/* Dependencies */
import { FunctionComponent, useState, useEffect, useRef } from 'react';
import * as Contentful from 'contentful';
import ReactPaginate from 'react-paginate';

// Services
import { httpClient } from '../../../services/httpClient';

// Components
import { ComponentWrapper } from '../../Molecules/ComponentWrapper/ComponentWrapper';
import { Container } from '../../Molecules/Container/Container';
import { Heading } from '../../Atoms/Heading/Heading';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { InsightCard } from '../../Molecules/InsightCard/InsightCard';

// Models
import {
  TypeAtomInsightTag,
  TypeTemplateInsightPage,
} from '../../../models/contentful';
import { InsightPostGridViewModel } from './InsightPostGrid.model';
import { RetrieveLatestInsightResponse } from '../../../services/Contentful/Contentful.model';
import { LatestPostFilters } from '../../Molecules/LatestPostFilters/LatestPostFilters';
import { FeaturedArticle } from '../FeaturedArticle/FeaturedArticle';

/**
 * INSIGHT & EVENT POST GRID - INEV07
 * @param InsightPostGridViewModel
 * @returns
 */
export const InsightPostGrid: FunctionComponent<InsightPostGridViewModel> = ({
  fields: { title, displayLimit, articles, filterTags },
}) => {
  const [activeTag, setActiveTag] = useState<TypeAtomInsightTag>();
  const [featuredArticle, setFeaturedArticle] =
    useState<TypeTemplateInsightPage>();

  const [currentItems, setCurrentItems] = useState<TypeTemplateInsightPage[]>(
    []
  );
  const [pageCount, setPageCount] = useState<number>(0);
  const [itemOffset, setItemOffset] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const postGrid = useRef<any>();

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * displayLimit + 1) % articles.length;
    setItemOffset(newOffset);
    postGrid.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    // Store items to use
    let articlesToUse: TypeTemplateInsightPage[];

    // Handle Active Tag
    if (activeTag) {
      articlesToUse = articles.filter((item) => {
        if (item.fields.tags) {
          const tagIds = item.fields.tags.map((tag) => tag.sys.id);
          return tagIds.includes(activeTag.sys.id);
        }
        return false;
      });
    }
    // Default
    else {
      articlesToUse = articles;
    }

    // Set Featured Article
    if (currentPage === 1) {
      setFeaturedArticle(articlesToUse[0]);
    }

    const endOffset = itemOffset + displayLimit;
    setCurrentItems(articlesToUse.slice(itemOffset, endOffset));
    setPageCount(Math.ceil((articlesToUse.length - 1) / displayLimit));

    // Set Current Page
    setCurrentPage(Math.floor(itemOffset / displayLimit));
  }, [itemOffset, displayLimit, activeTag]);

  return (
    <ComponentWrapper backgroundColor="Plum" className="text-primary">
      <Container className="py-9 sm:max-w-1130 lg:py-[72px]">
        {/* Filter */}
        {filterTags && (
          <LatestPostFilters
            activeTag={activeTag}
            handleFilter={(tag) => {
              setActiveTag(tag);
              setItemOffset(1);
              setCurrentPage(1);
            }}
            tags={filterTags}
          />
        )}
        {/* ./ Filter */}

        {/* Featured Post */}
        {featuredArticle && (
          <FeaturedArticle
            fields={featuredArticle.fields}
            preTitle={'FEATURED NEWS'}
            sys={featuredArticle.sys}
            metadata={featuredArticle.metadata}
            toPlainObject={featuredArticle.toPlainObject}
            update={featuredArticle.update}
          />
        )}
        {/* ./ Featured Post */}

        <section>
          {/* Pretitle for Post Grid */}
          {title && (
            <Heading level="h2" style="Heading 6" className="mb-6 uppercase">
              <PlainRichText content={title.fields.content} />
            </Heading>
          )}
          {/* ./ Pretitle for Post Grid */}

          {/* Post Grid */}
          <div
            className="grid gap-5 md:grid-cols-2 lg:grid-cols-3"
            ref={postGrid}
          >
            {currentItems.map(
              (
                { fields: { slug, image, tags, title, author, publishedDate } },
                index
              ) => {
                return (
                  <div data-aos="fade-up" key={`insight_${index}`}>
                    <InsightCard
                      link={`/insights/${slug.fields.slug}`}
                      image={image?.fields.asset}
                      tags={tags?.map((tag) => {
                        return tag.fields;
                      })}
                      title={title}
                      publishedDate={
                        publishedDate
                          ? new Date(publishedDate).toLocaleDateString('en-GB')
                          : undefined
                      }
                      author={author.fields.name}
                    />
                  </div>
                );
              }
            )}
          </div>
          {/* ./ Post Grid */}
        </section>

        {/* Pagination */}
        <ReactPaginate
          nextLabel="NEXT"
          previousLabel="PREV"
          onPageChange={handlePageClick}
          pageCount={pageCount}
          forcePage={currentPage}
          pageRangeDisplayed={2}
          marginPagesDisplayed={1}
          containerClassName={
            'pagination mt-9 lg:mt-16 flex justify-center font-display-a font-extrabold text-primary lg:text-2xl'
          }
          activeClassName={'opacity-50'}
          disabledClassName={'hidden'}
          renderOnZeroPageCount={() => null}
        />
        {/* ./ Pagination */}
      </Container>
    </ComponentWrapper>
  );
};

// Cache for the response
let responseCache: RetrieveLatestInsightResponse;

/**
 * Get Insight Post Grid Enhanced Props
 * @param component - Component.
 * @param preview - Preview mode status.
 * @returns
 */
export async function getInsightPostGridEnhancedProps(
  component: Contentful.Entry<Record<string, any>>,
  preview = false
) {
  // Insights
  let insightPages: RetrieveLatestInsightResponse;

  // If we have a cached response, return it
  if (responseCache) {
    insightPages = responseCache;
  } else {
    insightPages = await httpClient.contentful.retrieveLatestInsights(
      {
        limit: 999,
        locale: 'en-GB',
      },
      preview
    );
    responseCache = insightPages;
  }
  // Set the articles
  const resultsFound = insightPages.items.length > 0;

  component.fields.articles = [];

  if (resultsFound) {
    component.fields.articles = insightPages.items.map((item) => {
      return {
        ...item,
        fields: {
          ...item.fields,
          tags: item.fields.tags
            ? item.fields.tags.filter((tag) => tag.fields)
            : [],
        },
      };
    });
  }

  // Return the component
  return component;
}
