/* Dependencies */
import { FunctionComponent, useEffect, useRef } from 'react';
import anime from 'animejs';

// Components
import { Image } from '../../Molecules/Image/Image';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { ContainerArrow } from '../../Molecules/ContainerArrow/ContainerArrow';
import { Heading } from '../../Atoms/Heading/Heading';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';
import { CTATandem } from '../../Molecules/CTATandem/CTATandem';
import { ComponentWrapper } from '../../Molecules/ComponentWrapper/ComponentWrapper';
import { Awards } from '../../Molecules/Awards/Awards';

// Models
import { TypeHb02 } from '../../../models/contentful';
import { ContainerArrowProps } from '../../Molecules/ContainerArrow/ContainerArrow.model';

/**
 *  HERO BANNER IMAGE - HB02
 * @param TypeHb02 - Contentful model
 * @returns
 */
export const Hb02: FunctionComponent<TypeHb02> = ({
  fields: { title, subheading, bodyCopy, theme, callsToAction, image, awards },
}) => {
  const leftContainerRef = useRef<HTMLDivElement>(null);
  const leftContainerContentRef = useRef<HTMLDivElement>(null);
  const rightContainerRef = useRef<HTMLDivElement>(null);

  // Set the background color of the component
  let bgColor: ContainerArrowProps['bgColour'] = 'transparent';
  if (theme) {
    bgColor =
      theme.fields.colourScheme.toLowerCase() as ContainerArrowProps['bgColour'];
  }

  // Animate the component on mount.
  useEffect(() => {
    // Query the content elements.
    let containerElements: HTMLElement[] = [];
    if (leftContainerContentRef.current) {
      containerElements =
        Array.from(
          leftContainerContentRef.current.querySelectorAll('.content-item')
        ) || [];
    }

    // Animate the content elements on a timeline.
    anime
      .timeline({ autoplay: true })
      .add({
        targets: leftContainerRef.current,
        translateX: ['-20%', 0],
        scale: [0.8, 1],
        easing: 'easeInOutQuad',
        duration: 1000,
      })
      .add(
        {
          targets: rightContainerRef.current,
          translateX: ['20%', 0],
          easing: 'easeInOutQuad',
          duration: 1000,
        },
        '-=1000'
      )
      .add({
        targets: containerElements,
        translateY: ['20px', 0],
        opacity: [0, 1],
        easing: 'linear',
        delay: anime.stagger(50),
      });
  }, []);

  return (
    <ComponentWrapper>
      <div
        className={`relative mx-auto lg:overflow-hidden lg:pb-0 ${
          awards
            ? `before:absolute before:top-0 before:bottom-0 before:left-0 before:right-0 before:bg-${awards.fields.theme.fields.colourScheme.toLowerCase()} lg:before:top-1/2`
            : 'pb-[15vh]'
        }`}
      >
        {/* Mobile Image */}
        {image && (
          <div className="relative h-[60vh] w-full overflow-hidden lg:hidden">
            <div className=" w-full">
              <Image file={image.fields.asset} aspectRatio={'absolute'} />
            </div>
          </div>
        )}
        {/* / Mobile Image */}

        <div
          className={`flex w-full ${
            awards
              ? '-mt-[20vh]'
              : 'absolute -translate-y-1/2 transform lg:transform-none'
          } flex-row lg:static lg:mt-0 lg:py-16`}
        >
          {/* Image Arrow */}
          <ContainerArrow
            arrowsSide="Left"
            bgColour={'transparent'}
            classNameWrapper="hidden lg:block w-[60%] absolute top-0 right-0 h-full translate-x-[20%] transform"
            ref={rightContainerRef}
          >
            {image && (
              <>
                <div className="w-full lg:block">
                  <Image file={image.fields.asset} aspectRatio={'absolute'} />
                </div>
                <div className="w-full lg:hidden">
                  <Image file={image.fields.asset} aspectRatio={'1x1'} />
                </div>
              </>
            )}
          </ContainerArrow>
          {/* / Image Arrow */}

          {/* Content Arrow */}
          <div
            className="z-2 relative w-full -translate-x-[20%] transform lg:w-3/5"
            ref={leftContainerRef}
          >
            <ContainerArrow
              arrowsSide="Right"
              arrowsSize={awards ? 'None' : ''}
              bgColour={bgColor}
              className="flex flex-row items-center justify-start lg:min-h-[70vh]"
            >
              <div
                className="w-full max-w-[90%] py-16 px-8 lg:py-32 lg:px-28 xl:max-w-[80%]"
                ref={leftContainerContentRef}
              >
                {subheading && (
                  <div className="content-item mb-4">
                    <p className="text-base">
                      <PlainRichText content={subheading} />
                    </p>
                  </div>
                )}

                {title && (
                  <Heading
                    level="h1"
                    style={title.fields.style}
                    font={title.fields.fontFamily}
                    className="content-item whitespace-pre opacity-0"
                  >
                    <PlainRichText content={title.fields.content} />
                  </Heading>
                )}

                {bodyCopy && (
                  <Paragraph className="content-item mt-9 opacity-0">
                    <PlainRichText content={bodyCopy.fields.content} />
                  </Paragraph>
                )}

                {callsToAction && (
                  <div className="content-item mt-9 flex w-full flex-row opacity-0">
                    <CTATandem
                      callsToAction={callsToAction}
                      className="itmes-center flex flex-wrap justify-start"
                    />
                  </div>
                )}
              </div>
            </ContainerArrow>
          </div>
          {/* Content Arrow */}
        </div>
      </div>
      {awards && <Awards awards={awards} />}
    </ComponentWrapper>
  );
};
