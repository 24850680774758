/* Dependencies */
import { ComponentType, Fragment, FunctionComponent } from 'react';
import {
  componentEnhancers,
  componentMap,
} from '../../../components/components';
import * as Contentful from 'contentful';

// Models

/**
 * Map the desired components and render.
 * @param components - Components returned from Contentful.
 * @returns
 */
export const MapComponents: FunctionComponent<{
  components: Contentful.Entry<Record<string, any>>[] | undefined;
}> = ({ components }): JSX.Element => {
  return (
    <Fragment>
      {components &&
        components.map((component) => {
          if (!component || !component.sys || !component.sys.contentType) {
            return;
          }

          const Element = componentMap[component.sys.contentType.sys.id];

          if (!Element) {
            console.log(
              'Component not found',
              component.sys.contentType.sys.id
            );
            return null;
          }

          return <Element key={component.sys.id} {...component} />;
        })}
    </Fragment>
  );
};

export async function enhanceComponents(
  components: Contentful.Entry<Record<string, any>>[] | undefined,
  preview: boolean
) {
  if (components) {
    for (const component of components) {
      if (!component || !component.sys || !component.sys.contentType) {
        return;
      }

      const enhancer = componentEnhancers[component.sys.contentType.sys.id];

      if (enhancer) {
        console.log('Enhancing', component.sys.contentType.sys.id);
        await enhancer(component, preview);
      }
    }
  }
}
