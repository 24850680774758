import anime from 'animejs';
import {
  getAnimation,
  animations,
} from '../../../helpers/animateVideo/animateVideo';

export function openAndPlayVideo(
  initialContent: HTMLDivElement | null,
  videoContent: HTMLDivElement | null,
  videoContainer: HTMLDivElement | null,
  iframeReady: boolean
): void {
  const timeline = anime.timeline();
  timeline.add(getAnimation(initialContent, animations.fadeOut));

  const video = videoContent?.querySelector('iframe');
  if (videoContent && video && iframeReady) {
    timeline.add(
      getAnimation(videoContent, {
        ...animations.fadeIn,
        begin: () => {
          videoContent.style.display = 'flex';
          video.dataset.src && (video.src = video.dataset.src);
        },
      }),
      '-=1000'
    );
  }

  if (videoContainer) {
    timeline.add(
      getAnimation(
        videoContainer.querySelector('.clip-container_left'),
        animations.flattenLeftArrow
      ),
      '-=1000'
    );

    timeline.add(
      getAnimation(
        videoContainer.querySelector('.clip-container_right'),
        animations.flattenRightArrow
      ),
      '-=1000'
    );

    timeline.add(
      getAnimation(
        videoContainer.querySelector('.clip-container_left_border'),
        animations.flattenLeftArrowBorder
      ),
      '-=1000'
    );

    timeline.add(
      getAnimation(
        videoContainer.querySelector('.clip-container_right_border'),
        animations.flattenRightArrowBorder
      ),
      '-=1000'
    );
  }

  timeline.play();
}

export function closeAndStopVideo(
  initialContent: HTMLDivElement | null,
  videoContent: HTMLDivElement | null,
  videoContainer: HTMLDivElement | null
): void {
  const timeline = anime.timeline();
  timeline.add(getAnimation(initialContent, animations.fadeIn));

  const video = videoContent?.querySelector('iframe');
  if (videoContent && video) {
    timeline.add(
      getAnimation(videoContent, {
        ...animations.fadeOut,
        complete: () => {
          videoContent.style.display = '';
          video.src = '';
        },
      }),
      '-=1000'
    );
  }

  if (videoContainer) {
    timeline.add(
      getAnimation(
        videoContainer.querySelector('.clip-container_left'),
        animations.flattenLeftArrow,
        true
      ),
      '-=1000'
    );

    timeline.add(
      getAnimation(
        videoContainer.querySelector('.clip-container_right'),
        animations.flattenRightArrow,
        true
      ),
      '-=1000'
    );

    timeline.add(
      getAnimation(
        videoContainer.querySelector('.clip-container_left_border'),
        animations.flattenLeftArrowBorder,
        true
      ),
      '-=1000'
    );

    timeline.add(
      getAnimation(
        videoContainer.querySelector('.clip-container_right_border'),
        animations.flattenRightArrowBorder,
        true
      ),
      '-=1000'
    );
  }

  timeline.play();
}
