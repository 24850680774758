/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { Container } from '../../Molecules/Container/Container';

// Models
import { TypeBlockColouredPanel } from '../../../models/contentful';
import { MapComponents } from '../../Mappers/MapComponents/MapComponents';
import { ComponentWrapper } from '../../Molecules/ComponentWrapper/ComponentWrapper';

/**
 * Coloured Panel
 * @param TypeBlockColouredPanel
 * @returns
 */
export const ColouredPanel: FunctionComponent<TypeBlockColouredPanel> = ({
  fields: { components, theme },
}) => {
  return (
    <ComponentWrapper
      backgroundColor={theme.fields.colourScheme}
      className="coloured-panel relative pt-28 pb-24 before:absolute before:-inset-0 before:block before:h-4 before:w-1/2 before:bg-white lg:pt-48 lg:before:h-16"
    >
      <div
        className={`border-t- absolute top-0 left-1/2 -translate-x-1/2 border-x-[40px] border-t-[50px] border-solid border-x-transparent border-t-white lg:border-x-[125px] lg:border-t-[160px]`}
      ></div>
      <Container className="space-y-16 coloured-panel:px-0 lg:space-y-14">
        <MapComponents components={components} />
      </Container>
    </ComponentWrapper>
  );
};
