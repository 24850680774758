/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { ComponentWrapper } from '../../Molecules/ComponentWrapper/ComponentWrapper';
import { Container } from '../../Molecules/Container/Container';
import { RenderForm } from '../Forms/RenderForm/RenderForm';

// Models
import { TypeCon24 } from '../../../models/contentful';
import { Heading } from '../../Atoms/Heading/Heading';
import { title } from 'process';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';

/**
 * Form- CON024
 * @param TypeCon24
 * @returns
 */
export const Con24: FunctionComponent<TypeCon24> = ({
  fields: { form, subheading, bodyCopy, title },
}) => {
  return (
    <ComponentWrapper
      backgroundColor="Transparent"
      className="my-8 text-primary lg:my-16"
    >
      <Container className="max-w-1270 pb-10 lg:px-14 lg:pb-24">
        <div className="grid gap-y-8 gap-x-5 lg:grid-cols-2">
          <div className="lg:max-w-335">
            {/* Left side - text */}
            {subheading && (
              <Heading
                level="h2"
                style="Heading 6"
                className="mb-3 uppercase lg:mb-6"
              >
                <PlainRichText content={subheading} />
              </Heading>
            )}
            {title && (
              <Heading
                level="h3"
                style="Heading 2"
                className="mb-14 text-plum lg:mb-6"
              >
                <PlainRichText content={title.fields.content} />
              </Heading>
            )}
            {bodyCopy && <PlainRichText content={bodyCopy.fields.content} />}
          </div>
          {/* Right side - form */}
          {form && (
            <div className="w-full bg-fill">
              <RenderForm form={form} />
            </div>
          )}
        </div>
      </Container>
    </ComponentWrapper>
  );
};
