/* Dependencies */
import { FunctionComponent, useEffect, useRef, useState } from 'react';

// Components
import { Container } from '../../Molecules/Container/Container';
import { Heading } from '../../Atoms/Heading/Heading';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { ActionButton } from '../../Atoms/ActionButton/ActionButton';
import { VideoEmbed } from '../../Atoms/VideoEmbed/VideoEmbed';
import { VideoThumbnail } from '../../Molecules/VideoThumbnail/VideoThumbnail';

// Models
import { playVideo, textTheme } from './Con18.model';
import { Variants } from '../../Atoms/Button/Button.model';
import { TypeCon18 } from '../../../models/contentful';

/**
 * CONTENT VIDEO LINK - CON18
 * @param TypeCon18
 * @returns
 */
export const Con18: FunctionComponent<TypeCon18> = ({
  fields: { title, subheading, bodyCopy, image, video, theme },
}) => {
  const videoContentRef = useRef<HTMLDivElement>(null);
  const initialContentRef = useRef<HTMLDivElement>(null);
  const [mountIframe, setMountIframe] = useState(false);

  const buttonVariant: keyof Variants = theme.fields.colourScheme;

  useEffect(() => {
    playVideo(initialContentRef.current, videoContentRef.current, mountIframe);
  }, [mountIframe]);

  return (
    <Container className="my-24 rich-text:px-0 md:max-w-1130 lg:my-20">
      <div
        data-aos="fade-up"
        className={`relative ${
          textTheme[theme.fields.colourScheme]
        } pl-7 pt-64 pb-8 pr-14 lg:min-h-550 lg:px-14 lg:pb-14 lg:pt-16`}
      >
        <div ref={initialContentRef}>
          <VideoThumbnail image={image?.fields.asset} />

          <div className="relative max-w-400">
            {subheading && (
              <Heading
                level="h2"
                style="Heading 6"
                className="mb-2.5 uppercase"
              >
                <PlainRichText content={subheading} />
              </Heading>
            )}

            {title && (
              <Heading
                level="h3"
                font={title.fields.fontFamily}
                style={title.fields.style}
                className="mb-10 lg:mb-14"
              >
                <PlainRichText content={title.fields.content} />
              </Heading>
            )}

            <div className="mb-10 text-small lg:mb-16 lg:text-base">
              {bodyCopy && <PlainRichText content={bodyCopy.fields.content} />}
            </div>

            <ActionButton
              onClick={() => {
                setMountIframe(true);
              }}
              variant={buttonVariant}
            >
              Play video
            </ActionButton>
          </div>
        </div>

        {video && mountIframe && (
          <VideoEmbed video={video} ref={videoContentRef} />
        )}
      </div>
    </Container>
  );
};
