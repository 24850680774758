/* Dependencies */
import { FunctionComponent } from 'react';

/* Components */
import { Heading } from '../../Atoms/Heading/Heading';
import { getFormattedDate } from '../../../helpers/getFormattedDate/getFormattedDate';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';
import { ContainerArrow } from '../../Molecules/ContainerArrow/ContainerArrow';
import { Image } from '../../Molecules/Image/Image';
import { Tag } from '../../Atoms/Tag/Tag';
import { Link } from '../../Atoms/Link/Link';

/* Models */
import { FeaturedArticleProps } from './FeaturedArticle.model';

/**
 * Featured Article
 * @param FeaturedArticleViewModel
 * @returns
 */
export const FeaturedArticle: FunctionComponent<FeaturedArticleProps> = ({
  fields: { tags, title, publishedDate, author, image, slug },
  preTitle,
  pageType = 'insights',
}) => {
  return (
    <div className="mb-24 lg:mb-16">
      <Heading level="h2" style="Heading 6" className="mb-8 uppercase lg:mb-6">
        {preTitle}
      </Heading>
      <div className="relative flex flex-col rounded-md bg-white lg:flex-row">
        <Link
          to={`/${pageType}/${slug?.fields.slug}`}
          variant="none"
          className="absolute top-0 left-0 z-10 mb-2.5 h-full w-full overflow-hidden rounded-md lg:mb-4"
          openInNewTab={false}
        >
          <span className="sr-only">view event</span>
        </Link>
        {/* text side */}
        <div className="order-last w-full py-6 px-4 lg:order-first lg:w-3/5 lg:pr-24 lg:pl-8">
          {/* tags */}
          <ul className="mb-6 flex">
            {tags?.map(({ fields: { title, theme } }, index) => {
              return (
                <Tag
                  key={`${index}`}
                  backgroundColor={`${theme?.fields.colourScheme}`}
                  className={`m-0 mr-2 last:mr-0`}
                >
                  {title}
                </Tag>
              );
            })}
          </ul>
          {/* title */}
          <Heading
            level="h2"
            className={`mb-6 font-display-s text-plum lg:mb-24`}
          >
            {title}
          </Heading>

          {pageType === 'insights' &&
            <>
              <time
                className="font-display-a text-base text-plum"
                dateTime={`${String(publishedDate)}`}
              >
                {getFormattedDate(String(publishedDate))}
              </time>
              <Paragraph className="text-small text-plum">
                {author?.fields.name}
              </Paragraph>
            </>
          }
        </div>
        {/* ./text side */}

        {/* image side */}
        <div className="w-full pl-8 lg:w-2/5 lg:pl-0">
          <ContainerArrow
            arrowsSide="Left"
            classNameWrapper="w-full h-full overflow-hidden rounded-md"
          >
            {image && (
              <>
                <div className="hidden lg:block">
                  <Image file={image.fields.asset} aspectRatio={'absolute'} />
                </div>
                <div className="block lg:hidden">
                  <Image file={image.fields.asset} />
                </div>
              </>
            )}
          </ContainerArrow>
        </div>
        {/* ./image side */}
      </div>
    </div>
  );
};
