import React, { useRef } from 'react';
import { ServiceListProps } from './ServiceList.model';
import { ServiceListItem } from '../ServiceListItem/ServiceListItem';

export const ServiceList: React.FC<ServiceListProps> = ({
  services,
  animated,
  serviceItemBoxDefaultColourWhite,
}) => {
  const hrRef = useRef<HTMLHRElement>(null);

  return (
    <>
      <ul className="mt-[70px] flex flex-wrap justify-between gap-4 md:gap-5">
        {services.map(
          (
            { fields: { link, title, body, image, imageRollover = image } },
            index
          ) => (
            <ServiceListItem
              key={index}
              link={link}
              title={title}
              body={body}
              image={image}
              imageRollover={imageRollover}
              animated={animated}
              serviceItemBoxDefaultColourWhite={
                serviceItemBoxDefaultColourWhite
              }
            />
          )
        )}
      </ul>
      {animated && (
        <div data-aos="flip-left" data-aos-duration="800" data-aos-delay="500">
          <hr ref={hrRef} className="mt-10 border-t-3 border-red-500 pt-2" />
        </div>
      )}
    </>
  );
};
