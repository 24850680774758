/* Dependencies */
import { PropsWithChildren } from 'react';
import { TypeHotspotFields } from '../../../models/contentful';

export interface HotspotProps extends PropsWithChildren {
  className?: string;
  title?: TypeHotspotFields['title'];
  subheading?: TypeHotspotFields['subheading'];
  textSize?: textSize;
  side?: string;
  index?: number;
  theme?: keyof typeof hotspotThemes;
}
export type textSize = 'Small' | 'Large';

export const hotspotThemes = Object.freeze({
  Primary: {
    background: 'bg-white',
    backgroundAnimated: 'bg-blue',
    icon: 'fill-plum',
    iconAnimated: 'fill-plum',
  },
  Secondary: {
    background: 'bg-white',
    backgroundAnimated: 'bg-red',
    icon: 'fill-plum',
    iconAnimated: 'fill-white',
  },
  Tertiary: {
    background: 'bg-plum',
    backgroundAnimated: 'bg-red',
    icon: 'fill-white',
    iconAnimated: 'fill-white',
  },
});
