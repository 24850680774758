/* Dependencies */
import { FunctionComponent } from 'react';
import { getLinkUrl } from '../../../helpers/getLinkUrl/getLinkUrl';

// Components
import { LinkButton } from '../../Atoms/LinkButton/LinkButton';
import { ComponentWrapper } from '../../Molecules/ComponentWrapper/ComponentWrapper';
import { Container } from '../../Molecules/Container/Container';

// Models
import { TypeCon19 } from '../../../models/contentful';

/**
 * POST CONTENT FIGURES - INEV11
 * @param TypeCon19
 * @returns
 */
export const Con19: FunctionComponent<TypeCon19> = ({ fields }) => {
  return (
    <ComponentWrapper
      backgroundColor={`Transparent`}
      className={`mb-32 overflow-hidden py-4 lg:mb-40`}
    >
      <Container className="relative w-full sm:max-w-1130">
        <table
          data-aos="fade-up"
          className="min-w-full divide-y divide-plum border-b border-plum"
        >
          <thead>
            <tr className="text-left font-display-a text-xs font-black text-primary lg:text-base">
              <th scope="col" className="py-4">
                Job Role
              </th>
              <th scope="col" className="hidden py-4 md:table-cell">
                Location
              </th>
              <th scope="col" className="hidden py-4 md:table-cell">
                Duration
              </th>
              <th scope="col" className="py-4">
                <span className="sr-only">Sign up</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-plum">
            {fields.jobs?.map(
              (
                { fields: { jobRole, location, duration, callToAction } },
                index
              ) => {
                return (
                  <tr
                    key={`statistic_${index}`}
                    className="text-sm leading-normal text-primary lg:text-[18px]"
                  >
                    <td className="py-5">{jobRole}</td>
                    <td className="hidden py-5 md:table-cell">{location}</td>
                    <td className="hidden py-5 md:table-cell">{duration}</td>
                    {callToAction && (
                      <td className="py-5 text-right">
                        <LinkButton
                          variant={
                            callToAction.fields.theme?.fields.colourScheme
                          }
                          openInNewTab={callToAction.fields.openInNewTab}
                          to={getLinkUrl(callToAction)}
                        >
                          {callToAction.fields.title}
                        </LinkButton>
                      </td>
                    )}
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </Container>
    </ComponentWrapper>
  );
};
