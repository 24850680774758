/**
 * Get expected format date
 * @param date - Insight date.
 * @returns Formatted Date - DD/MM/YY
 */
export const getFormattedDate = (date?: string): string | undefined => {
  if (!date) return undefined;

  return new Date(String(date)).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  });
};

/**
 * Get time formatted
 * @param date - Event date.
 * @returns Formatted Time - HH:MM in 24 hour format
 */
export const getFormattedTime = (date?: string): string | undefined => {
  if (!date) return undefined;
  return new Date(String(date)).toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
  });
};

/**
 * Get date and time formatted
 * @param date - Event date.
 * @returns Formatted Date and Time - DD/MM/YY HH:MM in 24 hour format
 */
export const getFormattedDateTime = (date?: string): string => {
  if (!date) date = new Date(Date.now()).toString();

  const datePortion = new Date(String(date)).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  });

  const timePortion = new Date(String(date)).toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
  });

  return datePortion + ' ' + timePortion;
};
