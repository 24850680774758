/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { ComponentWrapper } from '../../Molecules/ComponentWrapper/ComponentWrapper';
import { Container } from '../../Molecules/Container/Container';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { Heading } from '../../Atoms/Heading/Heading';
import { CTATandem } from '../../Molecules/CTATandem/CTATandem';

// Models
import { TypeCon13 } from '../../../models/contentful';

/**
 * CONTENT CONTACT CTA - CON13
 * @param TypeCon13
 * @returns
 */
export const Con13: FunctionComponent<TypeCon13> = ({
  fields: { title, theme, callsToAction },
}) => {
  return (
    <ComponentWrapper
      backgroundColor={`${theme?.fields.colourScheme}`}
      className={`flex min-h-290 items-center py-16 lg:min-h-550`}
    >
      <Container className="sm:max-w-800">
        {title && (
          <div data-aos="fade-up">
            <Heading
              level="h2"
              font={`${title?.fields.fontFamily}`}
              style={`${title?.fields.style}`}
              className={`mb-6 text-center text-primary lg:mb-8`}
            >
              <PlainRichText content={title?.fields.content} />
            </Heading>
          </div>
        )}

        <CTATandem callsToAction={callsToAction} />
      </Container>
    </ComponentWrapper>
  );
};
