/* Dependencies */
import { PropsWithChildren } from 'react';
import { TypeVideoEmbed } from '../../../models/contentful';

export interface VideoEmbedProps extends PropsWithChildren {
  video: TypeVideoEmbed;
  autoplay?: boolean;
  controls?: boolean;
  loop?: boolean;
  mute?: boolean;
  closeButtonClick?: Function;
}

export interface VideoTypes {
  youTube: (
    videoId: string,
    autoplay: boolean,
    controls: boolean,
    loop: boolean,
    mute: boolean
  ) => string;
  vimeo: (
    videoId: string,
    autoplay: boolean,
    controls: boolean,
    loop: boolean,
    mute: boolean
  ) => string;
}

export const embedUrlFormats: VideoTypes = Object.freeze({
  youTube: (
    videoId: string,
    autoplay: boolean,
    controls: boolean,
    loop: boolean,
    mute: boolean
  ) => {
    return `https://www.youtube.com/embed/${videoId}?autoplay=${
      autoplay ? 1 : 0
    }&mute=${mute ? 1 : 0}&loop=${loop ? 1 : 0}&controls=${controls ? 1 : 0}`;
  },
  vimeo: (
    videoId: string,
    autoplay: boolean,
    controls: boolean,
    loop: boolean,
    mute: boolean
  ) => {
    return `https://player.vimeo.com/video/${videoId}&autoplay=${
      autoplay ? 1 : 0
    }&muted=${mute ? 1 : 0}&loop=${loop ? 1 : 0}&controls=${controls ? 1 : 0}`;
  },
});
