/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { RichText } from '../../Molecules/RichText/RichText';

// Models
import { TypeBlockRichText } from '../../../models/contentful';

/**
 * Rich Text Block Component
 * @params props - Required component props.
 */
export const RichTextBlock: FunctionComponent<TypeBlockRichText> = ({
  fields: { content },
}) => {
  return (
    <div className="my-12 mx-auto w-full max-w-1130 py-10 px-4">
      <RichText content={content} />
    </div>
  );
};
