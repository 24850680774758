/* Dependencies */
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperCore } from 'swiper/types';

// Import Swiper styles
import 'swiper/css';

// Components
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { ContainerArrow } from '../../Molecules/ContainerArrow/ContainerArrow';
import { Heading } from '../../Atoms/Heading/Heading';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';
import { Logo } from '../../Atoms/Logo/Logo';
import { ContainerArrowProps } from '../../Molecules/ContainerArrow/ContainerArrow.model';
import { ActionButton } from '../../Atoms/ActionButton/ActionButton';
import { Image } from '../../Molecules/Image/Image';

// Models
import { TypeCon23 } from '../../../models/contentful';

/**
 * POST CONTENT FIGURES - INEV11
 * @param TypeCon23
 * @returns
 */
export const Con23: FunctionComponent<TypeCon23> = ({
  fields: { title, testimonials, image, theme },
}) => {
  const swiperRef = useRef<SwiperCore>();
  const [isLogo, setIsLogo] = useState(
    testimonials[0].fields.logo ? false : true
  );

  useEffect(() => {
    swiperRef.current?.on('slideChange', function () {
      const index: number = swiperRef.current?.realIndex!;
      if (testimonials[index].fields.logo) setIsLogo(false);
      else setIsLogo(true);
    });
  }, []);
  return (
    <div className={`relative flex w-full`}>
      <ContainerArrow
        classNameWrapper="w-11/12 md:w-8/12 min-h-375 lg:min-h-550 xl:min-h-650 z-10"
        className="flex flex-col justify-center py-10 pr-[22.5%] pl-10 lg:pb-20 lg:pt-24"
        arrowsSide="Right"
        arrowsSize="Small"
        overlap={false}
        bgColour={
          theme.fields.colourScheme.toLowerCase() as ContainerArrowProps['bgColour']
        }
        animation="fade-right"
      >
        <div
          data-aos="fade-up"
          data-aos-delay="350"
          className="relative ml-auto w-full max-w-550"
        >
          {title && (
            <Heading
              level="h2"
              font={title.fields.fontFamily}
              style={'Heading 6'}
              className="mb-7 tracking-widest text-primary"
            >
              <PlainRichText content={title.fields.content} />
            </Heading>
          )}

          {testimonials && (
            <>
              <Swiper
                className="swiper-con23"
                slidesPerView={'auto'}
                onBeforeInit={(swiper) => {
                  swiperRef.current = swiper;
                }}
                spaceBetween={8}
                loop={true}
                breakpoints={{
                  1024: {
                    slidesPerView: 1,
                    spaceBetween: 16,
                  },
                }}
              >
                {testimonials.map(
                  (
                    { fields: { quote, logo, companyName, personName } },
                    index
                  ) => {
                    return (
                      <SwiperSlide
                        key={`slider_${index}`}
                        className="flex flex-col justify-between"
                      >
                        {quote && (
                          <Paragraph
                            variant="base"
                            className="mb-24 text-primary lg:mb-32"
                          >
                            <PlainRichText content={quote} />
                          </Paragraph>
                        )}
                        <div>
                          {logo && (
                            <div className="mb-6 h-12 max-w-80">
                              <Logo
                                file={logo}
                                className="h-full w-full object-contain object-left"
                              />
                            </div>
                          )}
                          {personName && (
                            <Paragraph
                              variant="base"
                              className="text-primary"
                              font="font-display-a"
                            >
                              {personName}
                            </Paragraph>
                          )}
                          {companyName && (
                            <Paragraph variant="small" className="text-primary">
                              {companyName}
                            </Paragraph>
                          )}
                        </div>
                      </SwiperSlide>
                    );
                  }
                )}
                {testimonials.length > 1 && (
                  <div
                    className={`absolute z-10 space-x-2.5 lg:space-x-6 ${
                      isLogo
                        ? 'bottom-16 lg:bottom-24'
                        : 'bottom-32 lg:bottom-36'
                    }`}
                  >
                    <ActionButton
                      onClick={() => swiperRef.current?.slidePrev()}
                      variant={`${
                        theme.fields.colourScheme === 'Red'
                          ? 'secondary'
                          : 'primary'
                      }`}
                      hasIcon={true}
                      arrowDirection={'Left'}
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-2.5 w-2.5 lg:m-0.5 lg:h-3 lg:w-3"
                      >
                        <path
                          className={`${
                            theme.fields.colourScheme === 'Red'
                              ? 'fill-button-secondary hover-hover:group-hover:fill-button-secondary-hover'
                              : 'fill-button-primary hover-hover:group-hover:fill-button-primary-hover'
                          }`}
                          d="M6.58 11.06C6.47333 10.0467 6.12 9.13333 5.52 8.32C4.90667 7.50667 4.2 6.98 3.4 6.74H11.6V5.18H3.4C4.2 4.94 4.90667 4.41333 5.52 3.6C6.12 2.78667 6.47333 1.88 6.58 0.879999L4.72 0.46C4.65333 1.87333 4.23333 2.98667 3.46 3.8C2.67333 4.61333 1.65333 5.07333 0.399999 5.18V6.74C1.65333 6.84667 2.67333 7.30667 3.46 8.12C4.23333 8.93333 4.65333 10.0467 4.72 11.46L6.58 11.06Z"
                        />
                      </svg>
                    </ActionButton>
                    <ActionButton
                      onClick={() => swiperRef.current?.slideNext()}
                      variant={`${
                        theme.fields.colourScheme === 'Red'
                          ? 'secondary'
                          : 'primary'
                      }`}
                      hasIcon={true}
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-2.5 w-2.5 lg:m-0.5 lg:h-3 lg:w-3"
                      >
                        <path
                          d="M5.42 11.06C5.52667 10.0467 5.88 9.13333 6.48 8.32C7.09333 7.50667 7.8 6.98 8.6 6.74H0.4V5.18H8.6C7.8 4.94 7.09333 4.41333 6.48 3.6C5.88 2.78667 5.52667 1.88 5.42 0.879999L7.28 0.46C7.34667 1.87333 7.76667 2.98667 8.54 3.8C9.32667 4.61333 10.3467 5.07333 11.6 5.18V6.74C10.3467 6.84667 9.32667 7.30667 8.54 8.12C7.76667 8.93333 7.34667 10.0467 7.28 11.46L5.42 11.06Z"
                          className={`${
                            theme.fields.colourScheme === 'Red'
                              ? 'fill-button-secondary hover-hover:group-hover:fill-button-secondary-hover'
                              : 'fill-button-primary hover-hover:group-hover:fill-button-primary-hover'
                          }`}
                        />
                      </svg>
                    </ActionButton>
                  </div>
                )}
              </Swiper>
            </>
          )}
        </div>
      </ContainerArrow>
      {image && (
        <div className="absolute inset-y-0 right-0 z-0 h-full w-6/12">
          <Image file={image.fields.asset} aspectRatio={'absolute'} />
        </div>
      )}
    </div>
  );
};
