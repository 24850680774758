/* Dependencies */
import { PropsWithChildren } from 'react';

export interface ContainerArrowProps extends PropsWithChildren {
  classNameWrapper?: string;
  className?: string;
  arrowsSide?: sides;
  arrowsSize?: sizes;
  border?: boolean;
  overlap?: boolean;
  bgColour?: keyof typeof backgroundColor;
  ref?: React.RefObject<HTMLDivElement>;
  animation?: 'fade-up' | 'fade-right' | 'fade-left';
}

export interface BackgroundColor {
  transparent: string;
  red: string;
  blue: string;
  plum: string;
}

export const backgroundColor: BackgroundColor = Object.freeze({
  transparent: '',
  red: 'theme-red',
  blue: 'theme-blue',
  plum: 'theme-plum',
});

export type sides = 'Left' | 'Right';
export type sizes = '' | 'Small' | 'None';
