// Components
import { DemoComponent } from './Organisms/DemoComponent/DemoComponent';
import { RichTextBlock } from './Organisms/RichTextBlock/RichTextBlock';
import { ColouredPanel } from './Organisms/ColouredPanel/ColouredPanel';
import { Con02 } from './Organisms/Con02/Con02';
import { Con04 } from './Organisms/Con04/Con04';
import { Con05 } from './Organisms/Con05/Con05';
import { Con06 } from './Organisms/Con06/Con06';
import { Con07 } from './Organisms/Con07/Con07';
import { Con08 } from './Organisms/Con08/Con08';
import { Con09 } from './Organisms/Con09/Con09';
import { Con10 } from './Organisms/Con10/Con10';
import { Con11 } from './Organisms/Con11/Con11';
import { Con13 } from './Organisms/Con13/Con13';
import { Con14 } from './Organisms/Con14/Con14';
import { Con15 } from './Organisms/Con15/Con15';
import { Con16 } from './Organisms/Con16/Con16';
import { Con17 } from './Organisms/Con17/Con17';
import { Con18 } from './Organisms/Con18/Con18';
import { Con19 } from './Organisms/Con19/Con19';
import { Con20 } from './Organisms/Con20/Con20';
import { Con22 } from './Organisms/Con22/Con22';
import { Con23 } from './Organisms/Con23/Con23';
import { Con25 } from './Organisms/Con25/Con25';
import { Con26 } from './Organisms/Con26/Con26';
import { Con27 } from './Organisms/Con27/Con27';
import { Con28 } from './Organisms/Con28/Con28';
import { Con29 } from './Organisms/Con29/Con29';
import { Con30 } from './Organisms/Con30/Con30';
import { Con31 } from './Organisms/Con31/Con31';
import { Con32 } from './Organisms/Con32/Con32';
import { Hb01 } from './Organisms/Hb01/Hb01';
import { Hb02 } from './Organisms/Hb02/Hb02';
import { Hb03 } from './Organisms/Hb03/Hb03';
import { Inev03, getInev03EnhancedProps } from './Organisms/Inev03/Inev03';
import { Inev06 } from './Organisms/Inev06/Inev06';
import {
  InsightPostGrid,
  getInsightPostGridEnhancedProps,
} from './Organisms/InsightPostGrid/InsightPostGrid';
import { Inev10 } from './Organisms/Inev10/Inev10';
import { Inev11 } from './Organisms/Inev11/Inev11';
import { Inev12 } from './Organisms/Inev12/Inev12';
import { Inev16 } from './Organisms/Inev16/Inev16';

// Models
import { DemoComponentProps } from './Organisms/DemoComponent/DemoComponent.model';
import {
  TypeBlockColouredPanel,
  TypeCon02,
  TypeCon03,
  TypeCon04,
  TypeCon05,
  TypeCon06,
  TypeCon07,
  TypeCon08,
  TypeCon09,
  TypeCon10,
  TypeCon11,
  TypeCon13,
  TypeCon14,
  TypeCon15,
  TypeCon16,
  TypeCon17,
  TypeCon18,
  TypeCon19,
  TypeCon20,
  TypeCon22,
  TypeCon23,
  TypeCon25,
  TypeCon26,
  TypeTestimonial,
  TypeCon29,
  TypeCon30,
  TypeCon31,
  TypeCon32,
  TypeHb01,
  TypeHb02,
  TypeHb03,
  TypeInev03,
  TypeInev06,
  TypeInev07,
  TypeInev10,
  TypeInev11,
  TypeInev12,
  TypeInev16,
} from '../models/contentful';
import {
  EventPostGrid,
  getEventPostGridEnhancedProps,
} from './Organisms/EventPostGrid/EventPostGrid';
import {
  CaseStudyPostGrid,
  getCaseStudyPostGridEnhancedProps,
} from './Organisms/CaseStudyPostGrid/CaseStudyPostGrid';
import { Con24 } from './Organisms/Con24/Con24';
import { Inev17 } from './Organisms/inev17/Inev17';

export interface ComponentMap {
  [key: string]: any;
}

export type ComponentTypes =
  | DemoComponentProps
  | TypeBlockColouredPanel
  | TypeCon02
  | TypeCon03
  | TypeCon04
  | TypeCon05
  | TypeCon06
  | TypeCon07
  | TypeCon08
  | TypeCon09
  | TypeCon10
  | TypeCon11
  | TypeCon13
  | TypeCon14
  | TypeCon15
  | TypeCon16
  | TypeCon17
  | TypeCon18
  | TypeCon19
  | TypeCon20
  | TypeCon22
  | TypeCon23
  | TypeCon25
  | TypeCon26
  | TypeTestimonial
  | TypeCon29
  | TypeCon30
  | TypeCon31
  | TypeCon32
  | TypeHb01
  | TypeHb02
  | TypeHb03
  | TypeInev03
  | TypeInev06
  | TypeInev07
  | TypeInev10
  | TypeInev11
  | TypeInev12
  | TypeInev16;

export const componentMap: ComponentMap = {
  demoComponent: DemoComponent,
  richTextBlock: RichTextBlock,
  blockRichText: RichTextBlock,
  blockColouredPanel: ColouredPanel,
  con02: Con02,
  con04: Con04,
  con05: Con05,
  con06: Con06,
  con07: Con07,
  con08: Con08,
  con09: Con09,
  con10: Con10,
  con11: Con11,
  con13: Con13,
  con14: Con14,
  con15: Con15,
  con16: Con16,
  con17: Con17,
  con18: Con18,
  con19: Con19,
  con20: Con20,
  con22: Con22,
  con23: Con23,
  con24: Con24,
  con25: Con25,
  con26: Con26,
  testimonial: Con27,
  blockContentChildNav: Con28,
  con29: Con29,
  con30: Con30,
  con31: Con31,
  con32: Con32,
  hb01: Hb01,
  hb02: Hb02,
  hb03: Hb03,
  inev03: Inev03,
  blockCaseStudyPostCarousel: Inev03,
  inev06: Inev06,
  inev07: InsightPostGrid,
  blockEventPostGrid: EventPostGrid,
  blockCaseStudyPostGrid: CaseStudyPostGrid,
  inev10: Inev10,
  inev11: Inev11,
  inev12: Inev12,
  inev16: Inev16,
  inev17: Inev17,
};

export interface ComponentEnhancers {
  [key: string]: any;
}

export const componentEnhancers: ComponentEnhancers = {
  inev03: getInev03EnhancedProps,
  blockCaseStudyPostCarousel: getInev03EnhancedProps,
  inev07: getInsightPostGridEnhancedProps,
  blockEventPostGrid: getEventPostGridEnhancedProps,
  blockCaseStudyPostGrid: getCaseStudyPostGridEnhancedProps,
};
