/**
 * Get video url
 * @param url - video url.
 * @returns
 */
export const getVideoUrl = (url: string): string => {
  if (!url) {
    return '';
  }

  if (url.includes('youtube')) {
    const urlString = new URL(url);
    return urlString.searchParams.get('v') || '';
  }

  if (url.includes('vimeo')) {
    const urlString = new URL(url);
    const urlParts = urlString.pathname.split('/');
    if (urlParts.length > 2) {
      return `${urlParts[1]}?h=${urlParts[2]}`;
    } else {
      return `${urlParts[1]}`;
    }
  }

  return url;
};
