/* Dependencies */
import { FunctionComponent } from 'react';
import { Disclosure } from '@headlessui/react';

// Components
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { Heading } from '../../Atoms/Heading/Heading';
import { Link } from '../../Atoms/Link/Link';

// Helpers
import { getLinkUrl } from '../../../helpers/getLinkUrl/getLinkUrl';

// Models
import { AccordionListProps } from './AccordionList.model';

/**
 * Accordion List
 * @param props - Component props
 */
export const AccordionList: FunctionComponent<AccordionListProps> = ({
  entries,
}) => {
  return (
    <dl className="accordion">
      {entries.map(
        (
          {
            fields: {
              locationName,
              addressTitle,
              addressLine1,
              addressLine2,
              addressLine3,
              city,
              postCode,
              emailTitle,
              email,
              phoneTitle,
              phone,
              officeHoursTitle,
              officeHours,
              viewMapCallToAction,
            },
          },
          index
        ) => (
          <Disclosure
            as="div"
            key={index}
            defaultOpen={index === 0 ? true : false}
            className="group border-t border-black/50 first:border-none"
          >
            {({ open }) => (
              <>
                <dt className="py-6">
                  <Disclosure.Button className="flex w-full items-center">
                    {/* heading */}
                    <Heading level="h2" style="Heading 5">
                      {locationName}
                    </Heading>
                    <span className="ml-2 mr-3 flex h-9 w-9 items-center justify-center rounded-full">
                      <svg
                        className={`transition-transform ${
                          open ? 'rotate-90' : 'rotate-0'
                        } text-midnight-black h-3 w-3 transform`}
                        aria-hidden="true"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="6"
                          cy="6"
                          r="6"
                          transform="rotate(-90 6 6)"
                          fill="#330523"
                        />
                        <line
                          x1="4.64645"
                          y1="9.64645"
                          x2="8.64645"
                          y2="5.64645"
                          stroke="white"
                        />
                        <line
                          x1="8.64645"
                          y1="6.35355"
                          x2="4.64645"
                          y2="2.35355"
                          stroke="white"
                        />
                      </svg>
                    </span>
                  </Disclosure.Button>
                </dt>
                <Disclosure.Panel as="dd" className="pb-4 pr-4 lg:py-7 lg:pr-8">
                  <div
                    className="grid gap-7 lg:grid-cols-2 lg:gap-x-10"
                    key={`${index}`}
                  >
                    {/* content */}
                    <div className="grid max-w-800 gap-y-7">
                      {addressLine1 && (
                        <div>
                          <Heading level="h3" style="Heading 6">
                            {addressTitle}
                          </Heading>
                          <address className="flex flex-col not-italic leading-6 lg:leading-7">
                            <span>{addressLine1}</span>

                            {addressLine2 && <span>{addressLine2}</span>}

                            {addressLine3 && <span>{addressLine3}</span>}

                            {city && <span>{city}</span>}

                            {postCode && <span>{postCode}</span>}
                          </address>
                        </div>
                      )}
                      {email && (
                        <div>
                          <Heading level="h3" style="Heading 6">
                            {emailTitle}
                          </Heading>
                          <Link
                            className="font-display-s"
                            variant="none"
                            to={`mailTo:${email}`}
                            openInNewTab={false}
                          >
                            {email}
                          </Link>
                        </div>
                      )}
                      {phone && (
                        <div>
                          <Heading level="h3" style="Heading 6">
                            {phoneTitle}
                          </Heading>
                          <Link
                            className="font-display-s"
                            variant="none"
                            to={`tel:${phone}`}
                            openInNewTab={false}
                          >
                            {phone}
                          </Link>
                        </div>
                      )}
                    </div>
                    <div className="grid gap-y-7">
                      {officeHours && (
                        <div>
                          <Heading level="h3" style="Heading 6">
                            {officeHoursTitle}
                          </Heading>
                          <PlainRichText content={officeHours} />
                        </div>
                      )}
                      {viewMapCallToAction && (
                        <div>
                          {(viewMapCallToAction?.fields.internalLink ||
                            viewMapCallToAction?.fields.externalLink) && (
                            <Link
                              openInNewTab={
                                viewMapCallToAction.fields.openInNewTab
                              }
                              to={getLinkUrl(viewMapCallToAction)}
                              variant="none"
                              className="flex items-center font-display-a text-xs font-extrabold leading-snug lg:text-2xl lg:leading-tight"
                            >
                              {viewMapCallToAction.fields.title}
                              <span className="flex h-9 w-9 items-center justify-center rounded-full">
                                <svg
                                  className="text-midnight-black h-3 w-3 transform transition-transform"
                                  aria-hidden="true"
                                  width="12"
                                  height="12"
                                  viewBox="0 0 12 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle
                                    cx="6"
                                    cy="6"
                                    r="6"
                                    transform="rotate(-90 6 6)"
                                    fill="#330523"
                                  />
                                  <line
                                    x1="4.64645"
                                    y1="9.64645"
                                    x2="8.64645"
                                    y2="5.64645"
                                    stroke="white"
                                  />
                                  <line
                                    x1="8.64645"
                                    y1="6.35355"
                                    x2="4.64645"
                                    y2="2.35355"
                                    stroke="white"
                                  />
                                </svg>
                              </span>
                            </Link>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        )
      )}
    </dl>
  );
};
