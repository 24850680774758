/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { Image } from '../../Molecules/Image/Image';
import { Container } from '../../Molecules/Container/Container';

// Models
import { TypeCon29 } from '../../../models/contentful';

/**
 * CONTENT ICON LIST - CON29
 * @param TypeCon29
 * @returns
 */
export const Con29: FunctionComponent<TypeCon29> = ({ fields }) => {
  return (
    <Container>
      {fields.icons && (
        <div className="mx-auto my-16 max-w-974 lg:my-32 lg:grid lg:grid-cols-2 lg:gap-x-6 lg:gap-y-4">
          {fields.icons.map(({ fields: { image, title } }, index) => {
            return (
              <div
                key={`icon_${index}`}
                className="mb-5 rounded-md border-x-[1px] border-y-[1px] border-solid border-red py-6 px-10 text-plum lg:mb-0 lg:py-8 lg:px-24"
              >
                <div className="mx-auto mb-4 w-9">
                  {image && <Image file={image.fields.asset} />}
                </div>
                <div className="text-center font-display-a text-base">
                  {title}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </Container>
  );
};
