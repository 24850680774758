/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { ComponentWrapper } from '../../Molecules/ComponentWrapper/ComponentWrapper';
import { Image } from '../../Molecules/Image/Image';

// Models
import { TypeCon32 } from '../../../models/contentful';
import { Heading } from '../../Atoms/Heading/Heading';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';

/**
 * CONTENT FULL PAGE IMAGE - CON32
 * @param TypeCon32
 * @returns
 */
export const Con32: FunctionComponent<TypeCon32> = ({
  fields: { title, height, image },
}) => {
  return (
    <ComponentWrapper>
      <div data-aos="fade-up">
        {title && (
          <Heading
            level="h2"
            style={`${title.fields.style}`}
            className="text-center mb-6 lg:mb-8"
          >
            <PlainRichText content={title.fields.content} />
          </Heading>
        )}
        <Image 
          file={image.fields.asset} 
          height={height} 
          aspectRatio="fluid" 
          className='object-cover' 
        />
      </div>
    </ComponentWrapper>
  );
};
