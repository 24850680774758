/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { Logo } from '../../Atoms/Logo/Logo';

// Models
import { AwardsProps } from './Awards.model';

export const Awards: FunctionComponent<AwardsProps> = ({ awards }) => {
  return (
    awards && (
      <div
        className={`bg-${awards.fields.theme.fields.colourScheme.toLowerCase()} py-16`}
      >
        <div className="mx-auto max-w-1130 justify-between px-12 brightness-0 invert filter lg:flex">
          {awards.fields.logos.map((logo, index) => {
            if (logo.fields.asset) {
              return (
                <div
                  className="mb-12 flex h-20 flex-col items-center last:mr-0 lg:mr-24 lg:mb-0 lg:h-32"
                  key={index}
                >
                  <Logo file={logo.fields.asset} />
                </div>
              );
            }

            return <></>;
          })}
        </div>
      </div>
    )
  );
};
