/* Dependencies */
import { FunctionComponent, useState, useEffect, useRef } from 'react';
import * as Contentful from 'contentful';
import ReactPaginate from 'react-paginate';

// Services
import { httpClient } from '../../../services/httpClient';

// Components
import { ComponentWrapper } from '../../Molecules/ComponentWrapper/ComponentWrapper';
import { Container } from '../../Molecules/Container/Container';
import { Heading } from '../../Atoms/Heading/Heading';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { EventCard } from '../../Molecules/EventCard/EventCard';

// Models
import {
  TypeInsightEventTag,
  TypeTemplateEventPage,
} from '../../../models/contentful';
import { EventPostGridViewModel } from './EventPostGrid.model';
import { RetrieveLatestEventsResponse } from '../../../services/Contentful/Contentful.model';
import { LatestPostFilters } from '../../Molecules/LatestPostFilters/LatestPostFilters';
import { FeaturedEvent } from '../FeaturedEvent/FeaturedEvent';

/**
 * INSIGHT & EVENT POST GRID - INEV07
 * @param EventPostGridViewModel
 * @returns
 */
export const EventPostGrid: FunctionComponent<EventPostGridViewModel> = ({
  fields: { title, displayLimit, events, filterTags },
}) => {
  const [activeTag, setActiveTag] = useState<TypeInsightEventTag>();
  const [featuredEvent, setFeaturedEvent] = useState<TypeTemplateEventPage>();

  const [currentItems, setCurrentItems] = useState<TypeTemplateEventPage[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [itemOffset, setItemOffset] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const postGrid = useRef<any>();

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * displayLimit + 1) % events.length;
    setItemOffset(newOffset);
    postGrid.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    // Store items to use
    let eventsToUse: TypeTemplateEventPage[];

    // Handle Active Tag
    if (activeTag) {
      eventsToUse = events.filter((item) => {
        if (item.fields.tags) {
          const tagIds = item.fields.tags.map((tag) => tag.sys.id);
          return tagIds.includes(activeTag.sys.id);
        }
        return false;
      });
    }
    // Default
    else {
      eventsToUse = events;
    }

    // Set Featured Event
    if (currentPage === 1) {
      setFeaturedEvent(eventsToUse[0]);
    }

    const endOffset = itemOffset + displayLimit;
    setCurrentItems(eventsToUse.slice(itemOffset, endOffset));
    setPageCount(Math.ceil((eventsToUse.length - 1) / displayLimit));

    // Set Current Page
    setCurrentPage(Math.floor(itemOffset / displayLimit));
  }, [itemOffset, displayLimit, activeTag]);

  return (
    <ComponentWrapper backgroundColor="Plum" className="text-primary">
      <Container className="py-9 sm:max-w-1130 lg:py-[72px]">
        {/* Filter */}
        {filterTags && (
          <LatestPostFilters
            activeTag={activeTag}
            handleFilter={(tag) => {
              setActiveTag(tag);
              setItemOffset(1);
              setCurrentPage(1);
            }}
            tags={filterTags}
          />
        )}
        {/* ./ Filter */}

        {/* Featured Post */}
        {featuredEvent && (
          <FeaturedEvent
            fields={featuredEvent.fields}
            preTitle={'FEATURED EVENT'}
            sys={featuredEvent.sys}
            metadata={featuredEvent.metadata}
            toPlainObject={featuredEvent.toPlainObject}
            update={featuredEvent.update}
          />
        )}
        {/* ./ Featured Post */}

        <section>
          {/* Pretitle for Post Grid */}
          {title && (
            <Heading
              level="h2"
              style="Heading 6"
              className="my-6 uppercase text-primary"
            >
              <PlainRichText content={title.fields.content} />
            </Heading>
          )}
          {/* ./ Pretitle for Post Grid */}

          {/* Post Grid */}
          <div
            className="grid gap-5 md:grid-cols-2 lg:grid-cols-3"
            ref={postGrid}
          >
            {currentItems.map(
              ({ fields: { slug, image, tags, title, eventDate } }, index) => {
                return (
                  <div data-aos="fade-up" key={`event_${index}`}>
                    <EventCard
                      link={`/events/${slug.fields.slug}`}
                      image={image?.fields.asset}
                      tags={tags?.map((tag) => {
                        return tag.fields;
                      })}
                      title={title.fields}
                      publishedDate={
                        eventDate
                          ? new Date(eventDate).toLocaleDateString('en-GB')
                          : undefined
                      }
                    />
                  </div>
                );
              }
            )}
          </div>
          {/* ./ Post Grid */}
        </section>

        {/* Pagination */}
        <ReactPaginate
          nextLabel="NEXT"
          previousLabel="PREV"
          onPageChange={handlePageClick}
          pageCount={pageCount}
          forcePage={currentPage}
          pageRangeDisplayed={2}
          marginPagesDisplayed={1}
          containerClassName={
            'pagination mt-9 lg:mt-16 flex justify-center font-display-a font-extrabold text-primary lg:text-2xl'
          }
          activeClassName={'opacity-50'}
          disabledClassName={'hidden'}
          renderOnZeroPageCount={() => null}
        />
        {/* Pagination */}
      </Container>
    </ComponentWrapper>
  );
};

// Cache for the response
let responseCache: RetrieveLatestEventsResponse;

/**
 * Get Event Post Grid Enhanced Props
 * @param component - Component.
 * @param preview - Preview mode status.
 * @returns
 */
export async function getEventPostGridEnhancedProps(
  component: Contentful.Entry<Record<string, any>>,
  preview = false
) {
  // Events
  let eventPages: RetrieveLatestEventsResponse;

  // If we have a cached response, return it
  if (responseCache) {
    eventPages = responseCache;
  } else {
    eventPages = await httpClient.contentful.retrieveLatestEvents(
      {
        limit: 999,
        locale: 'en-GB',
      },
      preview
    );
    responseCache = eventPages;
  }

  // Set the events
  component.fields.events = [];

  const resultsFound = eventPages.items.length > 0;
  if (resultsFound) {
    component.fields.events = eventPages.items.map((item) => {
      return {
        ...item,
        fields: {
          ...item.fields,
          tags: item.fields.tags
            ? item.fields.tags.filter((tag) => tag.fields)
            : [],
        },
      };
    });
  }

  // Return the component
  return component;
}
