/* Dependencies */
import { FunctionComponent } from 'react';
import { PageContext } from '../../../context/PageContext';
import { prefixSlug } from '../../../helpers/prefixSlug/prefixSlug';

// Components
import { Container } from '../../Molecules/Container/Container';
import { CTATandem } from '../../Molecules/CTATandem/CTATandem';

// Models
import { TypeBlockContentChildNav } from '../../../models/contentful/TypeBlockContentChildNav';
import { useRouter } from 'next/router';

/**
 * CONTENT CHILD NAV - CON28
 * @param TypeBlockContentChildNav
 * @returns
 */
export const Con28: FunctionComponent<TypeBlockContentChildNav> = ({
  fields: { navigationItems, callToAction },
}) => {
  const router = useRouter();
  return (
    <PageContext.Consumer>
      {({ pageTitle }) => (
        <Container className="py-12 text-base text-plum lg:py-16 lg:text-3xl">
          {navigationItems && (
            <ul className="mx-auto flex flex-col items-center lg:flex-row lg:justify-around">
              {navigationItems.map((navItem, index) => {
                if (router.asPath.includes(navItem.fields.page.fields.slug)) {
                  return (
                    <li
                      key={`navItem_${index}`}
                      className={`px-4 transition-opacity hover:opacity-100`}
                    >
                      {navItem.fields && (
                        <span className="border-b-[2px] border-plum">
                          {navItem.fields.title}
                        </span>
                      )}
                    </li>
                  );
                } else {
                  return (
                    <li
                      key={`navItem_${index}`}
                      className={`px-4 opacity-50 transition-opacity hover:opacity-100`}
                    >
                      {navItem.fields && (
                        <a
                          href={prefixSlug(navItem.fields.page.fields.slug)}
                          className="border-b-[2pxm"
                        >
                          {navItem.fields.title}
                        </a>
                      )}
                    </li>
                  );
                }
              })}
            </ul>
          )}

          {callToAction && (
            <CTATandem
              callsToAction={[callToAction]}
              className="mt-6 flex justify-center lg:mt-12"
            />
          )}
        </Container>
      )}
    </PageContext.Consumer>
  );
};
