/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { Statistics } from '../../Atoms/Statistics/Statistics';
import { ComponentWrapper } from '../../Molecules/ComponentWrapper/ComponentWrapper';
import { Container } from '../../Molecules/Container/Container';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { Heading } from '../../Atoms/Heading/Heading';

// Models
import { TypeCon25 } from '../../../models/contentful';

/**
 * POST CONTENT FIGURES - INEV11
 * @param TypeCon25
 * @returns
 */
export const Con25: FunctionComponent<TypeCon25> = ({
  fields: { title, statistics },
}) => {
  return (
    <ComponentWrapper
    backgroundColor="Transparent"
    className="mt-28 mb-14 lg:my-20"
  >
      <Container className="sm:max-w-1130">
        {title && (
          <div data-aos="fade-up">
            <Heading
              level="h2"
              font={`${title?.fields.fontFamily}`}
              style={`${title?.fields.style}`}
              className={`mb-8 text-center text-primary lg:mb-14`}
            >
              <PlainRichText content={title?.fields.content} />
            </Heading>
          </div>
        )}

        <Statistics statistics={statistics} />
      </Container>
    </ComponentWrapper>
  );
};
