/* Dependencies */
import { FunctionComponent, useEffect, useRef, useState } from 'react';

// Components
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { ContainerArrow } from '../../Molecules/ContainerArrow/ContainerArrow';
import { Heading } from '../../Atoms/Heading/Heading';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';
import { CTATandem } from '../../Molecules/CTATandem/CTATandem';
import { VideoThumbnail } from '../../Molecules/VideoThumbnail/VideoThumbnail';
import { VideoEmbed } from '../../Atoms/VideoEmbed/VideoEmbed';

// Models
import { TypeCon09 } from '../../../models/contentful';
import { ContainerArrowProps } from '../../Molecules/ContainerArrow/ContainerArrow.model';
import { openAndPlayVideo, closeAndStopVideo } from './Con09.model';
import { Block, Text } from '@contentful/rich-text-types';

/**
 * CONTENT SPLIT - CON09
 * @param TypeCon09
 * @returns
 */
export const Con09: FunctionComponent<TypeCon09> = ({
  fields: { title, bodyCopy, image, video, callsToAction, reverse, theme },
}) => {
  const initialContentRef = useRef<HTMLDivElement>(null);
  const videoContentRef = useRef<HTMLDivElement>(null);
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const [mountIframe, setMountIframe] = useState(false);

  useEffect(() => {
    video &&
      mountIframe &&
      openAndPlayVideo(
        initialContentRef.current,
        videoContentRef.current,
        videoContainerRef.current,
        mountIframe
      );
  }, [mountIframe]);

  // Set the background color of the component
  let bgColor: ContainerArrowProps['bgColour'] = 'transparent';
  if (theme) {
    bgColor =
      theme.fields.colourScheme.toLowerCase() as ContainerArrowProps['bgColour'];
  }

  let titleId = ((title?.fields?.content?.content[0] as Block)?.content[0] as Text)?.value?.toLowerCase() ?? "";
  if (titleId.includes(" ")) titleId = titleId.replace(/\s+/g, "-")

  return (
    <div className="overflow-hidden py-11 lg:flex lg:justify-between" id={titleId}>
      <div
        className={`${
          reverse ? 'mr-5' : 'order-last ml-5'
        } lg:mx-0 lg:w-1/2 lg:flex-shrink-0 lg:flex-grow-0`}
      >
        <ContainerArrow
          arrowsSide={reverse ? 'Right' : 'Left'}
          bgColour={bgColor}
          className={`flex min-h-[380px] items-center pl-10 lg:min-h-[610px] ${
            reverse ? 'lg:pr-[30%] lg:pl-40' : 'lg:pl-[30%] lg:pr-40'
          }`}
          animation={reverse ? 'fade-right' : 'fade-left'}
          border={true}
          ref={videoContainerRef}
        >
          <div ref={initialContentRef}>
            {image && video && (
              <VideoThumbnail
                image={image.fields.asset}
                onClick={() => setMountIframe(true)}
              />
            )}
          </div>
          {video && mountIframe && (
            <VideoEmbed
              video={video}
              autoplay={true}
              controls={true}
              loop={false}
              mute={false}
              closeButtonClick={() => {
                closeAndStopVideo(
                  initialContentRef.current,
                  videoContentRef.current,
                  videoContainerRef.current
                );
                setMountIframe(false);
              }}
              ref={videoContentRef}
            />
          )}
        </ContainerArrow>
      </div>
      <div
        data-aos="fade-up"
        className={`px-10 pt-10 lg:w-1/2 lg:flex-shrink-0 lg:flex-grow-0 lg:${
          reverse ? 'pr' : 'pl'
        }-40 lg:${reverse ? 'pl' : 'pr'}-[10%]`}
      >
        <Heading
          level="h2"
          style={title.fields.style}
          font={title.fields.fontFamily}
          className="mb-9 lg:mb-12"
        >
          <PlainRichText content={title.fields.content} />
        </Heading>

        {bodyCopy && (
          <Paragraph className="mb-8 text-primary">
            <PlainRichText content={bodyCopy.fields.content} />
          </Paragraph>
        )}

        {callsToAction && (
          <CTATandem
            callsToAction={callsToAction}
            className="mt-9 flex flex-wrap items-center justify-start"
          />
        )}
      </div>
    </div>
  );
};
