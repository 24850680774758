/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { Container } from '../../Molecules/Container/Container';
import { Heading } from '../../Atoms/Heading/Heading';
import { Image } from '../../Molecules/Image/Image';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';

// Models
import { TypeCon15 } from '../../../models/contentful';
import { imageAspectRatioClasses } from '../../Molecules/Image/Image.model';

/**
 * CONTENT TEAM GRID - CON15
 * @param TypeCon15
 * @returns
 */
export const Con15: FunctionComponent<TypeCon15> = ({
  fields: { title, subheading, bodyCopy, teamMembers },
}) => {
  return (
    <Container className="bg-team-pattern bg-[length:65%] bg-[right_-65px_top] bg-no-repeat text-plum sm:bg-auto lg:mb-44 lg:bg-right-top">
      <div className="mx-auto max-w-1130 pt-12 pb-8">
        <Heading
          level="h2"
          font={title.fields.fontFamily}
          style={title.fields.style}
          className="mb-6 max-w-290 lg:mb-8 lg:max-w-500"
        >
          <PlainRichText content={title.fields.content} />
        </Heading>
        <Paragraph className="mb-4 max-w-290 pr-16 text-sm lg:max-w-500 lg:text-base">
          <PlainRichText content={subheading} />
        </Paragraph>
        <Paragraph className="mb-4 max-w-[70%] text-sm lg:text-base">
          <PlainRichText content={bodyCopy?.fields.content} />
        </Paragraph>

        <div className="mx-[-16px] mt-12 grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4 lg:mt-16 lg:gap-4">
          {teamMembers?.map(({ fields }, index) => {
            return (
              <div
                key={`teamMember_${index}`}
                className="overflow-hidden rounded-md shadow-extra-light"
                data-aos="fade-up"
              >
                {fields.photo && (
                  <Image
                    file={fields.photo.fields.asset}
                    aspectRatio={'12x16'}
                  />
                )}
                {!fields.photo && (
                  <div
                    className={`w-full ${imageAspectRatioClasses['12x16']} bg-white`}
                  ></div>
                )}
                <div className="bg-white p-3 lg:p-4">
                  <div className="mb-1 font-display-a text-sm font-extrabold lg:text-base">
                    {fields.name}
                  </div>
                  <div className="mb-1 font-display-s text-xs lg:mb-3 lg:text-15px">
                    {fields.role}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
};
