/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { PlainRichText } from '@atoms/PlainRichText/PlainRichText';
import { Heading } from '@atoms/Heading/Heading';
import { ComponentWrapper } from '@molecules/ComponentWrapper/ComponentWrapper';
import { Container } from '@molecules/Container/Container';
import { CTATandem } from '@molecules/CTATandem/CTATandem';
import { ServiceList } from '@molecules/Services/ServiceList/ServiceList';

// Models
import { TypeCon26 } from '@models/contentful';

/**
 * POST CONTENT FIGURES - INEV11
 * @param TypeCon26
 * @returns
 */
export const Con26: FunctionComponent<TypeCon26> = ({
  fields: {
    title,
    callToAction,
    services,
    animated,
    serviceItemBoxDefaultColourWhite = true,
  },
}) => {
  return (
    <ComponentWrapper
      backgroundColor="Transparent"
      className="my-16 flex min-h-290 items-center lg:my-32 lg:min-h-550"
    >
      <Container className="pb-14 md:max-w-1450 lg:pb-0">
        {title && (
          <div data-aos="fade-up">
            <Heading
              level="h2"
              font={title?.fields?.fontFamily || ''}
              style={title?.fields?.style || ''}
              className="mb-6 text-center text-primary lg:mb-8"
            >
              <PlainRichText content={title?.fields?.content || ''} />
            </Heading>
          </div>
        )}

        <CTATandem callsToAction={callToAction} />

        {/* tile grid */}
        {services && (
          <div data-aos="fade-up">
            <ServiceList
              services={services}
              animated={animated}
              serviceItemBoxDefaultColourWhite={
                serviceItemBoxDefaultColourWhite
              }
            />
          </div>
        )}
      </Container>
    </ComponentWrapper>
  );
};
