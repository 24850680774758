/* Dependencies */
import { FunctionComponent } from 'react';

// Models
import { TagProps, theme } from '../Tag/Tag.model';

/**
 * Link component
 * @param props - Component props.
 */
export const Tag: FunctionComponent<TagProps> = ({
  children,
  className,
  backgroundColor = 'Transparent',
}) => {
  return (
    <li
      className={`${
        className || ''
      } m-0 mt-4 mr-2 flex min-w-55 items-center justify-center rounded-md px-3 py-0.5 text-center font-display-a text-xs font-medium text-primary last:mr-0 lg:min-w-80 lg:rounded-[10px] lg:py-[3px] lg:text-small ${
        theme[backgroundColor]
      }`}
    >
      {children}
    </li>
  );
};
