/* Dependencies */
import { FunctionComponent } from 'react';
import * as Contentful from 'contentful';

// Services
import { httpClient } from '../../../services/httpClient';

// Helpers
import {
  getFormattedDate,
  getFormattedDateTime,
} from '../../../helpers/getFormattedDate/getFormattedDate';

// Components
import { ComponentWrapper } from '../../Molecules/ComponentWrapper/ComponentWrapper';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { Heading } from '../../Atoms/Heading/Heading';
import { InsightCard } from '../../Molecules/InsightCard/InsightCard';
import { EventCard } from '../../Molecules/EventCard/EventCard';

// Models
import { TypeInev03Fields } from '../../../models/contentful';
import { TypeInev03ViewModel } from './Inev03.model';
import {
  RetrieveLatestEventsResponse,
  RetrieveLatestInsightResponse,
  RetrieveLatestCaseStudiesResponse,
} from '../../../services/Contentful/Contentful.model';

/**
 * INSIGHT & EVENT & Case Study POST CAROUSEL - INEV03
 * @param TypeInev03
 * @returns
 */
export const Inev03: FunctionComponent<TypeInev03ViewModel> = ({
  fields: { articles, events, caseStudies, title, subheading },
}) => {
  const entries = 5;

  return (
    <ComponentWrapper
      backgroundColor={`Plum`}
      className={`flex items-center overflow-hidden bg-insight-pattern bg-[length:65%] bg-right-top bg-no-repeat pt-10 pb-16 sm:bg-auto lg:py-20`}
    >
      <div className="relative mx-auto w-full lg:max-w-1550 lg:px-10">
        <div data-aos="fade-up" className="px-10 lg:px-0">
          {/* title */}
          <Heading level="h2" className="mb-6 text-primary lg:mb-16">
            <PlainRichText content={title.fields.content} />
          </Heading>
          {/* subtitle */}
          <Heading
            level="h3"
            style="Heading 6"
            className="mb-6 font-extrabold uppercase text-primary lg:mb-8"
          >
            {subheading}
          </Heading>
        </div>
        {/* Carousel */}
        <div
          data-aos="fade-up"
          className={`${entries > 1 ? 'pl-10' : 'px-10'} lg:px-0`}
        >
          <div
            className={`grid grid-flow-col gap-x-4 sm:gap-x-7 ${
              entries > 1
                ? 'auto-cols-[calc(100%-12px)] grid-cols-[calc(100%-12px)] pr-10 lg:pr-2 lg:pl-2'
                : 'auto-cols-[100%] grid-cols-[100%]'
            }
          ${
            entries >= 3
              ? 'sm:auto-cols-[calc(50%-27px)] sm:grid-cols-[calc(50%-27px)]'
              : 'sm:auto-cols-[calc(50%-15px)] sm:grid-cols-[calc(50%-15px)]'
          } snap-x snap-proximity overflow-x-auto py-6 scrollbar-none lg:auto-cols-[calc(33.33%-19px)] lg:grid-cols-[calc(33.33%-19px)] xl:auto-cols-[calc(25%-21px)] xl:grid-cols-[calc(25%-21px)]`}
          >
            {articles &&
              articles.map(
                (
                  {
                    fields: { slug, image, tags, title, author, publishedDate },
                  },
                  index
                ) => {
                  return (
                    <InsightCard
                      key={`${index}`}
                      link={`/insights/${slug.fields.slug}`}
                      image={image?.fields.asset}
                      tags={tags?.map((tag) => {
                        return tag.fields;
                      })}
                      title={title}
                      publishedDate={
                        publishedDate
                          ? getFormattedDate(publishedDate)
                          : undefined
                      }
                      author={author.fields.name}
                    />
                  );
                }
              )}
            {caseStudies &&
              caseStudies.map(
                (
                  {
                    fields: { slug, image, tags, title },
                  },
                  index
                ) => {
                  return (
                    <InsightCard
                      key={`${index}`}
                      link={`/case-studies/${slug.fields.slug}`}
                      image={image?.fields.asset}
                      tags={tags?.map((tag) => {
                        return tag.fields;
                      })}
                      title={title}
                    />
                  );
                }
              )}
            {events &&
              events.map(
                (
                  { fields: { slug, image, tags, title, eventDate, location } },
                  index
                ) => {
                  return (
                    <EventCard
                      key={`${index}`}
                      link={`/events/${slug.fields.slug}`}
                      image={image?.fields.asset}
                      tags={tags?.map((tag) => {
                        return tag.fields;
                      })}
                      title={title.fields}
                      eventDate={
                        eventDate ? getFormattedDateTime(eventDate) : undefined
                      }
                      location={location}
                    />
                  );
                }
              )}
          </div>
        </div>
      </div>
    </ComponentWrapper>
  );
};

// Store Cache
let articlesCache: RetrieveLatestInsightResponse;
let eventsCache: RetrieveLatestEventsResponse;
let caseStudiesCache: RetrieveLatestCaseStudiesResponse;
let displayLimitCache: number;

/**
 * Get Enhanced Props for INEV03
 * @param component - INEV03 Component
 * @param preview - Preview Mode
 * @returns
 */
export async function getInev03EnhancedProps(
  component: Contentful.Entry<Record<string, any>>,
  preview = false
) {
  const componentData = component as Contentful.Entry<TypeInev03Fields>;
  if (componentData.sys.contentType.sys.id !== 'blockCaseStudyPostCarousel') {
    // Article Pages
    let articlePages: RetrieveLatestInsightResponse;

    // Use Cache
    if (
      articlesCache &&
      displayLimitCache === componentData.fields.displayLimit
    ) {
      articlePages = articlesCache;
    }
    // Default
    else {
      articlePages = await httpClient.contentful.retrieveLatestInsights(
        {
          limit: componentData.fields.displayLimit,
          locale: 'en-GB',
        },
        preview
      );
      articlesCache = articlePages;
    }

    // Set Article Pages
    component.fields.articles = articlePages.items;

    // Event Pages
    let eventPages: RetrieveLatestEventsResponse;

    // Use Cache
    if (eventsCache && displayLimitCache === componentData.fields.displayLimit) {
      eventPages = eventsCache;
    } else {
      eventPages = await httpClient.contentful.retrieveLatestEvents(
        {
          limit: componentData.fields.displayLimit,
          locale: 'en-GB',
        },
        preview
      );
      eventsCache = eventPages;
    }

    // Set Event Pages
    component.fields.events = eventPages.items;
  } else {
    // Case Study Pages
    let caseStudyPages: RetrieveLatestCaseStudiesResponse;

    // Use Cache
    if (caseStudiesCache && displayLimitCache === componentData.fields.displayLimit) {
      caseStudyPages = caseStudiesCache;
    } else {
      caseStudyPages = await httpClient.contentful.retrieveLatestCaseStudies(
        {
          limit: componentData.fields.displayLimit,
          locale: 'en-GB',
        },
        preview
      );
      caseStudiesCache = caseStudyPages;
    }

    // Set Event Pages
    component.fields.caseStudies = caseStudyPages.items;
  }
  // Return the component
  return component;
}