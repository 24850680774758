import React from 'react';
import { Input } from '@molecules/Forms/Input/Input';
import { Question } from './QuestionList.model';
import { FormikTouched } from 'formik';

interface QuestionListProps {
  questions: Question[];
  values: Record<string, any>;
  errors: Record<string, any>;
  touched: FormikTouched<any>;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleFieldError: (
    errors: Record<string, any>,
    touched: FormikTouched<any>,
    fieldName: string
  ) => string | undefined;
}

export const QuestionList = ({
  questions,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleFieldError,
}: QuestionListProps) => {
  if (questions.length === 0) {
    return null;
  }
  return (
    <>
      {questions.map(
        ({ question, required }, index) =>
          question && (
            <div key={index} className="w-full">
              <Input
                label={question}
                name={`formQuestion${index}`}
                value={values[`formQuestion${index}`]}
                onChange={handleChange}
                onBlur={handleBlur}
                error={handleFieldError(
                  errors,
                  touched,
                  `formQuestion${index}`
                )}
                required={required}
                inputMode={'text'}
                type={'text'}
              />
            </div>
          )
      )}
    </>
  );
};
