/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { ContainerArrow } from '../../Molecules/ContainerArrow/ContainerArrow';
import { Heading } from '../../Atoms/Heading/Heading';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';
import { LinkButton } from '../../Atoms/LinkButton/LinkButton';
import { getLinkUrl } from '../../../helpers/getLinkUrl/getLinkUrl';
import { ContainerArrowProps } from '../../Molecules/ContainerArrow/ContainerArrow.model';
import { Image } from '../../Molecules/Image/Image';

// Models
import { TypeCon14 } from '../../../models/contentful';

/**
 * CONTENT GENERAL CTA - CON14
 * @param TypeCon14
 * @returns
 */
export const Con14: FunctionComponent<TypeCon14> = ({
  fields: { title, bodyCopy, callToAction, theme, image },
}) => {
  return (
    <div className={`relative flex w-full`}>
      <ContainerArrow
        classNameWrapper="w-11/12 md:w-8/12 min-h-375 lg:min-h-550 xl:min-h-650 z-10"
        className="flex flex-col justify-center py-8 pr-[22.5%] pl-10 lg:pb-20 lg:pt-24 xl:pb-32 xl:pt-36"
        arrowsSide="Right"
        arrowsSize="Small"
        overlap={false}
        bgColour={
          theme.fields.colourScheme.toLowerCase() as ContainerArrowProps['bgColour']
        }
        animation="fade-right"
      >
        <div
          data-aos="fade-up"
          data-aos-delay="350"
          className="ml-auto max-w-550"
        >
          {title && (
            <Heading
              level="h2"
              font={title.fields.fontFamily}
              style={title.fields.style}
              className="mb-6 text-primary lg:mb-14"
            >
              <PlainRichText content={title.fields.content} />
            </Heading>
          )}
          {bodyCopy && (
            <Paragraph variant="base" className="mb-6 text-primary lg:mb-14">
              <PlainRichText content={bodyCopy?.fields.content} />
            </Paragraph>
          )}

          {callToAction && (
            <LinkButton
              variant={callToAction.fields.theme?.fields.colourScheme}
              openInNewTab={callToAction.fields.openInNewTab}
              to={getLinkUrl(callToAction)}
              hasIcon={
                callToAction.fields.assetLink &&
                callToAction.fields.assetLink.fields
                  ? true
                  : false
              }
              arrowDirection={callToAction.fields.arrowDirection}
            >
              {callToAction.fields.title}
              {callToAction.fields.assetLink &&
                callToAction.fields.assetLink.fields && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="ml-1 h-3 w-3 lg:h-4 lg:w-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                    />
                  </svg>
                )}
            </LinkButton>
          )}
        </div>
      </ContainerArrow>
      {image && (
        <div className="absolute inset-y-0 right-0 z-0 h-full w-6/12">
          <Image file={image.fields.asset} aspectRatio={'absolute'} />
        </div>
      )}
    </div>
  );
};
