/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { EmailNewsletterForm } from '../EmailNewsletterForm/EmailNewsletterForm';
import { ContactUsForm } from '../ContactUsForm/ContactUsForm';
import { EbookForm } from '../EbookForm/EbookForm';
import { EventForm } from '../EventForm/EventForm';

// Models
import { TypeForm } from '@models/contentful';

import { Question } from '../QuestionList/QuestionList.model';
/**
 * Render Form
 * @param TypeForm
 * @returns
 */
export const RenderForm: FunctionComponent<{ form: TypeForm }> = ({
  form: {
    sys: { id },
    fields: {
      sourceIdentifier,
      formType,
      formQuestion1,
      formQuestion2,
      formQuestion3,
      formQuestion1Required,
      formQuestion2Required,
      formQuestion3Required,
      onSubmitAction,
    },
  },
}): JSX.Element => {
  const questions = [
    formQuestion1 && {
      question: formQuestion1,
      required: Boolean(formQuestion1Required),
    },
    formQuestion2 && {
      question: formQuestion2,
      required: Boolean(formQuestion2Required),
    },
    formQuestion3 && {
      question: formQuestion3,
      required: Boolean(formQuestion3Required),
    },
  ].filter(Boolean);

  switch (formType) {
    case 'Email Newsletter': {
      return (
        <EmailNewsletterForm
          formid={id}
          source={sourceIdentifier}
          callToAction={onSubmitAction}
          questions={questions as Question[]}
        />
      );
    }
    case 'Contact Us': {
      return (
        <ContactUsForm
          formid={id}
          source={sourceIdentifier}
          callToAction={onSubmitAction}
          questions={questions as Question[]}
        />
      );
    }
    case 'Ebook': {
      return (
        <EbookForm
          formid={id}
          source={sourceIdentifier}
          callToAction={onSubmitAction}
          questions={questions as Question[]}
        />
      );
    }
    case 'Event': {
      return (
        <EventForm
          formid={id}
          source={sourceIdentifier}
          callToAction={onSubmitAction}
          questions={questions as Question[]}
        />
      );
    }
    default: {
      return <></>;
    }
  }
};
