/* Dependencies */
import { Entry } from 'contentful';
import * as yup from 'yup';
import { TypeCallToActionFields } from '@models/contentful';

import { Question } from '../QuestionList/QuestionList.model';

// Generics
export interface EbookFormProps {
  /**
   * Contentful ID for the form
   */
  formid: string;
  /**
   * Identifier for the form.
   */
  source: string;
  /**
   * Possible questions for the Form
   */
  questions: Question[];

  /**
   * Call To Action
   */
  callToAction?: Entry<TypeCallToActionFields>;
}

export interface EbookFormInitialValues {
  source: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
  phone: string;
  company: string;
  recaptcha: string;
  terms: boolean;
  utm_source: string;
  formQuestion0?: string;
  formQuestion1?: string;
  formQuestion2?: string;
  [key: string]: string | boolean | undefined;
}

export const EbookFormSchema = (questions: Question[]) => {
  // Define the type for schema fields with an index signature
  interface SchemaFields {
    source: yup.StringSchema;
    firstName: yup.StringSchema;
    lastName: yup.StringSchema;
    jobTitle: yup.StringSchema;
    email: yup.StringSchema;
    phone: yup.StringSchema;
    company: yup.StringSchema;
    utm_source: yup.StringSchema;
    recaptcha: yup.StringSchema;
    terms: yup.BooleanSchema;
    [key: string]: yup.StringSchema | yup.BooleanSchema; // Index signature
  }

  let schemaFields: Partial<SchemaFields> = {
    source: yup.string().required('Field is required'),
    firstName: yup.string().required('Field is required'),
    lastName: yup.string().required('Field is required'),
    jobTitle: yup.string().required('Field is required'),
    email: yup.string().email('Invalid email').required('Field is required'),
    phone: yup.string().optional(),
    company: yup.string().required('Field is required'),
    utm_source: yup.string().optional(),
    recaptcha: yup.string().required('Field is required'),
    terms: yup
      .boolean()
      .isTrue('Field is required')
      .required('Field is required'),
  };

  questions.forEach(({ question, required }, index) => {
    if (question) {
      let validator = yup.string();

      validator = required
        ? validator.required('Field is required')
        : validator.optional();

      schemaFields[`formQuestion${index}`] = validator;
    }
  });

  return yup.object().shape(schemaFields as SchemaFields);
};
