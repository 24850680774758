/* Dependencies */
import { FunctionComponent, useEffect } from 'react';

// importing aos
import AOS from 'aos';
import 'aos/dist/aos.css';

// Components

// Models
import { StatisticsProps } from './Statistics.model';

/**
 * POST CONTENT FIGURES - Statistics
 * @param TypeStatistic
 * @returns
 */
export const Statistics: FunctionComponent<StatisticsProps> = ({
  className,
  statistics,
  size = 'Large',
}) => {
  const gridStats = ['md:grid-cols-1', 'md:grid-cols-2', 'md:grid-cols-3'];
  useEffect(() => {
    AOS.init({
      throttleDelay: 5,
      offset: 50,
      duration: 750,
      delay: 150,
      once: true,
    });
  }, []);
  return (
    <div className={`mx-auto max-w-974 ${className || ''}`}>
      <div
        className={`grid gap-12 ${size === 'Large' ? 'gap-x-20' : ''} ${
          gridStats[statistics.length - 1]
        } justify-center`}
      >
        {statistics.map(({ fields: { statistic, subheading } }, index) => (
          <div
            data-aos="fade-up"
            data-aos-delay={((index + 1) * 100).toString()}
            className={`flex flex-col items-center space-y-2 text-center text-primary ${
              size === 'Large' ? 'lg:space-y-4' : ''
            }`}
            key={`statistic_${index}`}
          >
            {statistics && (
              <span
                className={`text-5xl font-normal ${
                  size === 'Large' ? 'md:text-6xl lg:text-7xl' : ''
                }`}
              >
                {statistic}
              </span>
            )}
            {subheading && (
              <span
                className={`font-display-a text-sm font-medium  ${
                  size === 'Large' ? 'lg:text-2xl' : 'lg:text-base'
                }`}
              >
                {subheading}
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
