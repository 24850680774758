/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { ComponentWrapper } from '../../Molecules/ComponentWrapper/ComponentWrapper';
import { Container } from '../../Molecules/Container/Container';
import { Heading } from '../../Atoms/Heading/Heading';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';
import { Hotspot } from '../../Molecules/Hotspot/Hotspot';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { Statistics } from '../../Atoms/Statistics/Statistics';

// Models
import { TypeCon07 } from '../../../models/contentful';
import { illustrationTypes, positions, sides } from './Con07.model';

/**
 * CONTENT PARTNERSHIP HOTSPOTS - CON07
 * @param TypeCon07
 * @returns
 */
export const Con07: FunctionComponent<TypeCon07> = ({
  fields: {
    subheading,
    title,
    bodyCopy,
    theme,
    imagesWithHotspot: hotspots,
    illustrationType,
    statistics,
    reverse,
  },
}) => {
  return (
    <ComponentWrapper
      backgroundColor={`${theme?.fields.colourScheme}`}
      className={`flex items-center overflow-hidden py-12 coloured-panel:py-0 lg:py-28 coloured-panel:lg:py-0`}
    >
      <Container className="relative grid w-full gap-y-12 gap-x-8 sm:max-w-1270 md:grid-cols-12">
        <div
          className={`flex w-full max-w-335 flex-col justify-between md:col-span-4 ${
            reverse ? 'md:order-2' : ''
          }`}
        >
          <div data-aos="fade-up">
            {subheading && (
              <Heading
                level="h2"
                style="Heading 6"
                className="mb-2.5 uppercase text-primary"
              >
                <PlainRichText content={subheading} />
              </Heading>
            )}
            {title && (
              <Heading
                level="h3"
                font={`${title?.fields.fontFamily}`}
                style={`${title?.fields.style}`}
                className="text-primary md:mb-6 lg:mb-8"
              >
                <PlainRichText content={title?.fields.content} />
              </Heading>
            )}
          </div>

          <div className="hidden md:block" data-aos="fade-up">
            {bodyCopy && (
              <Paragraph className="text-small text-primary lg:max-w-335 lg:text-base">
                <PlainRichText content={bodyCopy?.fields.content} />
              </Paragraph>
            )}
          </div>
        </div>
        <div className={`md:col-span-8 ${reverse ? 'md:order-1' : ''}`}>
          <div className="relative w-full xl:pr-16">
            <div
              data-aos="fade-up"
              className="flex h-full max-h-[490px] w-full lg:max-h-[400px] xl:max-h-[490px]"
            >
              <img
                className="mx-auto w-full max-w-250 md:max-w-none"
                src={illustrationTypes[illustrationType]['url']}
                alt="Central Main"
                width={illustrationTypes[illustrationType]['width']}
                height={illustrationTypes[illustrationType]['height']}
              />
            </div>
            {hotspots?.map(({ fields }, index) => (
              <Hotspot
                key={`hotspot_${index}`}
                title={`${fields.title}`}
                subheading={`${fields.subheading}`}
                className={`absolute ${positions[illustrationType][index]}`}
                side={`${sides[illustrationType][index]}`}
                index={index}
                theme={
                  theme?.fields.colourScheme === 'Blue'
                    ? 'Tertiary'
                    : 'Secondary'
                }
                textSize="Small"
              />
            ))}
          </div>

          {statistics && (
            <Statistics
              statistics={statistics}
              size="Small"
              className="mt-16 hidden sm:max-w-550 lg:block"
            />
          )}
        </div>
        <div className="md:hidden" data-aos="fade-up">
          {bodyCopy && (
            <Paragraph className="text-small text-primary lg:max-w-335 lg:text-base">
              <PlainRichText content={bodyCopy?.fields.content} />
            </Paragraph>
          )}
        </div>
      </Container>
    </ComponentWrapper>
  );
};
