/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { Heading } from '../../Atoms/Heading/Heading';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';
import { DemoComponentProps } from './DemoComponent.model';
import { Link } from '../../Atoms/Link/Link';
import { UnorderedList } from '../../Atoms/UnorderedList/UnorderedList';
import { LinkButton } from '../../Atoms/LinkButton/LinkButton';
import { BlockQuote } from '../../Atoms/BlockQuote/BlockQuote';
import { ContainerArrow } from '../../Molecules/ContainerArrow/ContainerArrow';
import { ComponentWrapper } from '../../Molecules/ComponentWrapper/ComponentWrapper';

// Models

/**
 * Demo Components
 * @param props - Required props.
 */
export const DemoComponent: FunctionComponent<DemoComponentProps> = () => {
  const entries = 5;
  return (
    <div className="min-h-full w-full">
      <ComponentWrapper
        backgroundColor={`Plum`}
        className={`flex items-center overflow-hidden bg-insight-pattern bg-[length:65%] bg-right-top bg-no-repeat pt-10 pb-16 sm:bg-auto lg:py-20`}
      >
        <div className="relative mx-auto w-full max-w-1550 lg:px-10">
          <div data-aos="fade-up" className="px-10 lg:px-0">
            <Heading level="h2" className="mb-6 text-primary lg:mb-16">
              Insights <br /> & Events
            </Heading>
            <Heading
              level="h3"
              style="Heading 6"
              className="mb-6 font-extrabold uppercase text-primary lg:mb-8"
            >
              Recent Posts
            </Heading>
          </div>
          <div
            data-aos="fade-up"
            className={`${entries > 1 ? 'pl-10' : 'px-10'} lg:px-0`}
          >
            <div
              className={`grid grid-flow-col gap-x-4 sm:gap-x-7 ${
                entries > 1
                  ? 'auto-cols-[calc(100%-12px)] grid-cols-[calc(100%-12px)] pr-10 lg:pr-0'
                  : 'auto-cols-[100%] grid-cols-[100%]'
              }
          ${
            entries >= 3
              ? 'sm:auto-cols-[calc(50%-27px)] sm:grid-cols-[calc(50%-27px)]'
              : 'sm:auto-cols-[calc(50%-15px)] sm:grid-cols-[calc(50%-15px)]'
          } snap-x snap-proximity overflow-x-auto pb-6 lg:auto-cols-[calc(33.33%-19px)] lg:grid-cols-[calc(33.33%-19px)] xl:auto-cols-[calc(25%-21px)] xl:grid-cols-[calc(25%-21px)]`}
            ></div>
          </div>
        </div>
      </ComponentWrapper>

      <br />
      <br />
      <br />
      <br />
      <br />

      <Heading level="h1" className="">
        {/* <PlainRichText content={title} /> */}
        FRONTEND KITCHEN SINK
      </Heading>

      {/* headings */}
      <Heading level="h1" className="">
        heading 1
      </Heading>
      <Heading level="h2" className="">
        heading 2
      </Heading>
      <Heading level="h3" className="">
        heading 3
      </Heading>
      <Heading level="h4" className="">
        subheading (h4)
      </Heading>
      <Heading level="h5" className="">
        just a spare heading 5
      </Heading>
      <Heading level="h6" className="">
        microheading (h6)
      </Heading>

      {/* Paragraph Atom */}
      <Paragraph>
        Paragraph atom: Lorem ipsum dolor sit amet consectetur adipiscing elit
        senectus malesuada, commodo urna himenaeos rhoncus per etiam metus in
        justo, dictum condimentum nam nisl penatibus sapien mi montes. Mattis
        feugiat ornare varius vel inceptos nunc odio luctus, massa hac tincidunt
        nisi fringilla habitasse netus cras, torquent ligula leo diam platea
        blandit gravida
      </Paragraph>

      {/* Lists & Links */}
      <UnorderedList>
        <li>
          <Link to="https://www.google.com">Our services</Link>
        </li>
        <li>
          <Link to="https://www.google.com" variant="none">
            Our services
          </Link>
        </li>
        <li>
          <Link to="https://www.google.com" variant="primary">
            Contact
          </Link>
        </li>
        <li>
          <Link to="https://www.google.com" variant="secondary">
            Anything else
          </Link>
        </li>
      </UnorderedList>
      <UnorderedList>
        <li>Point 1</li>
        <li>Point two</li>
        <li>Point three</li>
      </UnorderedList>
      <UnorderedList>
        <li>Point 4</li>
        <li>Point five</li>
        <li>Point 6</li>
      </UnorderedList>

      {/* link buttons */}
      <div className="theme-red mb-3 bg-fill p-4">
        <LinkButton variant="secondary" to="https://www.google.com">
          Link Button
        </LinkButton>
      </div>
      <div className="mb-3 bg-fill p-4">
        <LinkButton variant="secondary" to="https://www.google.com">
          Link Button
        </LinkButton>
      </div>
      <div className="theme-blue mb-3 bg-fill p-4">
        <LinkButton variant="secondary" to="https://www.google.com">
          Link Button
        </LinkButton>
      </div>
      <div className="theme-plum mb-3 bg-fill p-4">
        <LinkButton
          variant="secondary"
          arrowDirection="Left"
          to="https://www.google.com"
        >
          Link Button
        </LinkButton>
      </div>

      {/* blockquote */}
      <BlockQuote>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eveniet illum
        vero consequuntur fuga tempora! Modi quidem accusantium ipsam et
        possimus, veniam fugiat dignissimos minus optio soluta beatae
        repudiandae quibusdam perferendis.
      </BlockQuote>

      <div className="relative flex w-full items-center">
        <ContainerArrow
          classNameWrapper="w-7/12"
          className="flex flex-col justify-center pl-[10%] pr-[15%]"
          arrowsSide="Right"
          overlap={true}
          bgColour="blue"
        >
          <h1>Text over the triangle</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur.
          </p>
        </ContainerArrow>
        <ContainerArrow
          classNameWrapper="ml-auto w-7/12"
          className="pt-[75%]"
          arrowsSide="Left"
        >
          <img
            src="//picsum.photos/1240/1240/"
            className="absolute top-0 left-0 h-full w-full object-cover"
          />
        </ContainerArrow>
      </div>

      <div className="relative flex w-full items-center">
        <ContainerArrow
          classNameWrapper="w-7/12"
          className="flex flex-col justify-center pl-[10%] pr-[15%]"
          arrowsSide="Right"
          overlap={true}
          bgColour="red"
        >
          <h1>Container with Border</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur.
          </p>
        </ContainerArrow>
        <ContainerArrow
          classNameWrapper="ml-auto w-7/12"
          className="pt-[75%]"
          border={true}
          arrowsSide="Left"
          bgColour="red"
        >
          <img
            src="//picsum.photos/1240/1240/"
            className="absolute top-0 left-0 h-full w-full object-cover"
          />
        </ContainerArrow>
      </div>
      <div className="relative flex w-full items-stretch">
        <ContainerArrow
          classNameWrapper="w-7/12"
          className="pt-[75%]"
          arrowsSide="Right"
          bgColour="red"
        >
          <img
            src="//picsum.photos/1240/1240/"
            className="absolute top-0 left-0 h-full w-full object-cover"
          />
        </ContainerArrow>
        <ContainerArrow
          classNameWrapper="w-7/12"
          className="flex flex-col justify-center py-16 pr-[10%] pl-[25%]"
          arrowsSide="Left"
          overlap={false}
          bgColour="plum"
        >
          <h1>Container with Border 44</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur.
          </p>
        </ContainerArrow>
      </div>
    </div>
  );
};
