/* Dependencies */
import React, { useEffect, useState } from 'react';

// Helpers
import { uniqueId } from '../../../../helpers/uniqueId/uniqueId';
import { getDescribedBy } from '../../../../helpers/getDescribedBy/getDescribedBy';

// Models
import { SelectProps } from './Select.model';

/**
 * Input Component
 */
export const Select: React.FC<SelectProps> = ({
  autoFocus,
  autoComplete,
  disabled,
  error,
  label,
  name,
  onChange,
  onBlur,
  options,
  placeholder,
  required,
  supportText,
  value,
}) => {
  // Set The Initial State
  const [id] = useState(uniqueId());
  const [describedBy, setDescribedBy] = useState('');

  // Set the Tool tip on ID Change
  useEffect(() => {
    setDescribedBy(getDescribedBy({ error, id, name, supportText }));
  }, [id, error, name, supportText]);

  // Render the component.
  return (
    <div className="w-full">
      <label htmlFor={id} className="block font-display-s text-base">
        {label}
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <select
          id={id}
          name={name}
          onChange={onChange.bind(this)}
          onBlur={onBlur.bind(this)}
          aria-invalid={error ? true : false}
          aria-describedby={describedBy}
          required={required}
          placeholder={placeholder}
          disabled={disabled}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          value={value ? value : ''}
          className={`h-9 border-b bg-transparent text-small outline-0 lg:text-base ${
            error &&
            'border-red text-red placeholder-red focus:border-red focus:outline-none focus:ring-red '
          } block w-full`}
        >
          {placeholder ? (
            <option value="" disabled>
              {placeholder}
            </option>
          ) : null}

          {options.map((option) => {
            return (
              <option
                defaultChecked={option.selected}
                value={option.key}
                key={option.key}
              >
                {option.value}
              </option>
            );
          })}
        </select>
      </div>

      {error || supportText ? (
        <div className="mt-1 flex w-full flex-row flex-wrap items-start">
          {error || supportText ? (
            <small
              className={`text-sm ${error && 'text-red'}`}
              id={`${id}_${name}-help`}
            >
              {!error ? supportText : error}
            </small>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
