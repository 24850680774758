/* Dependencies */
import { FunctionComponent } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Import Swiper Modules
import { A11y, Navigation } from 'swiper';

// Helpers
import { getLinkUrl } from '../../../helpers/getLinkUrl/getLinkUrl';

// Components
import { ComponentWrapper } from '../../Molecules/ComponentWrapper/ComponentWrapper';
import { Container } from '../../Molecules/Container/Container';
import { Logo } from '../../Atoms/Logo/Logo';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { Heading } from '../../Atoms/Heading/Heading';
import { Link } from '../../Atoms/Link/Link';

// Models
import { TypeCon11 } from '../../../models/contentful';

/**
 * CONTENT LOGO GRID - CON11 and CON12
 * CMS toggle for carousel or static
 * @param TypeCon11
 * @returns
 */
export const Con11: FunctionComponent<TypeCon11> = ({
  fields: { title, logos, isCarousel, numPerSlide },
}) => {
  if (!logos) {
    return null;
  }
  return (
    <ComponentWrapper backgroundColor="Transparent" className="my-[70px]">
      <Container>
        <div data-aos="fade-up">
          {title && (
            <Heading
              level="h2"
              style={`${title.fields.style}`}
              className="mb-6 text-center lg:mb-10"
            >
              <PlainRichText content={title.fields.content} />
            </Heading>
          )}
          {isCarousel ? (
            <>
              <Swiper
                className="swiper-con11"
                navigation={true}
                slidesPerView={'auto'}
                centeredSlides={true}
                spaceBetween={0}
                loop={true}
                modules={[Navigation, A11y]}
                breakpoints={{
                  768: {
                    slidesPerView: 4,
                    centeredSlides: false,
                    spaceBetween: 8,
                  },
                  1024: {
                    slidesPerView: numPerSlide,
                    spaceBetween: 16,
                    centeredSlides: false,
                  },
                }}
              >
                {logos.map(({ fields: { link, image } }, index) => {
                  // Handle missing image
                  if (!image) {
                    return null;
                  }

                  return (
                    <SwiperSlide
                      className="relative mx-auto max-w-[50%] px-4"
                      key={index}
                    >
                      {link && (
                        <Link
                          variant="none"
                          to={getLinkUrl(link)}
                          openInNewTab={link?.fields.openInNewTab}
                          className="absolute top-0 left-0 z-10 mb-2.5 h-full w-full min-w-[100px] overflow-hidden rounded-md lg:mb-4"
                        >
                          <span className="sr-only">view company</span>
                        </Link>
                      )}
                      {image.fields.asset && (
                        <Logo
                          file={image?.fields.asset}
                          className="mx-auto object-contain object-center"
                        />
                      )}
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </>
          ) : (
            <>
              <div
                className={`flex flex-wrap justify-center gap-12 px-8 md:gap-20 lg:gap-24 lg:px-10`}
              >
                {logos.map(({ fields: { link, image } }, index) => {
                  // Handle missing image
                  if (!image) {
                    return null;
                  }

                  return (
                    // link
                    <div className="basis-[43%] md:basis-[24.6%] xl:basis-[18.4%]" key={index}>
                      {link && (
                        <Link
                          variant="none"
                          to={getLinkUrl(link)}
                          className="absolute top-0 left-0 z-10 mb-2.5 h-full w-full overflow-hidden rounded-md lg:mb-4"
                        >
                          <span className="sr-only">view company</span>
                        </Link>
                      )}
                      {/* image */}
                      <div className="min-w-80">
                        {image.fields.asset && (
                          <Logo
                            file={image?.fields.asset}
                            className="mx-auto object-contain object-center"
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </Container>
    </ComponentWrapper>
  );
};
