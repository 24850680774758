/* Dependencies */
import { FunctionComponent } from 'react';

// Helpers
import { prefixSlug } from '../../../helpers/prefixSlug/prefixSlug';
import { getSlugUrl } from '../../../helpers/getSlugUrl/getSlugUrl';

// Components
import { Image } from '../Image/Image';
import { Link } from '../../Atoms/Link/Link';
import { Heading } from '../../Atoms/Heading/Heading';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';
import { BlockQuote } from '../../Atoms/BlockQuote/BlockQuote';
import { UnorderedList } from '../../Atoms/UnorderedList/UnorderedList';
import { OrderedList } from '../../Atoms/OrderedList/OrderedList';
import { MapComponents } from '../../Mappers/MapComponents/MapComponents';
import { LinkButton } from '../../Atoms/LinkButton/LinkButton';

// Contentful
import {
  BLOCKS,
  MARKS,
  INLINES,
  TopLevelBlock,
} from '@contentful/rich-text-types';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';

// Models
import { RichTextProps } from './RichText.model';

/**
 * Rich Text Component
 * @params props - Required component props.
 */
export const RichText: FunctionComponent<RichTextProps> = ({ content }) => {
  // Optiopns
  const renderOptions: Options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <strong>{text}</strong>,
      [MARKS.ITALIC]: (text) => <em>{text}</em>,
      [MARKS.UNDERLINE]: (text) => <u>{text}</u>,
      [MARKS.CODE]: (text) => <code>{text}</code>,
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        if (node.data?.uri.startsWith('/')) {
          return (
            <Link
              to={prefixSlug(node.data.uri)}
              className="underline underline-offset-2"
              variant="none"
            >
              {children}
            </Link>
          );
        }
        return (
          <Link
            to={node.data?.uri}
            className="underline underline-offset-2"
            variant="none"
          >
            {children}
          </Link>
        );
      },
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        return (
          <Link variant="none" to={getSlugUrl(node.data.target)}>
            {children}
          </Link>
        );
      },
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        return (
          <Link variant="none" to={node.data.target.fields.file.url}>
            {children}
          </Link>
        );
      },
      [BLOCKS.HEADING_1]: (_node, children) => (
        <div className="mx-auto mb-8 w-full last:mb-0 lg:mb-20">
          <Heading level="h1">{children}</Heading>
        </div>
      ),
      [BLOCKS.HEADING_2]: (_node, children) => (
        <div className="mx-auto mb-8 w-full last:mb-0 lg:mb-20">
          <Heading className="lg:text-[50px]" level="h2">
            {children}
          </Heading>
        </div>
      ),
      [BLOCKS.HEADING_3]: (_node, children) => (
        <div className="mx-auto mb-6 w-full last:mb-0 lg:mb-8">
          <Heading level="h3">{children}</Heading>
        </div>
      ),
      [BLOCKS.HEADING_4]: (_node, children) => (
        <div className="mx-auto mb-6 w-full last:mb-0 lg:mb-8">
          <Heading level="h4">{children}</Heading>
        </div>
      ),
      [BLOCKS.HEADING_5]: (_node, children) => (
        <div className="mx-auto mb-6 w-full last:mb-0 lg:mb-8">
          <Heading level="h5">{children}</Heading>
        </div>
      ),
      [BLOCKS.HEADING_6]: (_node, children) => (
        <div className="mx-auto mb-6 w-full last:mb-0 lg:mb-8">
          <Heading level="h6">{children}</Heading>
        </div>
      ),
      [BLOCKS.QUOTE]: (_node, children) => (
        <div className="mx-auto mb-16 w-full last:mb-0 lg:my-20">
          <BlockQuote>{children}</BlockQuote>
        </div>
      ),
      [BLOCKS.OL_LIST]: (_node, children) => (
        <div className="mx-auto mb-8 w-full last:mb-0 lg:mb-10">
          <OrderedList>{children}</OrderedList>
        </div>
      ),
      [BLOCKS.UL_LIST]: (_node, children) => (
        <div className="mx-auto mb-8 w-full last:mb-0 lg:mb-10">
          <UnorderedList>{children}</UnorderedList>
        </div>
      ),
      [BLOCKS.PARAGRAPH]: (_node, children) => (
        <div className="mx-auto mb-8 w-full last:mb-0 lg:mb-10">
          <Paragraph variant="base">{children}</Paragraph>
        </div>
      ),
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        if (node.data.target.sys.contentType.sys.id === 'link') {
          return (
            <div className="mx-auto mb-16 w-full last:mb-0 lg:mb-20">
              <LinkButton to={node.data.target.fields.url}>
                {node.data.target.fields.title}
              </LinkButton>
            </div>
          );
        }
        return (
          <div className="mx-auto mb-16 w-full last:mb-0 lg:my-20">
            <MapComponents components={[node.data.target]} />
          </div>
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        // Image
        if (node.data.target.fields.file.contentType.includes('image')) {
          return (
            <div className="mx-auto mb-16 w-full last:mb-0 lg:my-20">
              <Image file={node.data.target} aspectRatio="fluid" />
            </div>
          );
        }
        // Video
        else if (node.data.target.fields.file.contentType.includes('video')) {
          return (
            <div className="mx-auto mb-16 w-full last:mb-0 lg:my-20">
              <video controls src={node.data.target.fields.file.url} />
            </div>
          );
        }
        // PDF
        else if (
          node.data.target.fields.file.contentType.includes('pdf') ||
          node.data.target.fields.file.contentType.includes('application')
        ) {
          return (
            <div className="mx-auto mb-16 w-full last:mb-0 lg:mb-20">
              <LinkButton to={node.data.target.fields.file.url}>
                Download: {node.data.target.fields.title}
              </LinkButton>
            </div>
          );
        }
        // Catch
        else {
          console.log('Unsupported asset type', node.data.target.fields.file);
          return null;
        }
      },
    },
  };

  return (
    <div className="rich-text whitespace-pre-line">
      {content &&
        documentToReactComponents(
          {
            data: content.data,
            nodeType: BLOCKS.DOCUMENT,
            content: content.content as TopLevelBlock[],
          },
          renderOptions
        )}
    </div>
  );
};
