import { AnimeAnimParams } from 'animejs';

const baseAnimation: AnimeAnimParams = {
  easing: 'easeInOutQuad',
  duration: 1000,
};

export function getAnimation(
  targets: Element[] | Element | null | undefined,
  specificAnimation: AnimeAnimParams,
  reverse?: boolean
): AnimeAnimParams {
  if (!targets) return {};

  if (reverse) specificAnimation = reverseAnimation(specificAnimation);

  const animation = { ...baseAnimation, ...specificAnimation };
  animation.targets = targets;
  return animation;
}

function reverseAnimation(animation: AnimeAnimParams): AnimeAnimParams {
  const reverseAnimation = { ...animation };
  Object.keys(animation).forEach((key) => {
    if (typeof animation[key] != typeof String) {
      reverseAnimation[key] = [...animation[key]].reverse();
    }
  });
  return reverseAnimation;
}

const fadeIn: AnimeAnimParams = {
  opacity: [0, 1],
};

const fadeOut: AnimeAnimParams = {
  opacity: [1, 0],
};

const flattenLeftArrow: AnimeAnimParams = {
  clipPath: [
    'polygon(30% 0%, 100% 0%, 100% 100%, 30% 100%, 0% 50%)',
    'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 50%)',
  ],
  left: ['18px', 0],
};

const flattenRightArrow: AnimeAnimParams = {
  clipPath: [
    'polygon(0% 0%, 70% 0%, 100% 50%, 70% 100%, 0% 100%)',
    'polygon(0% 0%, 100% 0%, 100% 50%, 100% 100%, 0% 100%)',
  ],
  right: ['18px', 0],
};

const flattenLeftArrowBorder: AnimeAnimParams = {
  clipPath: [
    'polygon(calc(30% + -4px) 0%, 100% 0%, 100% 100%, calc(30% + -4px) 100%, 0% 50%)',
    'polygon(calc(0% + 0px) 0%, 100% 0%, 100% 100%, calc(0% + 0px) 100%, 0% 50%)',
  ],
};

const flattenRightArrowBorder: AnimeAnimParams = {
  clipPath: [
    'polygon(0% 0%, calc(70% + 4px) 0%, 100% 50%, calc(70% + 4px) 100%, 0% 100%)',
    'polygon(0% 0%, calc(100% + 0px) 0%, 100% 50%, calc(100% + 0px) 100%, 0% 100%)',
  ],
};

const slideVideoIntoCentre: AnimeAnimParams = {
  width: ['60%', '100%'],
  right: [0, '50vw'],
  translateX: [0, '50%'],
  paddingTop: [0, '64px'],
  paddingBottom: [0, '64px'],
};

const slideTextArrowOut: AnimeAnimParams = {
  opacity: [1, 0],
  translateX: [0, '-100%'],
};

export interface Animations {
  fadeIn: AnimeAnimParams;
  fadeOut: AnimeAnimParams;
  flattenLeftArrow: AnimeAnimParams;
  flattenRightArrow: AnimeAnimParams;
  flattenLeftArrowBorder: AnimeAnimParams;
  flattenRightArrowBorder: AnimeAnimParams;
  slideVideoIntoCentre: AnimeAnimParams;
  slideTextArrowOut: AnimeAnimParams;
}

export const animations: Animations = {
  fadeIn: fadeIn,
  fadeOut: fadeOut,
  flattenLeftArrow: flattenLeftArrow,
  flattenRightArrow: flattenRightArrow,
  flattenLeftArrowBorder: flattenLeftArrowBorder,
  flattenRightArrowBorder: flattenRightArrowBorder,
  slideVideoIntoCentre: slideVideoIntoCentre,
  slideTextArrowOut: slideTextArrowOut,
};
