/* Dependencies */
import { FunctionComponent } from 'react';
import { InformationCircleIcon } from '@heroicons/react/outline';

/**
 * Preview Mode Overlay Component
 */
export const PreviewModeOverlay: FunctionComponent = () => {
  return (
    <div className="fixed bottom-2 left-1/2 z-50 w-full max-w-500 -translate-x-1/2 rounded-md border-2 border-indigo-800 bg-white p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon
            className="h-5 w-5 text-indigo-700"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3 flex-1 items-center md:flex md:justify-between">
          <p className="text-sm text-indigo-700">Preview Mode is Enabled.</p>
          <p className="mt-3 text-sm md:mt-0 md:ml-6">
            <button
              onClick={() => {
                window.location.href = `/api/clear-preview?redirect=${encodeURIComponent(
                  window.location.pathname
                )}`;
              }}
              className="whitespace-nowrap font-medium text-indigo-700 hover:text-indigo-900"
            >
              Deactivate Preview<span aria-hidden="true">&rarr;</span>
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};
