/* Dependencies */
import { FunctionComponent, PropsWithChildren } from 'react';

/**
 * Block Quote
 * @param props - Required component props.
 * @returns
 */
export const BlockQuote: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  return (
    <blockquote className="blockquote mx-auto w-full max-w-800 sm:px-10">
      <span>{children}</span>
    </blockquote>
  );
};
