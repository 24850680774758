/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { Image } from '../../Molecules/Image/Image';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';

// Models
import { TypeInev10 } from '../../../models/contentful';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';

/**
 * POST CONTENT IMAGE QUOTE - INEV10
 * @param TypeInev10
 * @returns
 */
export const Inev10: FunctionComponent<TypeInev10> = ({
  fields: { author, quote },
}) => {
  return (
    <div className="mx-auto max-w-974">
      <div className="grid gap-y-9 gap-x-8 sm:grid-cols-12 lg:gap-x-16">
        <div className="sm:col-span-5">
          {author.fields.photo && (
            <div className="overflow-hidden rounded-md">
              <Image
                aspectRatio="12x16"
                file={author.fields.photo.fields.asset}
              />
            </div>
          )}
        </div>
        <div className="sm:col-span-7">
          <Paragraph className="blockquote mb-8 sm:mb-6">
            <PlainRichText content={quote} />
          </Paragraph>
          {author.fields.name && (
            <Paragraph variant="base" className="mb-1 font-medium">
              {author.fields.name}
            </Paragraph>
          )}
          {author.fields.role && (
            <Paragraph variant="medium">{author.fields.role}</Paragraph>
          )}
        </div>
      </div>
    </div>
  );
};
