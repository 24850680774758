/* Dependencies */
import { FunctionComponent, PropsWithChildren } from 'react';

/**
 * Ordered List
 * @param props - Required component props.
 * @returns
 */
export const OrderedList: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  return <ol className="w-full list-decimal pl-5">{children}</ol>;
};
