export const tileTheme = Object.freeze({
  Transparent: {
    false: 'border-red hover:bg-red hover:text-white',
    true: 'bg-red text-white',
  },
  Red: {
    false: 'border-white hover:bg-white hover:text-red',
    true: 'bg-white text-red',
  },
  Blue: {
    false: 'border-red hover:bg-red hover:border-red hover:text-white',
    true: 'bg-red border-red text-white',
  },
  Plum: {
    false: 'border-white hover:bg-white hover:text-plum',
    true: 'bg-white text-plum',
  },
});
