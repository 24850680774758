/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { Image } from '../../Molecules/Image/Image';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { ContainerArrow } from '../../Molecules/ContainerArrow/ContainerArrow';
import { Heading } from '../../Atoms/Heading/Heading';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';
import { CTATandem } from '../../Molecules/CTATandem/CTATandem';

// Models
import { TypeCon08 } from '../../../models/contentful';
import { ContainerArrowProps } from '../../Molecules/ContainerArrow/ContainerArrow.model';

/**
 * CONTENT SPLIT - CON08
 * @param TypeCon08
 * @returns
 */
export const Con08: FunctionComponent<TypeCon08> = ({
  fields: { title, bodyCopy, image, listItems, callsToAction, Reverse, hideArrow, theme },
}) => {
  // Set the background color of the component
  let bgColor: ContainerArrowProps['bgColour'] = 'transparent';
  if (theme) {
    bgColor =
      theme.fields.colourScheme.toLowerCase() as ContainerArrowProps['bgColour'];
  }

  return (
    <div className="overflow-hidden lg:flex lg:justify-between">
      <div
        className={`${
          !Reverse && 'order-last'
        } lg:w-1/2 lg:flex-shrink-0 lg:flex-grow-0`}
      >
      {!hideArrow ? <ContainerArrow
        arrowsSide={Reverse ? 'Right' : 'Left'}
        bgColour={bgColor}
        className={`relative ${
          Reverse ? 'mr-5' : 'ml-5'
          } flex min-h-[380px] items-center pl-10 lg:mx-0 lg:min-h-[610px] ${
          Reverse ? 'lg:pr-[30%] lg:pl-40' : 'lg:pl-[30%] lg:pr-40'
        }`}
        animation={Reverse ? 'fade-right' : 'fade-left'}
        border={!!image}
      >
        {image && (
          <div className="absolute top-0 left-0 h-full w-full">
              <Image file={image.fields.asset} aspectRatio={'absolute'} />
          </div>
        )}
        {!image && (
          <Heading
            level="h2"
            style={title.fields.style}
            font={title.fields.fontFamily}
            className="relative"
          >
            <PlainRichText content={title.fields.content} />
          </Heading>
        )}
      </ContainerArrow>
        : <div 
          className={`relative flex min-h-[380px] 
          items-center lg:min-h-[610px]`}
        >
          {image && (
            <div className="absolute top-0 left-0 h-full w-full">
              <Image className='lg:p-12' file={image.fields.asset} aspectRatio={'absolute'} objectFit='contain'/>
      
            </div>
          )}
          {!image && (
            <Heading
              level="h2"
              style={title.fields.style}
              font={title.fields.fontFamily}
              className="relative"
            >
              <PlainRichText content={title.fields.content} />
            </Heading>
          )}
        </div>
      }
    </div>
    <div
      data-aos="fade-up"
        className={`px-10 pt-10 lg:w-1/2 lg:flex-shrink-0 lg:flex-grow-0 lg:${
          Reverse ? 'pr' : 'pl'
        }-40 lg:${Reverse ? 'pl' : 'pr'}-[10%]`}
    >
      {image && (
        <Heading
          level="h2"
          style={title.fields.style}
          font={title.fields.fontFamily}
          className="mb-9 lg:mb-12"
        >
          <PlainRichText content={title.fields.content} />
        </Heading>
      )}
      {bodyCopy && (
        <Paragraph variant="base" className="mb-8 text-primary">
          <PlainRichText content={bodyCopy.fields.content} />
        </Paragraph>
      )}
  
      {listItems && (
        <ul
          className={`list-unordered list-blue grid w-full list-none grid-cols-2 text-small leading-6 lg:text-2xl`}
        >
          {listItems.map(({ fields: { content } }, index) => {
            return (
              <li key={`listItem_${index}`}>
                <PlainRichText content={content} />
              </li>
            );
          })}
        </ul>
      )}
  
      {callsToAction && (
        <CTATandem
          callsToAction={callsToAction}
          className="mt-9 flex flex-wrap items-center justify-start"
        />
      )}
    </div>
    </div>
  );
};
