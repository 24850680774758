/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { ComponentWrapper } from '../../Molecules/ComponentWrapper/ComponentWrapper';
import { Container } from '../../Molecules/Container/Container';
import { Heading } from '../../Atoms/Heading/Heading';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { LinkButton } from '../../Atoms/LinkButton/LinkButton';

// Models
import { TypeCon31 } from '../../../models/contentful';
import { getLinkUrl } from '../../../helpers/getLinkUrl/getLinkUrl';

/**
 * POST CONTENT FIGURES - INEV11
 * @param TypeCon31
 * @returns
 */
export const Con31: FunctionComponent<TypeCon31> = ({
  fields: { columnContent, callToAction },
}) => {
  return (
    <ComponentWrapper
      backgroundColor={`Transparent`}
      className={`my-20 overflow-hidden lg:my-36`}
    >
      <Container className="relative grid w-full gap-y-14 sm:max-w-1130 md:grid-cols-2 md:gap-x-10 md:gap-y-36">
        {columnContent.map(({ fields: { title, bodyCopy } }, index) => {
          return (
            <div key={`column_${index}`}>
              {title && (
                <Heading
                  level="h3"
                  font={`${title?.fields.fontFamily}`}
                  style={`${title?.fields.style}`}
                  className={`mb-10 text-primary lg:mb-8 ${
                    title?.fields.style === 'Heading 2' ? 'lg:text-[50px]' : ''
                  }`}
                >
                  <PlainRichText content={title?.fields.content} />
                </Heading>
              )}
              {bodyCopy && (
                <Paragraph variant="base" className="text-primary">
                  <PlainRichText content={bodyCopy?.fields.content} />
                </Paragraph>
              )}
            </div>
          );
        })}
        {callToAction && (
          <div className="md:col-span-2 md:mx-auto">
            <LinkButton
              to={getLinkUrl(callToAction)}
              variant={callToAction.fields.theme?.fields.colourScheme}
              openInNewTab={callToAction.fields.openInNewTab}
              hasIcon={
                callToAction.fields.assetLink &&
                callToAction.fields.assetLink.fields
                  ? true
                  : false
              }
            >
              {callToAction.fields.title}
              {callToAction.fields.assetLink &&
                callToAction.fields.assetLink.fields && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="ml-1 h-3 w-3 lg:h-4 lg:w-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                    />
                  </svg>
                )}
            </LinkButton>
          </div>
        )}
      </Container>
    </ComponentWrapper>
  );
};
