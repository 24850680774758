/* Dependencies */
import { TypeSeoDetailsFields } from '@models/contentful';
import * as Contentful from 'contentful';

/**
 * Generates an array of Open Graph image objects from the given SEO details.
 *
 * @param {Contentful.Entry<TypeSeoDetailsFields> | undefined} seo - The SEO details entry from Contentful.
 * @returns {Array<{ url: string; width: number; height: number; alt: string; type: string }> | undefined} - An array of Open Graph image objects or undefined if no image is present.
 */
export const generateOgImages = (
  seo: Contentful.Entry<TypeSeoDetailsFields> | undefined
) => {
  if (seo && seo.fields && seo.fields.image) {
    return [
      {
        url: seo.fields.image?.fields.file.url,
        width: seo.fields.image?.fields.file.details.image
          ? seo.fields.image?.fields.file.details.image.width
          : 0,
        height: seo.fields.image?.fields.file.details.image
          ? seo.fields.image?.fields.file.details.image.height
          : 0,
        alt: seo.fields.image?.fields.title,
        type: seo.fields.image?.fields.file.contentType,
      },
    ];
  }
  return undefined;
};
