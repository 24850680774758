import { PropsWithChildren } from 'react';
import { TypeThemeFields } from '../../../models/contentful';

export interface TagProps extends PropsWithChildren {
  /**
   * Class Name
   */
  className?: string;
  /**
   * Background Colour
   */
  backgroundColor?: TypeThemeFields['colourScheme'];
}

export const theme = Object.freeze({
  Transparent: 'bg-fill',
  Red: 'theme-red bg-fill',
  Blue: 'theme-blue bg-fill',
  Plum: 'theme-plum bg-fill',
});
