import React, { useState, useEffect } from 'react';

import { Link } from '@atoms/Link/Link';
import { getLinkUrl } from '@helpers/getLinkUrl/getLinkUrl';
import { Image as CustomImage } from '@molecules/Image/Image';
import { If, Then, Else } from 'react-if';
import { ServiceItemFields } from './ServiceListItem.model';
import { Asset } from 'contentful';

export const ServiceListItem: React.FC<ServiceItemFields> = ({
  link,
  title,
  body,
  image,
  imageRollover = image,
  animated,
  serviceItemBoxDefaultColourWhite,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  // Preload rollover image silently
  useEffect(() => {
    if (imageRollover) {
      const img = new Image();
      img.src = imageRollover.fields.file.url;
      img.onload = () => {}; // Image preloaded silently
      img.onerror = () => {}; // Failed to preload image silently
    }
  }, [imageRollover]);

  const getBackgroundClass = () => {
    if (serviceItemBoxDefaultColourWhite) {
      return isHovered ? 'bg-red text-white' : 'bg-white text-primary';
    }
    return isHovered ? 'bg-fill text-primary' : 'bg-red text-white';
  };

  const getImage = () => {
    if (serviceItemBoxDefaultColourWhite) {
      return isHovered ? imageRollover : image;
    }
    return isHovered ? image : imageRollover;
  };

  return (
    <If condition={animated}>
      <Then>
        <li
          className={`relative m-0 w-full transform rounded-xl border-3 border-red px-12 pt-6 pb-9 text-center transition md:flex-[0_0_46%] md:px-11 md:pt-[60px] md:pb-[90px] lg:flex-[0_0_30%] ${getBackgroundClass()}`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {link && (
            <Link
              className="absolute top-0 left-0 z-10 h-full w-full"
              variant="none"
              to={getLinkUrl(link)}
              openInNewTab={link?.fields?.openInNewTab}
            >
              <span className="sr-only">view service: {title}</span>
            </Link>
          )}
          <div
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="300"
            data-aos-offset="75"
          >
            {image && (
              <CustomImage
                file={getImage() as Asset}
                aspectRatio="auto"
                priority={true}
                className={`transform transition-transform duration-300 ${
                  isHovered ? 'translate-y-2' : 'translate-y-0'
                } md:pb-8`}
              />
            )}
            <h3 className="my-9 font-display-a text-4xl sm:text-4xl md:text-4xl">
              {title}
            </h3>
            <p className="text-22px">{body}</p>
          </div>
        </li>
      </Then>
      <Else>
        <li
          className={`relative m-0 w-full transform rounded-md border border-red py-9 px-12 text-center transition hover:-translate-y-1 hover:scale-105 md:flex-[0_0_45%] md:py-[90px] md:px-11 lg:flex-[0_0_30%] ${getBackgroundClass()}`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {link && (
            <Link
              className="absolute top-0 left-0 z-10 h-full w-full"
              variant="none"
              to={getLinkUrl(link)}
              openInNewTab={link?.fields?.openInNewTab}
            >
              <span className="sr-only">
                view service:
                {title}
              </span>
            </Link>
          )}
          {image && (
            <CustomImage
              file={getImage() as Asset}
              aspectRatio="auto"
              priority={true}
            />
          )}
          <h3 className="my-9 font-display-a text-base md:my-3">{title}</h3>
          <p className="text-[15px]">{body}</p>
        </li>
      </Else>
    </If>
  );
};
