export const illustrationTypes = Object.freeze({
  BuildPlatforms: {
    url: '/assets/svg/CON07-buildplatforms.svg',
    width: '663',
    height: '675',
  },
  Partnerships: {
    url: '/assets/svg/CON07-partnerships.svg',
    width: '600',
    height: '669',
  },
});

export const positions = Object.freeze({
  BuildPlatforms: [
    'left-[calc(50%-50px)] top-[25%] md:left-[calc(50%-50px)] lg:top-[10%] lg:left-[calc(50%-260px)] xl:left-[calc(50%-320px)] max-w-290 xl:max-w-335',
    'left-[calc(50%-110px)] top-[47%] md:left-[calc(50%-170px)] lg:top-[40%] lg:left-[calc(50%-330px)] xl:left-[calc(50%-400px)] max-w-290 xl:max-w-335',
    'left-[calc(50%+75px)] top-[65%] md:left-[calc(50%+125px)] lg:top-[55%] lg:left-[calc(50%+70px)] xl:left-[calc(50%+90px)] max-w-290 xl:max-w-335',
  ],
  Partnerships: [
    'left-[calc(50%-140px)] top-[17.5%] md:left-[calc(50%-200px)] lg:top-[35%] lg:left-[calc(50%-340px)] xl:left-[calc(50%-450px)] max-w-290',
    'left-[calc(50%+85px)] top-[37.5%] md:left-[calc(50%+145px)] lg:top-[5%] lg:left-[calc(50%+60px)] xl:left-[calc(50%+125px)] max-w-290 xl:max-w-335',
    'left-[calc(50%+85px)] top-[72.5%] md:left-[calc(50%+145px)] lg:top-[67.5%] lg:left-[calc(50%+60px)] xl:left-[calc(50%+125px)] max-w-290 xl:max-w-335',
  ],
});

export const sides = Object.freeze({
  BuildPlatforms: ['Left', 'Left', 'Right'],
  Partnerships: ['Left', 'Right', 'Right'],
});
