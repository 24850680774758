/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { ComponentWrapper } from '../../Molecules/ComponentWrapper/ComponentWrapper';
import { Container } from '../../Molecules/Container/Container';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { Heading } from '../../Atoms/Heading/Heading';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';
import { CTATandem } from '../../Molecules/CTATandem/CTATandem';

// Models
import { TypeHb01 } from '../../../models/contentful';
import { sizes } from './Hb01.model';

/**
 * HERO BANNER TITLE - HB01
 * @param TypeHb01
 * @returns
 */
export const Hb01: FunctionComponent<TypeHb01> = ({
  fields: { title, bodyCopy, callsToAction, theme, variant = 'Medium' },
}) => {
  return (
    <ComponentWrapper
      backgroundColor={`${theme?.fields.colourScheme}`}
      className={`flex items-center py-16 ${sizes[variant]}`}
    >
      <Container
        className={`${variant === 'Large' ? 'sm:max-w-800' : 'sm:max-w-1308'}`}
      >
        {title && (
          <div data-aos="fade-up">
            <Heading
              level="h1"
              font={`${title?.fields.fontFamily}`}
              style={`${title?.fields.style}`}
              className={`${bodyCopy ? 'mb-6 lg:mb-8' : ''} ${
                callsToAction ? 'mb-8 lg:mb-16' : ''
              } break-words text-center text-primary lg:break-normal`}
            >
              <PlainRichText content={title?.fields.content} />
            </Heading>
          </div>
        )}
        {bodyCopy && (
          <div data-aos="fade-up">
            <Paragraph
              variant="base"
              className="mb-6 text-center text-primary lg:mb-8"
            >
              <PlainRichText content={bodyCopy?.fields.content} />
            </Paragraph>
          </div>
        )}
        <CTATandem callsToAction={callsToAction} />
      </Container>
    </ComponentWrapper>
  );
};
