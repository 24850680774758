/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { Container } from '../../Molecules/Container/Container';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { Heading } from '../../Atoms/Heading/Heading';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';
import { CTATandem } from '../../Molecules/CTATandem/CTATandem';

// Models
import { TypeCon10 } from '../../../models/contentful';
import { Logo } from '../../Atoms/Logo/Logo';

/**
 * CONTENT SPLIT - CON10
 * @param TypeCon10
 * @returns
 */
export const Con10: FunctionComponent<TypeCon10> = ({
  fields: { title, subheading, bodyCopy, image, callsToAction },
}) => {
  return (
    <Container className="mx-auto max-w-1130 items-stretch justify-between space-y-24 py-10 text-plum lg:flex lg:space-y-0 lg:space-x-16 lg:space-x-reverse lg:py-16 odd:lg:pr-28">
      <div
        className="mx-auto w-full max-w-400 lg:flex lg:items-end"
        data-aos="fade-up"
        data-aos-delay="300"
      >
        {image?.fields.asset && (
          <Logo
            file={image.fields.asset}
            className="h-full max-h-[500px] w-full object-contain object-center"
          />
        )}
      </div>
      <div
        className="justify-between lg:order-first lg:flex lg:min-w-[270px] lg:basis-5/12 lg:flex-col"
        data-aos="fade-up"
      >
        <div>
          {subheading && (
            <Heading
              level="h2"
              style="Heading 6"
              className="mb-2.5 uppercase text-primary"
            >
              <PlainRichText content={subheading} />
            </Heading>
          )}
          {title && (
            <Heading
              level="h3"
              font={title.fields.fontFamily}
              style={title.fields.style}
              className="mb-9 lg:mb-6"
            >
              <PlainRichText content={title.fields.content} />
            </Heading>
          )}
        </div>
        <div>
          {bodyCopy && (
            <Paragraph>
              <PlainRichText content={bodyCopy.fields.content} />
            </Paragraph>
          )}
          {callsToAction && (
            <CTATandem callsToAction={callsToAction} className="mt-6" />
          )}
        </div>
      </div>
    </Container>
  );
};
