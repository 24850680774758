/* Dependencies */
import { FunctionComponent, useEffect, useRef, useState } from 'react';

// Components
import { Image } from '../../Molecules/Image/Image';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { ContainerArrow } from '../../Molecules/ContainerArrow/ContainerArrow';
import { Heading } from '../../Atoms/Heading/Heading';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';
import { CTATandem } from '../../Molecules/CTATandem/CTATandem';
import { ComponentWrapper } from '../../Molecules/ComponentWrapper/ComponentWrapper';
import { VideoEmbed } from '../../Atoms/VideoEmbed/VideoEmbed';
import { VideoThumbnail } from '../../Molecules/VideoThumbnail/VideoThumbnail';
import { Awards } from '../../Molecules/Awards/Awards';

// Models
import { TypeHb03 } from '../../../models/contentful';
import { ContainerArrowProps } from '../../Molecules/ContainerArrow/ContainerArrow.model';
import { animate, openAndPlayVideo, stopAndCloseVideo } from './Hb03.model';

/**
 * HERO BANNER VIDEO - HB03
 * @param TypeHb03
 * @returns
 */
export const Hb03: FunctionComponent<TypeHb03> = ({
  fields: {
    title,
    subheading,
    bodyCopy,
    theme,
    callsToAction,
    image,
    video,
    awards,
  },
}) => {
  const leftContainerRef = useRef<HTMLDivElement>(null);
  const leftContainerContentRef = useRef<HTMLDivElement>(null);
  const rightContainerRef = useRef<HTMLDivElement>(null);
  const initialContentRefSm = useRef<HTMLDivElement>(null);
  const initialContentRefLg = useRef<HTMLDivElement>(null);
  const videoContentRefSm = useRef<HTMLDivElement>(null);
  const videoContentRefLg = useRef<HTMLDivElement>(null);
  const [mountIframeLg, setMountIframeLg] = useState(false);
  const [mountIframeSm, setMountIframeSm] = useState(false);

  useEffect(() => {
    if (video && mountIframeLg) {
      openAndPlayVideo(
        initialContentRefLg.current,
        videoContentRefLg.current,
        leftContainerRef.current,
        rightContainerRef.current,
        mountIframeLg
      );
    }
  }, [mountIframeLg]);

  useEffect(() => {
    if (video && mountIframeSm) {
      openAndPlayVideo(
        initialContentRefSm.current,
        videoContentRefSm.current,
        leftContainerRef.current,
        rightContainerRef.current,
        mountIframeSm
      );
    }
  }, [mountIframeSm]);

  // Set the background color of the component
  let bgColor: ContainerArrowProps['bgColour'] = 'transparent';
  if (theme) {
    bgColor =
      theme.fields.colourScheme.toLowerCase() as ContainerArrowProps['bgColour'];
  }

  // Animate the component on mount.
  useEffect(() => {
    animate(
      leftContainerRef.current,
      leftContainerContentRef.current,
      rightContainerRef.current
    );
  }, []);

  let flattenArrow: boolean = false;
  if (awards || video) {
    flattenArrow = true;
  }

  return (
    <ComponentWrapper>
      <div
        className={`relative mx-auto overflow-hidden ${
          awards &&
          'before:absolute before:top-0 before:bottom-0 before:left-0 before:right-0 before:bg-plum lg:before:top-1/2'
        } ${!flattenArrow && 'pb-[45vh]'}`}
      >
        {/* Mobile Image */}
        <div className="relative h-[60vh] w-full lg:hidden">
          <div className="w-full" ref={initialContentRefSm}>
            <VideoThumbnail
              image={image.fields.asset}
              cutOffMobile={true}
              onClick={() => setMountIframeSm(true)}
            />
          </div>
          {video && mountIframeSm && (
            <VideoEmbed
              video={video}
              autoplay={true}
              controls={true}
              loop={false}
              mute={false}
              closeButtonClick={() => {
                stopAndCloseVideo(
                  initialContentRefSm.current,
                  videoContentRefSm.current,
                  leftContainerRef.current,
                  rightContainerRef.current
                );
                setTimeout(() => {
                  setMountIframeSm(false);
                }, 2500);
              }}
              ref={videoContentRefSm}
            />
          )}
        </div>
        {/* / Mobile Image */}

        <div
          className={`flex w-full ${
            flattenArrow
              ? '-mt-[20vh]'
              : 'absolute -translate-y-1/2 transform lg:transform-none'
          } flex-row lg:static lg:mt-0 lg:py-16`}
        >
          {/* Image Arrow */}
          <ContainerArrow
            arrowsSide="Left"
            bgColour={'transparent'}
            classNameWrapper="hidden lg:block w-[60%] absolute top-0 right-0 h-full translate-x-[20%] transform"
            ref={rightContainerRef}
          >
            <div ref={initialContentRefLg}>
              <div className="w-full lg:block">
                <VideoThumbnail
                  image={image.fields.asset}
                  onClick={() => setMountIframeLg(true)}
                />
              </div>
              <div className="w-full lg:hidden">
                <Image file={image.fields.asset} aspectRatio={'1x1'} />
              </div>
            </div>
            {video && mountIframeLg && (
              <VideoEmbed
                video={video}
                autoplay={true}
                controls={true}
                loop={false}
                mute={false}
                closeButtonClick={() => {
                  stopAndCloseVideo(
                    initialContentRefLg.current,
                    videoContentRefLg.current,
                    leftContainerRef.current,
                    rightContainerRef.current
                  );
                  setTimeout(() => {
                    setMountIframeLg(false);
                  }, 2500);
                }}
                ref={videoContentRefLg}
              />
            )}
          </ContainerArrow>
          {/* / Image Arrow */}

          {/* Content Arrow */}
          <div
            className="z-2 relative w-full -translate-x-[20%] transform lg:w-3/5"
            ref={leftContainerRef}
          >
            <ContainerArrow
              arrowsSide="Right"
              arrowsSize={flattenArrow ? 'None' : ''}
              bgColour={bgColor}
              className="flex flex-row items-center justify-start lg:min-h-[70vh]"
            >
              <div
                className="w-full max-w-[90%] py-16 px-8 lg:py-32 lg:px-28 xl:max-w-[80%]"
                ref={leftContainerContentRef}
              >
                {subheading && (
                  <div className="content-item mb-4">
                    <p className="text-base">
                      <PlainRichText content={subheading} />
                    </p>
                  </div>
                )}

                <div data-aos="fade-up">
                  <Heading
                    level="h1"
                    style={title.fields.style}
                    font={title.fields.fontFamily}
                    className="content-item whitespace-pre opacity-0"
                  >
                    <PlainRichText content={title.fields.content} />
                  </Heading>
                </div>

                {bodyCopy && (
                  <div data-aos="fade-up">
                    <Paragraph
                      className="content-item mt-9 opacity-0"
                      variant="base"
                    >
                      <PlainRichText content={bodyCopy.fields.content} />
                    </Paragraph>
                  </div>
                )}

                {callsToAction && (
                  <div className="content-item mt-9 flex w-full flex-row opacity-0">
                    <CTATandem
                      callsToAction={callsToAction}
                      className="itmes-center flex flex-wrap justify-start"
                    />
                  </div>
                )}
              </div>
            </ContainerArrow>
          </div>
          {/* Content Arrow */}
        </div>
      </div>
      {awards && <Awards awards={awards} />}
    </ComponentWrapper>
  );
};
