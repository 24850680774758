/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { Container } from '../../Molecules/Container/Container';
import { Image } from '../../Molecules/Image/Image';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { Heading } from '../../Atoms/Heading/Heading';

// Models
import { TypeCon22 } from '../../../models/contentful';

/**
 * POST CONTENT FIGURES - INEV11
 * @param TypeCon22
 * @returns
 */
export const Con22: FunctionComponent<TypeCon22> = ({
  fields: { title, people },
}) => {
  return (
    <Container className="my-24 max-w-1130 text-plum">
      <div data-aos="fade-up">
        {title && (
          <Heading
            level="h2"
            style={title.fields.style}
            font={title.fields.fontFamily}
            className="mb-6 lg:mb-12"
          >
            <PlainRichText content={title.fields.content} />
          </Heading>
        )}
        {people && (
          <ul className="-m-2 flex flex-wrap justify-center font-display-a">
            {people.map(
              ({ fields: { photo, name, role, emailAddress } }, index) => {
                return (
                  <li className="m-0 w-full p-2 lg:w-1/3" key={index}>
                    <div className="h-full w-full rounded-md border border-red bg-fill py-8 px-12 text-center">
                      {photo && (
                        <div className="relative mx-auto h-32 w-32 overflow-hidden rounded-full">
                          <Image
                            file={photo.fields.asset}
                            aspectRatio="absolute"
                          />
                        </div>
                      )}
                      <h3 className="mt-4 mb-2 text-base">{name}</h3>
                      <p className="mb-2 text-15px">{role}</p>
                      {emailAddress && (
                        <p className="mb-2 text-15px">
                          <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
                        </p>
                      )}
                    </div>
                  </li>
                );
              }
            )}
          </ul>
        )}
      </div>
    </Container>
  );
};
