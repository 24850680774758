// Dependencies
import { FunctionComponent } from 'react';

// Components
import { Heading } from '../../Atoms/Heading/Heading';
import { Link } from '../../Atoms/Link/Link';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';
import { Tag } from '../../Atoms/Tag/Tag';
import { Image } from '../Image/Image';

// Models
import { InsightCardProps } from './InsightCard.model';

/**
 * PostCard Component
 * @params props - Required component props.
 */
export const InsightCard: FunctionComponent<InsightCardProps> = ({
  link,
  image,
  tags,
  title,
  publishedDate,
  author,
}) => {
  return (
    <div className="relative flex h-full flex-col rounded-md bg-white p-3 pb-4 shadow-light transition hover:shadow-lg lg:p-4 lg:pb-6 lg:hover:scale-105">
      {link && (
        <Link
          to={link}
          variant="none"
          title={title}
          className="absolute top-0 left-0 z-10 mb-2.5 h-full w-full overflow-hidden rounded-md lg:mb-4"
          openInNewTab={false}
        >
          <span className="sr-only">
            view service:
            {title}
          </span>
        </Link>
      )}

      {image && (
        <div className="w-full">
          <Image file={image} aspectRatio={'16x9'} />
        </div>
      )}

      <div className="flex w-full flex-grow flex-col">
        {tags && (
          <ul className="flex flex-wrap">
            {tags?.map((tag, index) => {
              return (
                <Tag
                  key={`${index}`}
                  backgroundColor={tag?.theme.fields.colourScheme}
                >
                  {tag?.title}
                </Tag>
              );
            })}
          </ul>
        )}

        {title && (
          <Heading
            level="h4"
            className={`mt-4 ${publishedDate || author ? "mb-8 lg:mb-9" : "mb-2" } text-2xl font-normal leading-5 text-plum sm:font-display-s lg:text-24px`}
          >
            {title}
          </Heading>
        )}

        <div className="mt-auto">
          {publishedDate && (
            <Paragraph className="font-display-a text-small font-medium text-plum lg:text-base">
              <time dateTime={`${publishedDate}`}>{publishedDate}</time>
            </Paragraph>
          )}

          {author && (
            <Paragraph className="text-small text-plum lg:text-base">
              {author}
            </Paragraph>
          )}
        </div>
      </div>
    </div>
  );
};
