/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { ComponentWrapper } from '../../Molecules/ComponentWrapper/ComponentWrapper';
import { Container } from '../../Molecules/Container/Container';
import { Heading } from '../../Atoms/Heading/Heading';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';
import { Hotspot } from '../../Molecules/Hotspot/Hotspot';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';

// Models
import { TypeCon17 } from '../../../models/contentful';
import { illustrationTypes, positions, sides } from './Con17.model';

/**
 * CONTENT GROWTH HOTSPOTS - CON17
 * @param TypeCon17
 * @returns
 */
export const Con17: FunctionComponent<TypeCon17> = ({
  fields: { subheading, title, bodyCopy, theme, hotspots, illustrationType },
}) => {
  return (
    <ComponentWrapper
      backgroundColor={`${theme?.fields.colourScheme}`}
      className={`flex items-center overflow-hidden py-16 lg:py-28`}
    >
      <Container className="relative w-full sm:max-w-1270">
        <div
          data-aos="fade-up"
          className={`z-10 w-full max-w-400 md:absolute md:w-2/5`}
        >
          {subheading && (
            <Heading
              level="h2"
              style="Heading 6"
              className="mb-2.5 uppercase text-primary"
            >
              <PlainRichText content={subheading} />
            </Heading>
          )}
          {title && (
            <Heading
              level="h3"
              font={`${title?.fields.fontFamily}`}
              style={`${title?.fields.style}`}
              className="mb-6 text-primary lg:mb-8"
            >
              <PlainRichText content={title?.fields.content} />
            </Heading>
          )}
          {bodyCopy && (
            <Paragraph className="text-small text-primary lg:max-w-335 lg:text-base">
              <PlainRichText content={bodyCopy?.fields.content} />
            </Paragraph>
          )}
        </div>
        <div className={`relative w-full pt-8 md:pt-20 md:pl-8`}>
          <div
            data-aos="fade-up"
            className="mx-auto flex h-full max-h-[400px] w-full md:max-h-775 md:max-w-none"
          >
            <img
              src={illustrationTypes[illustrationType]['url']}
              alt="Central Main"
              width={illustrationTypes[illustrationType]['width']}
              height={illustrationTypes[illustrationType]['height']}
              className="mx-auto"
            />
          </div>
          {hotspots?.map(({ fields }, index) => (
            <Hotspot
              key={`hotspot_${index}`}
              title={`${fields.title}`}
              subheading={`${fields.subheading}`}
              className={`absolute ${positions[illustrationType][index]}`}
              side={`${sides[illustrationType][index]}`}
              index={index}
              theme={
                theme?.fields.colourScheme === 'Blue' ? 'Tertiary' : 'Primary'
              }
            />
          ))}
        </div>
      </Container>
    </ComponentWrapper>
  );
};
