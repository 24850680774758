export const illustrationTypes = Object.freeze({
  Sustainability: {
    url: '/assets/svg/CON17-sustainability.svg',
    width: '292',
    height: '772',
  },
  EmployeePerks: {
    url: '/assets/svg/CON17-employeeperks.svg',
    width: '490',
    height: '685',
  },
});

export const positions = Object.freeze({
  Sustainability: [
    'top-[5%] left-[calc(50%-5px)] md:top-[10%] md:left-[calc(50%+10px)] lg:top-[3%] max-w-500',
    'top-[25%] left-[calc(50%+10px)] md:top-[30%] lg:top-[25%] lg:left-[calc(50%+25px)] max-w-500',
    'top-[60%] right-[calc(50%+30px)] md:top-[62.5%] lg:top-[57%] max-w-500',
    'top-[75%] left-[calc(50%+35px)] md:top-[75%] lg:top-[70%] lg:left-[calc(50%+60px)] max-w-500',
    'top-[92.5%] right-[calc(50%+60px)] md:top-[92.5%] lg:top-[87.5%] max-w-500',
  ],
  EmployeePerks: [
    'top-[20%] left-[calc(50%-60px)] md:left-[calc(50%-65px)] lg:top-[10%] max-w-500',
    'top-[32%] left-[calc(50%+50px)] md:left-[calc(50%+110px)] lg:top-[25%] lg:left-[calc(50%+60px)] xl:left-[calc(50%+80px)] max-w-450 xl:max-w-500',
    'top-[47%] left-[calc(50%+125px)] md:left-[calc(50%+160px)] lg:top-[42%] lg:left-[calc(50%+110px)] xl:left-[calc(50%+150px)] 2xl:left-[calc(50%+200px)] max-w-400 xl:max-w-500',
    'top-[55%] right-[calc(50%+60px)] md:right-[calc(50%+100px)] lg:top-[55%] lg:right-[calc(50%+50px)] xl:right-[calc(50%+65px)] max-w-450 xl:max-w-500',
    'top-[65%] left-[calc(50%+60px)] md:left-[calc(50%+130px)] lg:left-[calc(50%+90px)] xl:left-[calc(50%+130px)] 2xl:left-[calc(50%+175px)] max-w-400 xl:max-w-500',
    'top-[85%] right-[calc(50%-5px)] md:right-[calc(50%-30px)] lg:top-[82%] max-w-500',
    'top-[90%] left-[calc(50%+50px)] md:left-[calc(50%+100px)] lg:top-[85%] lg:left-[calc(50%+60px)] xl:left-[calc(50%+110px)] max-w-450 xl:max-w-500',
  ],
});

export const sides = Object.freeze({
  Sustainability: ['Right', 'Right', 'Left', 'Right', 'Left'],
  EmployeePerks: ['Right', 'Right', 'Right', 'Left', 'Right', 'Left', 'Right'],
});
