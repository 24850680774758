/* Dependencies */
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

// Models
import { RecaptchaProps } from './ReCaptcha.model';

/**
 * ReCaptcha Component
 * @param props - Required component props.
 */
export const ReCaptcha: React.FC<RecaptchaProps> = ({
  onChange,
  onError,
  onExpire,
  error,
}) => {
  // Render the component.
  return (
    <div className="relative flex flex-wrap items-start">
      <ReCAPTCHA
        sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY as string}
        onChange={(value) => {
          onChange(value);
        }}
        onErrored={() => {
          onError();
          onChange(null);
        }}
        onExpired={() => {
          onExpire();
        }}
        onReset={() => {
          onError();
        }}
      />
      {error && (
        <div className="mt-2 flex w-full flex-row flex-wrap items-start">
          <small className={`text-small ${error && 'text-primary'}`}>
            {error}
          </small>
        </div>
      )}
    </div>
  );
};
