/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { Container } from '../../Molecules/Container/Container';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { Heading } from '../../Atoms/Heading/Heading';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';
import { ContainerArrow } from '../../Molecules/ContainerArrow/ContainerArrow';

// Models
import { TypeCon16 } from '../../../models/contentful';
import { sectionBgColours } from './Con16.model';

/**
 * CONTENT STRATEGY HORIZONTAL SCROLL - CON16
 * @param TypeCon16
 * @returns
 */
export const Con16: FunctionComponent<TypeCon16> = ({
  fields: { title, bodyCopy, sections },
}) => {
  return (
    <div data-aos="fade-up" className="overflow-hidden pb-5 lg:pb-10">
      <Container className="scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-[#ff0044] relative w-screen overflow-x-scroll pt-24 pl-10 lg:pt-20 lg:pb-10">
        <div className="relative z-10 mb-80 max-w-290 pr-11 text-plum lg:mb-96 lg:max-w-400 lg:pr-12">
          <Heading
            level="h2"
            font={title.fields.fontFamily}
            style={title.fields.style}
            className="mb-6 lg:mb-8"
          >
            <PlainRichText content={title.fields.content} />
          </Heading>

          <Paragraph className="text-small lg:text-base">
            <PlainRichText content={bodyCopy?.fields.content} />
          </Paragraph>
        </div>

        <div className="absolute top-0 left-0 flex h-full flex-nowrap px-10 pb-24 lg:pb-20">
          {sections?.map(({ fields }, index) => {
            return (
              <div className="group flex flex-nowrap" key={`section_${index}`}>
                <ContainerArrow
                  className="flex flex-col justify-center pl-4 pr-12 lg:pl-7 lg:pr-24"
                  classNameWrapper={`h-48 w-56 lg:h-64 lg:w-80 self-end mr-4 group-last-of-type:mr-0 ${index % 2 === 1 && 'mb-52 lg:mb-80'
                    } text-xs lg:text-2xl lg:leading-5`}
                  arrowsSide="Right"
                  overlap={false}
                  bgColour={sectionBgColours[index % sectionBgColours.length]}
                >
                  <span data-aos="fade-up">{fields.label}</span>
                </ContainerArrow>
                {index < sections.length - 1 && (
                  <span
                    aria-hidden="true"
                    className={`relative -mr-20 mb-24 h-24 w-36 self-end lg:-mr-28 lg:mb-32 lg:h-44 lg:w-60 ${index % 2 === 0
                      ? "border-b-2 after:top-0 after:-translate-y-1/2 after:content-['▲']"
                      : "bottom-28 border-t-2 after:bottom-0 after:translate-y-1/2 after:content-['▼'] lg:bottom-36"
                      } border-r-2 border-dotted border-plum after:absolute after:left-full after:-translate-x-1/2 after:pl-0.5 after:text-small after:text-plum`}
                  ></span>
                )}
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
};
