/* Dependencies */
import React, { useEffect, useState } from 'react';

// Helpers
import { uniqueId } from '../../../../helpers/uniqueId/uniqueId';
import { getDescribedBy } from '../../../../helpers/getDescribedBy/getDescribedBy';

// Models
import { CheckboxProps } from './Checkbox.model';

/**
 * Input Component
 */
export const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  disabled,
  error,
  label,
  name,
  onChange,
  onBlur,
  readOnly,
  required,
  supportText,
  value,
}) => {
  // Set The Initial State
  const [id] = useState(uniqueId());
  const [describedBy, setDescribedBy] = useState('');

  // Set the Tool tip on ID Change
  useEffect(() => {
    setDescribedBy(getDescribedBy({ error, id, name, supportText }));
  }, [id, error, name, supportText]);

  // Render the component.
  return (
    <div className="relative flex">
      <label htmlFor={id} className={`relative flex cursor-pointer`}>
        <input
          id={id}
          type="checkbox"
          name={name}
          onChange={onChange.bind(this)}
          onBlur={onBlur.bind(this)}
          aria-invalid={error ? true : false}
          aria-describedby={describedBy}
          required={required}
          readOnly={readOnly}
          disabled={disabled}
          value={value}
          defaultChecked={checked}
          className="peer invisible absolute opacity-0"
        />
        <span
          className={`relative pl-8 font-display-s text-2xl leading-5 ${
            error && 'text-red-600'
          } before:absolute before:left-0 before:h-4 before:w-4 before:rounded-sm before:border before:border-primary before:bg-center before:bg-no-repeat peer-checked:before:bg-white
          peer-checked:before:bg-tick-plum peer-checked:before:bg-[length:80%] ${
            error ? 'before:text-primary' : 'before:focus:ring-plum'
          }
          `}
        >
          {label}
        </span>
      </label>

      {supportText ? (
        <div className="mt-1 flex w-full flex-row flex-wrap items-start">
          {supportText && (
            <small className="text-sm" id={`${id}_${name}-help`}>
              {supportText}
            </small>
          )}
        </div>
      ) : null}
    </div>
  );
};
