/* Dependencies */
import { ChevronRightIcon } from '@heroicons/react/solid';
import { FunctionComponent } from 'react';

// Models
import { ActionButtonProps } from './ActionButton.model';
import { variants, borderVariants } from '../Button/Button.model';
import { iconVariants } from '../Link/Link.model';

/**
 * Button component
 * @param props - Component props.
 */
export const ActionButton: FunctionComponent<ActionButtonProps> = ({
  children,
  className,
  onClick,
  variant = 'primary',
  arrowDirection = 'Right',
  type = 'submit',
  hasIcon = false,
}) => {
  if (variant === 'Transparent') {
    return (
      <button
        onClick={() => onClick()}
        className={`${
          className || ''
        } font-display-a text-xs transition-colors lg:text-2xl ${
          variants[variant]
        } flex items-center`}
        type={type}
      >
        {children}
        <ChevronRightIcon
          className={`ml-1 h-5 w-5 ${iconVariants['base']}`}
          aria-hidden="true"
        />
      </button>
    );
  }

  return (
    <button
      onClick={() => onClick()}
      className={`${
        className || ''
      } group relative inline-flex min-w-max overflow-hidden font-display-a ${
        arrowDirection === 'Right' ? 'pr-[18px]' : ''
      } ${arrowDirection === 'Left' ? 'pl-[18px]' : ''}`}
      type={type}
    >
      {!hasIcon && (
        <span
          className={`w-full ${variants[variant]} border-y 
          ${arrowDirection === 'None' ? 'rounded border px-6 py-2.5' : 'py-2'} 
          ${arrowDirection === 'Right' ? 'rounded-l border-l pl-6 pr-4' : ''} 
          ${arrowDirection === 'Left' ? 'rounded-r border-r pr-6 pl-4' : ''} 
          ${
            borderVariants[variant]
          } relative inline-flex text-xs transition-colors lg:text-2xl lg:leading-4`}
        >
          {children}
        </span>
      )}
      {hasIcon && (
        <span
          className={`w-full ${variants[variant]} border-y 
          ${arrowDirection === 'None' ? 'rounded border p-2.5' : 'py-2'} 
          ${
            arrowDirection === 'Right' ? 'rounded-l border-l pl-2.5 pr-1.5' : ''
          } 
          ${
            arrowDirection === 'Left' ? 'rounded-r border-r pr-2.5 pl-1.5' : ''
          } 
          ${
            borderVariants[variant]
          } relative inline-flex items-center justify-between text-xs transition-colors lg:text-15px lg:leading-4`}
        >
          {children}
        </span>
      )}
      {arrowDirection !== 'None' && (
        <span
          className={`clip-button_${arrowDirection?.toLowerCase()} ${
            variants[variant]
          } rounded border ${borderVariants[variant]} transition-colors`}
        ></span>
      )}
    </button>
  );
};
