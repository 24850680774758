/* Dependencies */
import { FunctionComponent, useState } from 'react';

// Components
import { ComponentWrapper } from '../../Molecules/ComponentWrapper/ComponentWrapper';
import { Container } from '../../Molecules/Container/Container';
import { Image } from '../../Molecules/Image/Image';
import { Heading } from '../../Atoms/Heading/Heading';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';

// Models
import { TypeCon05, TypeCon05Tile } from '../../../models/contentful';
import { tileTheme } from './Con05.model';

/**
 * CONTENT BENEFITS GRID - CON05
 * @param TypeCon05
 * @returns
 */
export const Con05: FunctionComponent<TypeCon05> = ({
  fields: { title, theme, bodyCopy, tiles },
}) => {
  const [activeItem, setActiveItem] = useState<TypeCon05Tile>();

  if (!activeItem && tiles) {
    setActiveItem(tiles[0]);
  }

  return (
    <ComponentWrapper backgroundColor={`${theme?.fields.colourScheme}`}>
      <Container className="max-w-1130 py-16 coloured-panel:py-4 lg:py-24">
        {/* main text */}
        {title && (
          <Heading
            level="h2"
            font={`${title.fields.fontFamily}`}
            style={`${title.fields.style}`}
            className={`mb-6 text-center text-primary ${
              bodyCopy ? 'lg:mb-8' : 'lg:mb-16'
            }`}
          >
            <PlainRichText content={title.fields.content} />
          </Heading>
        )}
        {bodyCopy && (
          <Paragraph className="text-primary">
            <PlainRichText content={bodyCopy.fields.content} />
          </Paragraph>
        )}
        {/* ./ main text */}

        <div className="mt-8 md:flex md:justify-between md:gap-24">
          {/* left 40 - preview text */}
          <div className="md:w-4/12">
            {activeItem && (
              <Paragraph className="text-primary">
                <PlainRichText content={activeItem.fields.contentToRender} />
              </Paragraph>
            )}
          </div>
          {/* ./left */}
          {/* right 60 - tile list */}
          <div className="mt-7 grid grid-cols-2 gap-3 md:mt-0 md:w-4/5 md:gap-7">
            {tiles &&
              tiles.map((tile, index) => {
                const { image, title, hoverImage } = tile.fields;

                return (
                  <button
                    key={`tile_${index}`}
                    className={`group flex min-h-[75px] w-full flex-col rounded-md border border-red p-3 text-left text-primary transition md:py-8 md:px-6 ${
                      tileTheme[theme?.fields.colourScheme][
                        `${activeItem === tile}`
                      ]
                    }`}
                    onClick={() => {
                      setActiveItem(tile);
                    }}
                  >
                    {/* icon */}
                    <div
                      className={`h-5 w-5 md:h-10 md:w-10 ${
                        hoverImage && activeItem !== tile
                          ? 'group-hover:hidden'
                          : ''
                      }
                      ${hoverImage && activeItem === tile ? 'hidden' : ''}`}
                    >
                      <Image file={image.fields.asset} aspectRatio="fluid" />
                    </div>
                    {/* hover icon */}
                    {hoverImage && (
                      <div
                        className={`h-6 w-6 md:h-10 md:w-10 ${
                          activeItem !== tile ? 'hidden group-hover:block' : ''
                        }`}
                      >
                        <Image
                          file={hoverImage.fields.asset}
                          aspectRatio="fluid"
                        />
                      </div>
                    )}
                    <Paragraph className="mt-3 w-full text-xs md:mt-5 md:w-3/5 md:text-lg">
                      <PlainRichText content={title} />
                    </Paragraph>
                  </button>
                );
              })}
          </div>
          {/* ./ right */}
        </div>
      </Container>
    </ComponentWrapper>
  );
};
