/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { Container } from '../../Molecules/Container/Container';
import { Image } from '../../Molecules/Image/Image';

// Models
import { TypeInev12 } from '../../../models/contentful';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { Heading } from '../../Atoms/Heading/Heading';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';

/**
 * POST CONTENT FIGURES - INEV11
 * @param TypeInev12
 * @returns
 */
export const Inev12: FunctionComponent<TypeInev12> = ({
  fields: { title, people },
}) => {
  return (
    <Container className="my-16 text-plum sm:max-w-1130 lg:my-20">
      <div data-aos="fade-up">
        {title && (
          <Heading
            level="h2"
            font={`${title.fields.fontFamily}`}
            style={`${title.fields.style}`}
            className={`mb-10 text-center uppercase lg:mb-14`}
          >
            <PlainRichText content={title?.fields.content} />
          </Heading>
        )}
        {/* grid */}
        <ul className="flex flex-wrap justify-center gap-5">
          {people.map(
            ({ fields: { photo, name, role, companyName } }, index) => {
              return (
                <li
                  key={`${index}`}
                  className="m-0 flex flex-[0_1_300px] flex-col items-center rounded-md border border-red p-5 text-center lg:px-4 lg:pb-8"
                >
                  {photo && (
                    <div className="mb-5 h-24 w-24 overflow-hidden rounded-full lg:h-[120px] lg:w-[120px]">
                      <Image file={photo.fields.asset} aspectRatio="1x1" />
                    </div>
                  )}
                  <div className="flex grow flex-col justify-between">
                    {name && (
                      <Heading
                        level="h3"
                        style="Heading 5"
                        className="mb-3 font-display-a font-normal"
                      >
                        {name}
                      </Heading>
                    )}
                    <div>
                      {role && (
                        <Paragraph className="mb-2 text-2xl leading-5">
                          {role}
                        </Paragraph>
                      )}
                      {companyName && (
                        <Paragraph className="text-2xl">
                          {companyName}
                        </Paragraph>
                      )}
                    </div>
                  </div>
                </li>
              );
            }
          )}
        </ul>
      </div>
    </Container>
  );
};
