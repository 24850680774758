/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { ComponentWrapper } from '../../Molecules/ComponentWrapper/ComponentWrapper';
import { Container } from '../../Molecules/Container/Container';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { Heading } from '../../Atoms/Heading/Heading';
import { CTATandem } from '../../Molecules/CTATandem/CTATandem';
import { RichText } from '../../Molecules/RichText/RichText';

// Models
import { TypeInev16 } from '../../../models/contentful';

/**
 * POST CONTENT FIGURES - INEV11
 * @param TypeInev16
 * @returns
 */
export const Inev16: FunctionComponent<TypeInev16> = ({
  fields: { theme, title, columns, callToAction },
}) => {
  const gridColumns = ['md:grid-cols-1', 'md:grid-cols-2', 'md:grid-cols-3'];
  return (
    <ComponentWrapper
      backgroundColor={`${theme?.fields.colourScheme}`}
      className={`py-10 lg:py-24`}
    >
      <Container className="sm:max-w-1270">
        {title && (
          <div data-aos="fade-up">
            <Heading
              level="h2"
              font={`${title?.fields.fontFamily}`}
              style={`${title?.fields.style}`}
              className={`mb-14 text-center text-primary lg:mb-12`}
            >
              <PlainRichText content={title?.fields.content} />
            </Heading>
          </div>
        )}
        <div
          className={`grid gap-10 lg:gap-20 ${
            gridColumns[columns.length - 1]
          } justify-center ${callToAction ? 'mb-6' : ''}`}
        >
          {columns.map(({ fields: { title, bodyCopy } }, index) => (
            <div
              data-aos="fade-up"
              data-aos-delay={((index + 1) * 100).toString()}
              className={`text-primary`}
              key={`column_${index}`}
            >
              {title && (
                <div data-aos="fade-up">
                  <Heading
                    level="h3"
                    font={`${title?.fields.fontFamily}`}
                    style={`${title?.fields.style}`}
                    className={`${
                      bodyCopy ? 'mb-6 lg:mb-10' : ''
                    } text-primary`}
                  >
                    <PlainRichText content={title?.fields.content} />
                  </Heading>
                </div>
              )}
              {bodyCopy && (
                <div data-aos="fade-up">
                  <RichText content={bodyCopy?.fields.content} />
                </div>
              )}
            </div>
          ))}
        </div>
        <CTATandem callsToAction={callToAction} />
      </Container>
    </ComponentWrapper>
  );
};
