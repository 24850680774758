/* Dependencies */
import { Entry } from 'contentful';
import * as yup from 'yup';
import { TypeCallToActionFields } from '@models/contentful';

import { Question } from '../QuestionList/QuestionList.model';

// Generics
export interface EnquiryType {
  key: string;
  value: string;
}
export const enquiryTypes: EnquiryType[] = [
  {
    key: 'general',
    value: 'General',
  },
  {
    key: 'business',
    value: 'New Business',
  },
  {
    key: 'partnerships',
    value: 'Partnerships',
  },
  {
    key: 'other',
    value: 'Other',
  },
];

export interface ContactUsFormProps {
  /**
   * Contentful ID for the form
   */
  formid: string;
  /**
   * Identifier for the form.
   */
  source: string;
  /**
   * Possible question for the Form
   */
  questions: Question[];

  /**
   * Call To Action
   */
  callToAction?: Entry<TypeCallToActionFields>;
}

export interface ContactUsFormInitialValues {
  source: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  enquiryType: string;
  message: string;
  recaptcha: string;
  terms: boolean;
  formQuestion0?: string;
  formQuestion1?: string;
  formQuestion2?: string;
  [key: string]: string | boolean | undefined;
}

export const ContactUsFormSchema = (questions: Question[]) => {
  interface SchemaFields {
    source: yup.StringSchema;
    firstName: yup.StringSchema;
    lastName: yup.StringSchema;
    email: yup.StringSchema;
    phone: yup.StringSchema;
    enquiryType: yup.StringSchema;
    message: yup.StringSchema;
    recaptcha: yup.StringSchema;
    terms: yup.BooleanSchema;
    [key: string]: yup.StringSchema | yup.BooleanSchema;
  }

  let schemaFields: Partial<SchemaFields> = {
    source: yup.string().required('Field is required'),
    firstName: yup.string().required('Field is required'),
    lastName: yup.string().required('Field is required'),
    email: yup.string().email('Invalid email').required('Field is required'),
    phone: yup.string().optional(),
    enquiryType: yup.string().required('Field is required'),
    message: yup.string().required('Field is required'),
    recaptcha: yup.string().required('Field is required'),
    terms: yup
      .boolean()
      .isTrue('Field is required')
      .required('Field is required'),
  };

  questions.forEach(({ question, required }, index) => {
    if (question) {
      let validator = yup.string();

      validator = required
        ? validator.required('Field is required')
        : validator.optional();

      schemaFields[`formQuestion${index}`] = validator;
    }
  });

  return yup.object().shape(schemaFields as SchemaFields);
};
