/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { Image } from '../../Molecules/Image/Image';

// Models
import { VideoThumbnailProps } from './VideoThumbnail.model';

/**
 * VideoThumbnail - Thumbnail image, translucent overlay, and play button for videos.
 * @param props - Required component props.
 * @returns
 */
export const VideoThumbnail: FunctionComponent<VideoThumbnailProps> = ({
  image,
  cutOffMobile,
  onClick,
}) => {
  return (
    <>
      {image && <Image file={image} aspectRatio={'absolute'} />}
      <div className="absolute left-0 top-0 h-full w-full bg-video-thumbnail-overlay">
        {onClick && (
          <button
            className={`absolute left-1/2 ${
              cutOffMobile ? `top-[calc(50%-10vh)] lg:top-1/2` : 'top-1/2'
            } flex h-24 w-24 -translate-x-1/2 -translate-y-1/2 rotate-90 items-center justify-center rounded-full bg-white after:pb-1.5 after:text-[51px] after:text-red after:content-['▲'] lg:right-[40%]`}
            onClick={() => {
              onClick();
            }}
          ></button>
        )}
      </div>
    </>
  );
};
