// Dependencies
import { FunctionComponent } from 'react';

// Components
import { Heading } from '../../Atoms/Heading/Heading';
import { Link } from '../../Atoms/Link/Link';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { Tag } from '../../Atoms/Tag/Tag';
import { Image } from '../Image/Image';

// Models
import { EventCardProps } from './EventCard.model';

/**
 * PostCard Component
 * @params props - Required component props.
 */
export const EventCard: FunctionComponent<EventCardProps> = ({
  link,
  image,
  tags,
  title,
  location,
  eventDate,
}) => {
  return (
    <div className="relative flex h-full flex-col rounded-md bg-white p-3 pb-4 shadow-light transition hover:scale-105 hover:shadow-lg lg:p-4 lg:pb-6">
      {link && (
        <Link
          to={link}
          variant="none"
          className="absolute top-0 left-0 z-10 mb-2.5 h-full w-full overflow-hidden rounded-md lg:mb-4"
          openInNewTab={false}
        >
          <span className="sr-only">
            view event:
            {title && <PlainRichText content={title.content} />}
          </span>
        </Link>
      )}

      {image && (
        <div className="w-full overflow-hidden rounded-md">
          <Image file={image} aspectRatio={'16x9'} />
        </div>
      )}

      <div className="flex w-full flex-grow flex-col">
        {tags && (
          <ul className="flex flex-wrap">
            {tags
              ?.filter((tag) => tag)
              .map((tag, index) => {
                return (
                  <Tag
                    key={`${index}`}
                    backgroundColor={tag?.theme.fields.colourScheme}
                  >
                    {tag?.title}
                  </Tag>
                );
              })}
          </ul>
        )}

        {title && (
          <Heading
            level="h4"
            className="mt-4 mb-8 text-2xl font-normal leading-5 text-plum sm:font-display-s lg:mb-9 lg:text-24px"
          >
            <PlainRichText content={title.content} />
          </Heading>
        )}

        <div className="mt-auto">
          {eventDate && (
            <Paragraph className="font-display-a text-small font-medium text-plum lg:text-base">
              <time dateTime={`${eventDate}`}>{eventDate}</time>
            </Paragraph>
          )}

          {location && (
            <Paragraph className="text-small text-plum lg:text-base">
              {location}
            </Paragraph>
          )}
        </div>
      </div>
    </div>
  );
};
