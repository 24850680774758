/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { Statistics } from '../../Atoms/Statistics/Statistics';

// Models
import { TypeInev11 } from '../../../models/contentful';

/**
 * POST CONTENT FIGURES - INEV11
 * @param TypeInev11
 * @returns
 */
export const Inev11: FunctionComponent<TypeInev11> = ({ fields }) => {
  return <Statistics statistics={fields.statistics} />;
};
