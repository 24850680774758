import anime from 'animejs';
import {
  getAnimation,
  animations,
} from '../../../helpers/animateVideo/animateVideo';
import { getWindowWidth } from '../../../helpers/getWindowWidth/getWindowWidth';

export function animate(
  leftContainer: HTMLDivElement | null,
  leftContainerContent: HTMLDivElement | null,
  rightContainer: HTMLDivElement | null
): void {
  // Query the content elements.
  let containerElements: HTMLElement[] = [];
  if (leftContainerContent) {
    containerElements =
      Array.from(leftContainerContent.querySelectorAll('.content-item')) || [];
  }

  // Animate the content elements on a timeline.
  anime
    .timeline({ autoplay: true })
    .add(
      getAnimation(leftContainer, {
        translateX: ['-20%', 0],
        scale: [0.8, 1],
      })
    )
    .add(
      getAnimation(rightContainer, {
        translateX: ['20%', 0],
      }),
      '-=1000'
    )
    .add(
      getAnimation(containerElements, {
        translateY: ['20px', 0],
        opacity: [0, 1],
        easing: 'linear',
        delay: anime.stagger(50),
      })
    );
}

export function openAndPlayVideo(
  initialContent: HTMLDivElement | null,
  videoContent: HTMLDivElement | null,
  leftContainer: HTMLDivElement | null,
  rightContainer: HTMLDivElement | null,
  iframeReady: boolean
): void {
  const video = videoContent?.querySelector('iframe');
  if (videoContent && video && iframeReady) {
    anime
      .timeline({ autoplay: true })
      .add(
        getAnimation(initialContent, {
          ...animations.fadeOut,
          complete: () => {
            initialContent && (initialContent.style.zIndex = '-1');
          },
        })
      )
      .add(
        getAnimation(videoContent, {
          ...animations.fadeIn,
          begin: () => {
            videoContent.style.display = 'flex';
            video?.dataset.src && (video.src = video.dataset.src);
          },
        }),
        '-=1000'
      )
      .add(getAnimation(leftContainer, animations.slideTextArrowOut), '-=1000')
      .add(
        getAnimation(rightContainer, {
          ...animations.slideVideoIntoCentre,
          maxWidth: [`${getWindowWidth()}`, '1130px'],
        }),
        '-=500'
      )
      .add(
        getAnimation(
          rightContainer?.querySelector('.clip-container_left'),
          animations.flattenLeftArrow
        ),
        '-=1000'
      )
      .add(
        getAnimation(
          rightContainer?.querySelector('.clip-container_right'),
          animations.flattenRightArrow
        ),
        '-=1000'
      );
  }
}

export function stopAndCloseVideo(
  initialContent: HTMLDivElement | null,
  videoContent: HTMLDivElement | null,
  leftContainer: HTMLDivElement | null,
  rightContainer: HTMLDivElement | null
): void {
  const video = videoContent?.querySelector('iframe');
  anime
    .timeline({ autoplay: true })
    .add(
      getAnimation(
        rightContainer?.querySelector('.clip-container_left'),
        animations.flattenLeftArrow,
        true
      )
    )
    .add(
      getAnimation(
        rightContainer?.querySelector('.clip-container_right'),
        animations.flattenRightArrow,
        true
      ),
      '-=1000'
    )
    .add(
      getAnimation(
        rightContainer,
        {
          ...animations.slideVideoIntoCentre,
          maxWidth: [`${getWindowWidth()}`, '1130px'],
        },
        true
      ),
      '-=1000'
    )
    .add(
      getAnimation(videoContent, {
        ...animations.fadeOut,
        begin: () => {
          video?.dataset.src && (video.src = '');
        },
        complete: () => {
          videoContent && (videoContent.style.display = '');
        },
      }),
      '-=500'
    )
    .add(
      getAnimation(leftContainer, animations.slideTextArrowOut, true),
      '-=1000'
    )
    .add(
      getAnimation(initialContent, {
        ...animations.fadeIn,
        complete: () => {
          initialContent && (initialContent.style.zIndex = '');
        },
      }),
      '-=1000'
    );
}
