/* Dependencies */
import { forwardRef, useEffect } from 'react';

// importing aos
import AOS from 'aos';
import 'aos/dist/aos.css';

// Models
import { ContainerArrowProps, backgroundColor } from './ContainerArrow.model';

/**
 * ContainerArrow - Creates a section wrapper.
 * @param props - Required component props.
 * @returns
 */
export const ContainerArrow = forwardRef<HTMLDivElement, ContainerArrowProps>(
  (
    {
      children,
      classNameWrapper,
      className = '',
      arrowsSide = 'Right',
      arrowsSize = '',
      border = false,
      bgColour = 'transparent',
      overlap = false,
      animation = undefined,
    },
    ref
  ) => {
    useEffect(() => {
      if (animation) {
        AOS.init({
          throttleDelay: 5,
          offset: 50,
          duration: 750,
          delay: 150,
          once: true,
        });
      }
    }, []);
    return (
      <div
        className={`${classNameWrapper} ${
          overlap ? `absolute z-10 h-[calc(100%-100px)] ` : ''
        } ${backgroundColor[bgColour]}`}
        ref={ref}
        {...(animation ? { 'data-aos': animation } : {})}
      >
        {/* If image with border */}
        {border && (
          <div
            className={`${className} clip-container_${arrowsSide.toLowerCase()}_border${
              arrowsSize ? '_' + arrowsSize.toLocaleLowerCase() : ''
            } relative h-full w-full bg-fill`}
          >
            <div
              className={`clip-container_${arrowsSide.toLowerCase()}${
                arrowsSize ? '_' + arrowsSize.toLocaleLowerCase() : ''
              } absolute top-0 bottom-0 h-full ${
                arrowsSide == 'Right'
                  ? 'left-0 right-[18px]'
                  : 'left-[18px] right-0'
              } flex flex-col justify-center overflow-hidden p-8 text-primary`}
            >
              {children}
            </div>
          </div>
        )}

        {/* Content/Image without border */}
        {!border && (
          <div
            className={`${className} clip-container_${arrowsSide.toLowerCase()}${
              arrowsSize ? '_' + arrowsSize.toLocaleLowerCase() : ''
            } relative h-full overflow-hidden bg-fill text-primary`}
          >
            {children}
          </div>
        )}
      </div>
    );
  }
);

// Set Display Name
ContainerArrow.displayName = 'ContainerArrow';
