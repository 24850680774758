/* Dependencies */
import { FunctionComponent } from 'react';

// Models
import { UnorderedListProps } from './UnorderedList.model';

/**
 * Unordered List
 * @param props - Required component props.
 * @returns
 */
export const UnorderedList: FunctionComponent<UnorderedListProps> = ({
  children,
}) => {
  return (
    <ul className={`list-unordered w-full text-2xl leading-6`}>{children}</ul>
  );
};
