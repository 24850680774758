/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { Container } from '../../Molecules/Container/Container';
import { Image } from '../../Molecules/Image/Image';

// Models
import { TypeTestimonial } from '../../../models/contentful';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';

/**
 * POST CONTENT FIGURES - INEV11
 * @param TypeTestimonial
 * @returns
 */
export const Con27: FunctionComponent<TypeTestimonial> = ({ fields }) => {
  return (
    <div className="">
      <h1>CON27</h1>
      <Container>
        <PlainRichText content={fields.quote} />
        {fields.logo && <Image file={fields.logo} />}
        {fields.companyName}
        {fields.personName}
      </Container>
    </div>
  );
};
