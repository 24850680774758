/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { Container } from '../../Molecules/Container/Container';
import { Image } from '../../Molecules/Image/Image';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { Heading } from '../../Atoms/Heading/Heading';
import { ComponentWrapper } from '../../Molecules/ComponentWrapper/ComponentWrapper';
import { ContainerArrow } from '../../Molecules/ContainerArrow/ContainerArrow';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';
import { Tag } from '../../Atoms/Tag/Tag';
import { Link } from '../../Atoms/Link/Link';

// Helpers
import { getFormattedDate } from '../../../helpers/getFormattedDate/getFormattedDate';

// Models
import { TypeInev06 } from '../../../models/contentful';

/**
 * FEATURED POST - INEV06
 * @param TypeInev06
 * @returns
 */
export const Inev06: FunctionComponent<TypeInev06> = ({
  fields: { preTitle, title, article },
}) => {
  return (
    <ComponentWrapper backgroundColor="Transparent">
      <Container className="relative max-w-1130 py-16 lg:py-24">
        <div data-aos="fade-up">
          <Link
            to={`/insights/${article.fields.slug.fields.slug}`}
            variant="none"
            className="absolute top-0 left-0 z-10 mb-2.5 h-full w-full overflow-hidden rounded-md lg:mb-4"
            openInNewTab={false}
          >
            <span className="sr-only">view article</span>
          </Link>
          <Heading level="h2" style="Heading 6" className="uppercase">
            {preTitle}
          </Heading>
          <div className="flex flex-col rounded-md bg-white lg:flex-row">
            {/* text side */}
            <div className="order-last w-full py-6 px-4 lg:order-first lg:w-3/5 lg:pr-24 lg:pl-8">
              {/* tags */}
              <ul className="mb-6 flex">
                {article.fields.tags?.map(
                  ({ fields: { title, theme } }, index) => {
                    return (
                      <Tag
                        key={`${index}`}
                        backgroundColor={`${theme?.fields.colourScheme}`}
                        className={`m-0 mr-2 last:mr-0`}
                      >
                        {title}
                      </Tag>
                    );
                  }
                )}
              </ul>
              {/* title */}
              <Heading
                level="h2"
                font={`${title?.fields.fontFamily}`}
                style={`${title?.fields.style}`}
                className={`mb-6 font-display-s text-primary lg:mb-24`}
              >
                <PlainRichText content={title?.fields.content} />
              </Heading>
              <time
                className="font-display-a text-base text-primary"
                dateTime={`${String(article.fields.publishedDate)}`}
              >
                {getFormattedDate(String(article.fields.publishedDate))}
              </time>
              <Paragraph className="text-small text-primary">
                {article.fields.author.fields.name}
              </Paragraph>
            </div>
            {/* ./text side */}

            {/* image side */}
            <div className="w-full pl-8 lg:w-2/5 lg:pl-0">
              <ContainerArrow
                arrowsSide="Left"
                classNameWrapper="w-full h-full overflow-hidden rounded-md"
              >
                {article.fields.image && (
                  <>
                    <div className="hidden lg:block">
                      <Image
                        file={article.fields.image.fields.asset}
                        aspectRatio={'absolute'}
                      />
                    </div>
                    <div className="block lg:hidden">
                      <Image file={article.fields.image.fields.asset} />
                    </div>
                  </>
                )}
              </ContainerArrow>
            </div>
            {/* ./image side */}
          </div>
        </div>
      </Container>
    </ComponentWrapper>
  );
};
