/* Dependencies */
import { XIcon } from '@heroicons/react/solid';
import { forwardRef } from 'react';
import { getVideoUrl } from '../../../helpers/getVideoUrl/getVideoUrl';

// Models
import {
  VideoEmbedProps,
  embedUrlFormats,
  VideoTypes,
} from './VideoEmbed.model';

/**
 * Video Embed
 * @param props - Required component props.
 * @returns
 */
export const VideoEmbed = forwardRef<HTMLDivElement, VideoEmbedProps>(
  (
    {
      video,
      closeButtonClick,
      autoplay = true,
      controls = true,
      loop = false,
      mute = false,
    },
    ref
  ) => {
    let type: keyof VideoTypes = 'youTube';
    let videoId: string = video.fields.youtubeVideoId || '';

    if (video.fields.vimeoVideoId) {
      type = 'vimeo';
      videoId = video.fields.vimeoVideoId;
    }

    return (
      <div
        className="absolute top-0 left-0 hidden h-full w-full items-center justify-center bg-black opacity-0"
        ref={ref}
      >
        <iframe
          allowFullScreen
          data-src={embedUrlFormats[type](
            getVideoUrl(videoId),
            autoplay,
            controls,
            loop,
            mute
          )}
          className="aspect-[16/9] max-h-full w-full lg:h-full"
          allow="autoplay; fullscreen; picture-in-picture"
        ></iframe>

        {closeButtonClick && (
          <button
            className="absolute top-2 right-2 flex h-8 w-8 items-center justify-center"
            onClick={() => closeButtonClick()}
          >
            <XIcon className="z-20 h-6 w-6 text-white" aria-hidden="true" />
          </button>
        )}
      </div>
    );
  }
);

// Set Display Name
VideoEmbed.displayName = 'VideoEmbed';
