/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { ComponentWrapper } from '../../Molecules/ComponentWrapper/ComponentWrapper';
import { Container } from '../../Molecules/Container/Container';
import { Heading } from '../../Atoms/Heading/Heading';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { AccordionList } from '../../Molecules/AccordionList/AccordionList';
import { RenderForm } from '../Forms/RenderForm/RenderForm';

// Models
import { TypeCon20 } from '../../../models/contentful';

/**
 * POST CONTENT FIGURES - INEV11
 * @param TypeCon20
 * @returns
 */
export const Con20: FunctionComponent<TypeCon20> = ({
  fields: { form, title, locations, formTitle },
}) => {
  return (
    <ComponentWrapper
      backgroundColor="Transparent"
      className="my-16 text-primary lg:mb-24 lg:mt-20"
    >
      <Container className="max-w-1130">
        <div className="grid lg:grid-cols-2 lg:gap-x-14">
          {/* Right Side Col 1 Accordion */}
          <div className="grid">
            {title && (
              <Heading level="h2" style="Heading 3">
                <PlainRichText content={title.fields.content} />
              </Heading>
            )}
            <AccordionList entries={locations} />
          </div>
          {/* ./ Right Side Col 1 Accordion */}
          {/* Left Side Col 2 */}
          {form && (
            <div className="max-w-1/2 w-full">
              {formTitle && (
                <Heading level="h2" style="Heading 3" className='mb-6'>
                  <PlainRichText content={formTitle.fields.content} />
                </Heading>
              )}
              <RenderForm form={form} />
            </div>
          )}
          {/* ./Left Side Col 2 */}
        </div>
      </Container>
    </ComponentWrapper>
  );
};
