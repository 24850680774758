/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { Container } from '../../Molecules/Container/Container';
import { Image } from '../../Molecules/Image/Image';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { Heading } from '../../Atoms/Heading/Heading';
import { ComponentWrapper } from '../../Molecules/ComponentWrapper/ComponentWrapper';
import { ContainerArrow } from '../../Molecules/ContainerArrow/ContainerArrow';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';
import { Tag } from '../../Atoms/Tag/Tag';
import { Link } from '../../Atoms/Link/Link';

// Helpers
import {
  getFormattedDate,
  getFormattedTime,
} from '../../../helpers/getFormattedDate/getFormattedDate';

// Models
import { TypeCon02 } from '../../../models/contentful';

/**
 * FEATURED POST - INEV06
 * @param TypeCon02
 * @returns
 */
export const Con02: FunctionComponent<TypeCon02> = ({
  fields: { title, event, theme },
}) => {
  // Handle Circular Depenedency
  if (!event || !event.fields) {
    return (
      <div className="w-full bg-red p-2">
        <p className="text-center text-white">
          The Featured event is not avialable for display. Please check the
          Contentful and ensure the block is not linking to itself. (Circular
          Dependency)
        </p>
      </div>
    );
  }

  return (
    <ComponentWrapper backgroundColor={`${theme?.fields.colourScheme}`}>
      <Container className="max-w-1130 py-16 lg:py-24">
        <div className="relative flex flex-col rounded-md bg-white lg:flex-row">
          <Link
            to={`/events/${event.fields.slug?.fields.slug}`}
            variant="none"
            className="absolute top-0 left-0 z-10 mb-2.5 h-full w-full overflow-hidden rounded-md lg:mb-4"
            openInNewTab={false}
          >
            <span className="sr-only">view event</span>
          </Link>

          {/* text side */}
          <div className="order-last w-full py-6 px-4 lg:order-first lg:w-3/5 lg:pr-24 lg:pl-8">
            {/* tags */}
            <ul className="mb-6 flex">
              {event.fields.tags?.map(({ fields: { title, theme } }, index) => {
                return (
                  <Tag
                    key={`${index}`}
                    backgroundColor={`${theme?.fields.colourScheme}`}
                    className={`m-0 mr-2 last:mr-0`}
                  >
                    {title}
                  </Tag>
                );
              })}
            </ul>
            {/* title */}
            <Heading
              level="h2"
              font={`${title?.fields.fontFamily}`}
              style={`${title?.fields.style}`}
              className={`mb-6 font-display-s lg:mb-24`}
            >
              <PlainRichText content={title?.fields.content} />
            </Heading>
            {/* date */}
            <div className="flex flex-col space-y-2">
              {event.fields.eventDate && (
                <>
                  <time
                    className="font-display-a text-base"
                    dateTime={`${String(event.fields.eventDate)}`}
                  >
                    {getFormattedDate(String(event.fields.eventDate))}
                  </time>
                  <time
                    className="font-display-a text-base"
                    dateTime={`${String(event.fields.eventDate)}`}
                  >
                    {getFormattedTime(String(event.fields.eventDate))}
                  </time>
                </>
              )}
            </div>
            {/* location */}
            {event.fields.location && (
              <Paragraph className="text-small">
                {event.fields.location}
              </Paragraph>
            )}
          </div>
          {/* ./text side */}

          {/* image side */}
          <div className="w-full pl-8 lg:w-2/5 lg:pl-0">
            <ContainerArrow
              arrowsSide="Left"
              classNameWrapper="w-full h-full overflow-hidden rounded-md"
            >
              {event.fields.image && (
                <>
                  <div className="hidden lg:block">
                    <Image
                      file={event.fields.image.fields.asset}
                      aspectRatio={'absolute'}
                    />
                  </div>
                  <div className="block lg:hidden">
                    <Image file={event.fields.image.fields.asset} />
                  </div>
                </>
              )}
            </ContainerArrow>
          </div>
          {/* ./image side */}
        </div>
      </Container>
    </ComponentWrapper>
  );
};
