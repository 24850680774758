/* Dependencies */
import { Formik } from 'formik';
import axios from 'axios';
import { FunctionComponent, useState } from 'react';

// Helpers
import { getLinkUrl } from '@helpers/getLinkUrl/getLinkUrl';
import { handleFieldError } from '@helpers/handleFieldError/handleFieldError';

// Components
import { ActionButton } from '@atoms/ActionButton/ActionButton';
import { Paragraph } from '@atoms/Paragraph/Paragraph';
import { Link } from '@atoms/Link/Link';
import { Checkbox } from '@molecules/Forms/Checkbox/Checkbox';
import { Input } from '@molecules/Forms/Input/Input';
import { ReCaptcha } from '@molecules/Forms/ReCaptcha/ReCaptcha';
import { QuestionList } from '../QuestionList/QuestionList';

// Models
import {
  EmailNewsletterFormInitialValues,
  EmailNewsletterFormProps,
  EmailNewsletterFormSchema,
} from './EmailNewsletterForm.model';

import { Question } from '../QuestionList/QuestionList.model';

/**
 * Email Newsletter Form
 * @param EmailNewsletterForm
 * @returns
 */
export const EmailNewsletterForm: FunctionComponent<
  EmailNewsletterFormProps
> = ({ formid, source, callToAction, questions }) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues: EmailNewsletterFormInitialValues = {
    source,
    firstName: '',
    lastName: '',
    jobTitle: '',
    email: '',
    company: '',
    recaptcha: '',
    terms: false,
    formQuestion0: '',
    formQuestion1: '',
    formQuestion2: '',
  };
  return (
    <div className="w-full">
      <Formik
        initialValues={initialValues}
        validationSchema={EmailNewsletterFormSchema(questions)}
        onSubmit={async (values, { resetForm }) => {
          if (isSubmitting) return;

          const formData = {
            source: values.source,
            firstName: values.firstName,
            lastName: values.lastName,
            jobTitle: values.jobTitle,
            email: values.email,
            company: values.company,
            recaptcha: values.recaptcha,
            terms: values.terms,
            questions: questions.map((question, index) => ({
              question: question.question,
              answer: values[`formQuestion${index}`],
            })),
          };

          // Set Submission State
          setIsSubmitting(true);

          // Submit Form
          await axios
            .post('/api/emailform', {
              formType: 'EmailNewsletter',
              formid,
              ...formData,
            })
            .then(() => {
              setShowErrorMessage(false);
              setShowSuccessMessage(true);

              // Reset the form
              resetForm();
            })
            .catch(() => {
              setShowSuccessMessage(false);
              setShowErrorMessage(true);
            });

          // Optional Link / Download
          if (callToAction) {
            const url = getLinkUrl(callToAction);
            const target = callToAction.fields.openInNewTab
              ? '_blank'
              : '_self';
            if (url) {
              setTimeout(function () {
                window.open(url, target);
              }, 500);
            }
          }

          // Set Submission State
          setIsSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldTouched,
          setFieldError,
          setFieldValue,
        }) => (
          <form
            action="POST"
            onSubmit={handleSubmit}
            className="space-y-5 lg:space-y-7"
          >
            <div className="grid w-full grid-cols-1 gap-5 md:grid-cols-2">
              <Input
                label="First Name"
                name="firstName"
                type="text"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={handleFieldError(errors, touched, 'firstName')}
                inputMode="text"
                required
              />
              <Input
                label="Last Name"
                name="lastName"
                type="text"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={handleFieldError(errors, touched, 'lastName')}
                inputMode="text"
                required
              />
            </div>
            <div className="w-full">
              <Input
                label="Email"
                name="email"
                type="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={handleFieldError(errors, touched, 'email')}
                inputMode="email"
                required
              />
            </div>
            <div className="grid w-full grid-cols-1 gap-5 md:grid-cols-2">
              <Input
                label="Job Title"
                name="jobTitle"
                type="text"
                value={values.jobTitle}
                onChange={handleChange}
                onBlur={handleBlur}
                error={handleFieldError(errors, touched, 'jobTitle')}
                inputMode="text"
                required
              />

              <Input
                label="Company"
                name="company"
                type="text"
                value={values.company}
                onChange={handleChange}
                onBlur={handleBlur}
                error={handleFieldError(errors, touched, 'company')}
                inputMode="text"
                required
              />
            </div>
            {/* Form questions */}
            <QuestionList
              questions={questions as Question[]}
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              handleFieldError={handleFieldError}
              errors={errors}
              touched={touched}
            />
            {/* Form questions */}
            {/* ReCaptcha */}
            <div className="w-full">
              <ReCaptcha
                onError={() => {
                  setFieldTouched('recaptcha', true);
                  setFieldValue('recaptcha', '');
                  setFieldError(
                    'recaptcha',
                    'Captcha invalid, please try again.'
                  );
                }}
                onExpire={() => {
                  setFieldTouched('recaptcha', true);
                  setFieldValue('recaptcha', '', true);
                }}
                onChange={(code) => {
                  setFieldTouched('recaptcha', true);
                  setFieldValue('recaptcha', code, true);
                }}
                error={handleFieldError(errors, touched, 'recaptcha')}
              />
            </div>
            {/* / ReCaptcha */}

            {/* Terms & Submit  */}
            <div className="flex w-full flex-col items-baseline space-y-7 md:flex-row md:space-y-0">
              <div className="md:order-2 md:pt-2">
                <Checkbox
                  label={
                    <>
                      By clicking “Submit” you agree to receive communications
                      from MMT. You can unsubscribe at any time. MMT is
                      committed to protecting your privacy and we&apos;ll only
                      use your personal information in line with our&nbsp;
                      <Link
                        to="/privacy-policy"
                        title="Main Logo"
                        openInNewTab={false}
                        variant="none"
                      >
                        Privacy Policy
                      </Link>
                    </>
                  }
                  name="terms"
                  value="terms"
                  checked={values.terms}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={handleFieldError(errors, touched, 'terms')}
                  required
                />
              </div>
              <div className="w-full md:order-1 md:mr-5 md:w-auto md:basis-auto">
                <ActionButton variant="secondary" onClick={handleSubmit}>
                  Submit
                </ActionButton>
              </div>
            </div>
            {/* / Terms & Accept */}
            {showSuccessMessage && (
              <div className="mt-6 block w-full border border-primary p-3">
                <Paragraph>Success. Your message has been sent.</Paragraph>
              </div>
            )}

            {showErrorMessage && (
              <div className="mt-6 block w-full border border-primary p-3">
                <Paragraph>
                  Your message has not been sent. Please try again.
                </Paragraph>
              </div>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};
