/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { Heading } from '../../Atoms/Heading/Heading';

// Models
import { TypeInev17 } from '../../../models/contentful';
import { RenderForm } from '../Forms/RenderForm/RenderForm';
import { ContainerArrow } from '../../Molecules/ContainerArrow/ContainerArrow';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';
import { ContainerArrowProps } from '../../Molecules/ContainerArrow/ContainerArrow.model';

/**
 * POST CONTENT FIGURES - INEV11
 * @param TypeInev17
 * @returns
 */
export const Inev17: FunctionComponent<TypeInev17> = ({
  fields: { theme, title, bodyCopy, form },
}) => {
  return (
    <section className="my-12 flex flex-col items-center justify-between lg:flex-row">
      {/* Information side */}
      <div className="flex max-w-1130 flex-col px-10 pt-0 pb-16 lg:order-first lg:w-5/12 lg:py-16 lg:pl-32">
        {/* title */}
        {title && (
          <Heading
            level="h2"
            font={`${title?.fields.fontFamily}`}
            style={`${title?.fields.style}`}
            className={`mb-6 font-display-s lg:mb-16`}
          >
            <PlainRichText content={title?.fields.content} />
          </Heading>
        )}
        {bodyCopy && (
          <Paragraph variant="base">
            <PlainRichText content={bodyCopy.fields.content} />
          </Paragraph>
        )}
        {/* body copy */}
      </div>
      {/* ./ Information side */}
      {/* Form side */}
      {form && (
        <div className="lg:w-1/2">
          <ContainerArrow
            arrowsSide="Left"
            arrowsSize="None"
            bgColour={
              theme.fields.colourScheme.toLowerCase() as ContainerArrowProps['bgColour']
            }
          >
            <div className="px-10 py-12 lg:ml-auto lg:w-4/5 lg:py-16 lg:px-14">
              {form.fields.title && (
                <Heading
                  level="h2"
                  font={`${form.fields.title?.fields.fontFamily}`}
                  style={`${form.fields.title?.fields.style}`}
                  className={`mb-6 font-display-s lg:mb-10`}
                >
                  <PlainRichText content={form.fields.title?.fields.content} />
                </Heading>
              )}
              <RenderForm form={form} />
            </div>
          </ContainerArrow>
        </div>
      )}
      {/* ./ Form side */}
    </section>
  );
};
