import { FunctionComponent } from 'react';
import { LatestPostFiltersProps } from './LatestPostFilters.model';

export const LatestPostFilters: FunctionComponent<LatestPostFiltersProps> = ({
  activeTag,
  tags,
  handleFilter,
}) => {
  return (
    <div className="mb-8 grid grid-cols-2 gap-y-7 md:mb-16 md:flex md:items-center md:justify-center md:gap-x-[72px]">
      <button
        type="button"
        className={`text-primary lg:text-3xl ${
          !activeTag ? 'underline' : 'opacity-50'
        }`}
        onClick={() => handleFilter(undefined)}
      >
        All
      </button>
      {tags.map((tag, index) => {
        return (
          <button
            type="button"
            className={`text-primary lg:text-3xl ${
              activeTag === tag ? 'underline' : 'opacity-50'
            }`}
            key={index}
            onClick={() => handleFilter(tag)}
          >
            {tag.fields.title}
          </button>
        );
      })}
    </div>
  );
};
