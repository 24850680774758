/* Dependencies */
import React, { useEffect, useState } from 'react';

// Helpers
import { uniqueId } from '../../../../helpers/uniqueId/uniqueId';
import { getDescribedBy } from '../../../../helpers/getDescribedBy/getDescribedBy';

// Models
import { InputProps } from './Input.model';

// Functions
/**
 * References input event and default value to return char count.
 * @param event - The input event if available.
 * @param defaultValue - The default component value.
 */
export const characterCount = (defaultValue: string, event?: Event): number => {
  return !event
    ? defaultValue.length
    : (event.target as HTMLInputElement).value.length;
};

/**
 * Input Component
 */
export const Input: React.FC<InputProps> = ({
  autoFocus,
  autoComplete,
  disabled,
  error,
  inputMode,
  label,
  max,
  maxLength,
  min,
  minLength,
  name,
  onChange,
  onBlur,
  placeholder,
  readOnly,
  required,
  showCharCount,
  supportText,
  type,
  value,
}) => {
  // Set The Initial State
  const [id] = useState(uniqueId());
  const [charCount, setCharCount] = useState(0);
  const [describedBy, setDescribedBy] = useState('');

  // Set the Tool tip on ID Change
  useEffect(() => {
    setDescribedBy(getDescribedBy({ error, id, name, supportText }));
  }, [id, error, name, supportText]);

  /**
   * Sets the character count
   */
  useEffect(() => {
    if (showCharCount) {
      setCharCount(characterCount(value as string));
    }
  }, [showCharCount, value]);

  // Render the component.
  return (
    <div className="relative">
      <label htmlFor={id} className="relative flex">
        <input
          id={id}
          type={type}
          name={name}
          onChange={onChange.bind(this)}
          onBlur={onBlur.bind(this)}
          aria-invalid={error ? true : false}
          aria-describedby={describedBy}
          max={max}
          maxLength={maxLength}
          min={min}
          minLength={minLength}
          required={required}
          placeholder={placeholder ?? label}
          readOnly={readOnly}
          disabled={disabled}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          value={value ? value : ''}
          inputMode={inputMode ? inputMode : 'text'}
          className={`peer h-9 border-b bg-transparent placeholder-transparent outline-0 outline-transparent ${
            error && 'focus:outline-none focus:ring-red'
          } block w-full pr-10 `}
        />

        <span className="absolute top-1/2 block w-full -translate-y-8 text-xs transition-all peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:text-small peer-focus:-translate-y-8 peer-focus:text-xs lg:peer-placeholder-shown:text-base lg:peer-focus:text-xs">
          {label}
        </span>
      </label>

      {showCharCount || error || supportText ? (
        <div className="mt-2 flex w-full flex-row flex-wrap items-start">
          {error || supportText ? (
            <small
              className={`text-xs ${error && 'text-secondary'}`}
              id={`${id}_${name}-help`}
              data-character-count={maxLength}
            >
              {!error ? supportText : error}
            </small>
          ) : null}
          {showCharCount && maxLength ? (
            <small className={`ml-auto text-right`}>
              <span className="sr-only" aria-live="polite" aria-atomic="true">
                You have {maxLength - charCount} characters remaining.
              </span>
              {charCount}/{maxLength}
            </small>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
