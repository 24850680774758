/* Dependencies */
import { FunctionComponent } from 'react';
import Script from 'next/script';

// Components
import { Container } from '../../Molecules/Container/Container';
import { Heading } from '../../Atoms/Heading/Heading';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';
import { LinkButton } from '../../Atoms/LinkButton/LinkButton';
import { getLinkUrl } from '../../../helpers/getLinkUrl/getLinkUrl';
import { ComponentWrapper } from '../../Molecules/ComponentWrapper/ComponentWrapper';

// Models
import { TypeCon30 } from '../../../models/contentful';

/**
 * POST CONTENT FIGURES - INEV11
 * @param TypeCon30
 * @returns
 */
export const Con30: FunctionComponent<TypeCon30> = ({
  fields: { title, bodyCopy, callToAction },
}) => {
  return (
    <ComponentWrapper className="my-24 lg:my-40">
      <Container className="mx-auto max-w-1270 text-center text-plum">
        {title && (
          <Heading
            level="h2"
            font={title.fields.fontFamily}
            style={title.fields.style}
            className="mb-6 lg:mb-8"
          >
            <PlainRichText content={title.fields.content} />
          </Heading>
        )}
        {bodyCopy && (
          <Paragraph className="mx-auto mb-8 mt-6 max-w-800 text-sm lg:text-base">
            <PlainRichText content={bodyCopy?.fields.content} />
          </Paragraph>
        )}

        {callToAction && (
          <div className="mt-5 w-full">
            <LinkButton
              variant={callToAction.fields.theme?.fields.colourScheme}
              openInNewTab={callToAction.fields.openInNewTab}
              to={getLinkUrl(callToAction)}
              hasIcon={
                callToAction.fields.assetLink &&
                callToAction.fields.assetLink.fields
                  ? true
                  : false
              }
            >
              {callToAction.fields.title}
              {callToAction.fields.assetLink &&
                callToAction.fields.assetLink.fields && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="ml-1 h-3 w-3 lg:h-4 lg:w-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                    />
                  </svg>
                )}
            </LinkButton>
          </div>
        )}

        <Script src="https://apps.elfsight.com/p/platform.js" />

        <div className="mt-20 w-full">
          <div className="elfsight-app-8851d9fd-98af-4268-bd88-80b6e1a8a31b"></div>
        </div>
      </Container>
    </ComponentWrapper>
  );
};
