import anime from 'animejs';

export const textTheme = Object.freeze({
  Transparent: 'text-primary',
  Red: 'theme-red text-primary',
  Blue: 'theme-blue text-primary',
  Plum: 'theme-plum text-primary',
});

export function playVideo(
  initialContent: HTMLDivElement | null,
  videoContent: HTMLDivElement | null,
  iframeReady: boolean
): void {
  const video = videoContent?.querySelector('iframe');

  if (iframeReady) {
    anime
      .timeline({ autoplay: true })
      .add({
        targets: initialContent,
        opacity: 0,
        easing: 'easeInOutQuad',
        duration: 1000,
        complete: () => {
          initialContent && (initialContent.style.zIndex = '-1');
        },
      })
      .add(
        {
          targets: videoContent,
          opacity: 1,
          easing: 'easeInOutQuad',
          duration: 1000,
          begin: () => {
            videoContent && (videoContent.style.display = 'flex');
            video?.dataset.src && (video.src = video.dataset.src);
          },
        },
        '-=1000'
      );
  }
}
