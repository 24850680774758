/* Dependencies */
import { FunctionComponent, useState, useEffect, useRef } from 'react';
import * as Contentful from 'contentful';
import ReactPaginate from 'react-paginate';

// Services
import { httpClient } from '../../../services/httpClient';

// Components
import { ComponentWrapper } from '../../Molecules/ComponentWrapper/ComponentWrapper';
import { Container } from '../../Molecules/Container/Container';
import { Heading } from '../../Atoms/Heading/Heading';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { InsightCard } from '../../Molecules/InsightCard/InsightCard';

// Models
import {
  TypeAtomCaseStudyTag,
  TypeTemplateCaseStudyPage,
} from '../../../models/contentful';
import { CaseStudyPostGridViewModel } from './CaseStudyPostGrid.model';
import { RetrieveLatestCaseStudiesResponse } from '../../../services/Contentful/Contentful.model';
import { LatestPostFilters } from '../../Molecules/LatestPostFilters/LatestPostFilters';
import { FeaturedArticle } from '../FeaturedArticle/FeaturedArticle';

/**
 * CASE STUDY POST GRID - INEV07
 * @param CaseStudyPostGridViewModel
 * @returns
 */
export const CaseStudyPostGrid: FunctionComponent<CaseStudyPostGridViewModel> = ({
  fields: { title, displayLimit, caseStudies, filterTags },
}) => {
  const [activeTag, setActiveTag] = useState<TypeAtomCaseStudyTag>();
  const [featuredCaseStudy, setFeaturedCaseStudy] =
    useState<TypeTemplateCaseStudyPage>();

  const [currentItems, setCurrentItems] = useState<TypeTemplateCaseStudyPage[]>(
    []
  );
  const [pageCount, setPageCount] = useState<number>(0);
  const [itemOffset, setItemOffset] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const postGrid = useRef<any>();

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * displayLimit + 1) % caseStudies.length;
    setItemOffset(newOffset);
    postGrid.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    // Store items to use
    let caseStudiesToUse: TypeTemplateCaseStudyPage[];

    // Handle Active Tag
    if (activeTag) {
      caseStudiesToUse = caseStudies.filter((item) => {
        if (item.fields.tags) {
          const tagIds = item.fields.tags.map((tag) => tag.sys.id);
          return tagIds.includes(activeTag.sys.id);
        }
        return false;
      });
    }
    // Default
    else {
      caseStudiesToUse = caseStudies;
    }

    // Set Featured Case Study
    if (currentPage === 1) {
      setFeaturedCaseStudy(caseStudiesToUse[0]);
    }

    const endOffset = itemOffset + displayLimit;
    setCurrentItems(caseStudiesToUse.slice(itemOffset, endOffset));
    setPageCount(Math.ceil((caseStudiesToUse.length - 1) / displayLimit));

    // Set Current Page
    setCurrentPage(Math.floor(itemOffset / displayLimit));
  }, [itemOffset, displayLimit, activeTag]);

  return (
    <ComponentWrapper backgroundColor="Red" className="text-primary">
      <Container className="py-9 sm:max-w-1130 lg:py-[72px]">
        {/* Filter */}
        {filterTags && (
          <LatestPostFilters
            activeTag={activeTag}
            handleFilter={(tag) => {
              setActiveTag(tag);
              setItemOffset(1);
              setCurrentPage(1);
            }}
            tags={filterTags}
          />
        )}
        {/* ./ Filter */}

        {/* Featured Post */}
        {featuredCaseStudy && (
          <FeaturedArticle
            pageType="case-studies"
            fields={featuredCaseStudy.fields}
            preTitle={'FEATURED Case Study'}
            sys={featuredCaseStudy.sys}
            metadata={featuredCaseStudy.metadata}
            toPlainObject={featuredCaseStudy.toPlainObject}
            update={featuredCaseStudy.update}
          />
        )}
        {/* ./ Featured Post */}

        <section>
          {/* Pretitle for Post Grid */}
          {title && (
            <Heading level="h2" style="Heading 6" className="mb-6 uppercase">
              <PlainRichText content={title.fields.content} />
            </Heading>
          )}
          {/* ./ Pretitle for Post Grid */}

          {/* Post Grid */}
          <div
            className="grid gap-5 md:grid-cols-2 lg:grid-cols-3"
            ref={postGrid}
          >
            {currentItems.map(
              (
                { fields: { slug, image, tags, title } },
                index
              ) => {
                return (
                  <div data-aos="fade-up" key={`caseStudy_${index}`}>
                    <InsightCard
                      link={`/case-studies/${slug.fields.slug}`}
                      image={image?.fields.asset}
                      tags={tags?.map((tag) => {
                        return tag.fields;
                      })}
                      title={title}
                    />
                  </div>
                );
              }
            )}
          </div>
          {/* ./ Post Grid */}
        </section>

        {/* Pagination */}
        <ReactPaginate
          nextLabel="NEXT"
          previousLabel="PREV"
          onPageChange={handlePageClick}
          pageCount={pageCount}
          forcePage={currentPage}
          pageRangeDisplayed={2}
          marginPagesDisplayed={1}
          containerClassName={
            'pagination mt-9 lg:mt-16 flex justify-center font-display-a font-extrabold text-primary lg:text-2xl'
          }
          activeClassName={'opacity-50'}
          disabledClassName={'hidden'}
          renderOnZeroPageCount={() => null}
        />
        {/* ./ Pagination */}
      </Container>
    </ComponentWrapper>
  );
};

// Cache for the response
let responseCache: RetrieveLatestCaseStudiesResponse;

/**
 * Get Case Study Post Grid Enhanced Props
 * @param component - Component.
 * @param preview - Preview mode status.
 * @returns
 */
export async function getCaseStudyPostGridEnhancedProps(
  component: Contentful.Entry<Record<string, any>>,
  preview = false
) {
  // Case Studies
  let caseStudyPages: RetrieveLatestCaseStudiesResponse;

  // If we have a cached response, return it
  if (responseCache) {
    caseStudyPages = responseCache;
  } else {
    caseStudyPages = await httpClient.contentful.retrieveLatestCaseStudies(
      {
        limit: 999,
        locale: 'en-GB',
      },
      preview
    );
    responseCache = caseStudyPages;
  }
  // Set the caseStudies
  const resultsFound = caseStudyPages.items.length > 0;

  component.fields.caseStudies = [];

  if (resultsFound) {
    component.fields.caseStudies = caseStudyPages.items.map((item) => {
      return {
        ...item,
        fields: {
          ...item.fields,
          tags: item.fields.tags
            ? item.fields.tags.filter((tag) => tag.fields)
            : [],
        },
      };
    });
  }

  // Return the component
  return component;
}
