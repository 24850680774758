/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { ComponentWrapper } from '../../Molecules/ComponentWrapper/ComponentWrapper';
import { Container } from '../../Molecules/Container/Container';
import { Heading } from '../../Atoms/Heading/Heading';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { CTATandem } from '../../Molecules/CTATandem/CTATandem';

// Models
import { TypeCon04 } from '../../../models/contentful';

/**
 * CONTENT FEATURED INSIGHT 2 - CON04
 * @param TypeCon04
 * @returns
 */
export const Con04: FunctionComponent<TypeCon04> = ({
  fields: { entries },
}) => {
  return (
    <Container className="max-w-1130 py-16 lg:py-24">
      <div className="lg:grid lg:grid-cols-2 lg:gap-4">
        {entries &&
          entries.map((entry, index) => {
            const { title, bodyCopy, callToActions, theme } = entry.fields;
            return (
              <div
                data-aos="fade-up"
                key={`${entry.sys.id}_${index}`}
                data-aos-delay={((index % 2) * 300).toString()}
              >
                <ComponentWrapper
                  key={`entry_${index}`}
                  backgroundColor={theme.fields.colourScheme}
                  className="mb-4 flex h-full flex-col justify-between rounded-md px-6 pt-14 pb-10 text-primary shadow-[0_4px_25px_0_rgba(0,0,0,0.05)] lg:px-16 lg:pt-2 lg:pb-14"
                >
                  <div>
                    <Heading
                      level="h2"
                      font={title.fields.fontFamily}
                      style={title.fields.style}
                      className="mb-6 flex items-end text-xl lg:mb-12 lg:h-52 lg:text-3xl"
                    >
                      <PlainRichText content={title.fields.content} />
                    </Heading>
                    <p className="mb-5 font-display-s text-small lg:mb-12 lg:text-base">
                      <PlainRichText content={bodyCopy} />
                    </p>
                  </div>
                  <CTATandem
                    callsToAction={callToActions}
                    className="flex flex-wrap items-center"
                  />
                </ComponentWrapper>
              </div>
            );
          })}
      </div>
    </Container>
  );
};
